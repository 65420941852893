const FIREBASE_FUNCTIONS = `https://asia-northeast1-suanluang-services.cloudfunctions.net`

export default
{
    space: `spaces/v6`,

    query:
    {
        limit: 50,
    },

    url:
    {
        cloudinary: `https://res.cloudinary.com`,
        
        // upload:
        // {
        //     image: `${FIREBASE_FUNCTIONS}/admin-media?action=upload&type=image`,
        //     video: `${FIREBASE_FUNCTIONS}/admin-media?action=upload&type=video`,
        // },

        blacklist:
        {
            add: `${FIREBASE_FUNCTIONS}/admin-blacklist?action=add`,
            remove: `${FIREBASE_FUNCTIONS}/admin-blacklist?action=remove`,
        },

        hq:
        {
            save: `${FIREBASE_FUNCTIONS}/admin-hq?action=save`,
            publish: `${FIREBASE_FUNCTIONS}/admin-hq?action=publish`,
        },

        post:
        {
            save: `${FIREBASE_FUNCTIONS}/admin-post?action=save`,
            delete: `${FIREBASE_FUNCTIONS}/admin-post?action=delete`,
            publish: `${FIREBASE_FUNCTIONS}/admin-post?action=publish`,
            unpublish: `${FIREBASE_FUNCTIONS}/admin-post?action=unpublish`,
        },

        project:
        {
            save: `${FIREBASE_FUNCTIONS}/admin-project?action=save`,
            delete: `${FIREBASE_FUNCTIONS}/admin-project?action=delete`,
            publish: `${FIREBASE_FUNCTIONS}/admin-project?action=publish`,
            unpublish: `${FIREBASE_FUNCTIONS}/admin-project?action=unpublish`,
        },

        taxonomy:
        {
            save: `${FIREBASE_FUNCTIONS}/admin-taxonomy?action=save`,
            publish: `${FIREBASE_FUNCTIONS}/admin-taxonomy?action=publish`,
        },

        service:
        {
            cloudinary:
            {
                save: `${FIREBASE_FUNCTIONS}/admin-service-cloudinary?action=save`,
                delete: `${FIREBASE_FUNCTIONS}/admin-service-cloudinary?action=delete`,
                activate: `${FIREBASE_FUNCTIONS}/admin-service-cloudinary?action=activate`,
                usage: `${FIREBASE_FUNCTIONS}/admin-service-cloudinary?action=usage`,
            },

            mailgun:
            {
                save: `${FIREBASE_FUNCTIONS}/admin-service-mailgun?action=save`,
            },
        },

        user:
        {
            save: `${FIREBASE_FUNCTIONS}/admin-user?action=save`,
            delete: `${FIREBASE_FUNCTIONS}/admin-user?action=delete`,
            // changePassword: `${FIREBASE_FUNCTIONS}/admin-user?action=change-password`,
        },
    },
}