import React, { Fragment, useState, useEffect } from 'react'
import {
    message,
} from 'antd'

import Modal from '../../components/Modal'

import Post from '../../utils/Space.Post'
import Taxonomy from '../../utils/Space.Taxonomy'

import Header from './Header'
import Footer from './Footer'
import Loader from './Loader'

import TabOverview from './Tab.Overview'
import TabContent from './Tab.Content'
import TabGallery from './Tab.Gallery.v2'
import TabMeta from './Tab.Meta'

const TABS = 
[
    {
        tab: 'ภาพรวม',
        key: 'overview',
    },

    {
        tab: 'เนื้อหา',
        key: 'content',
    },

    {
        tab: 'อัลบั้ม',
        key: 'gallery',
    },

    {
        tab: 'เมต้า',
        key: 'meta',
    },
]

const VALIDATIONS = 
{
    slug: 'idle',
}

function Comp (
    {
        match,
        history,
    }
)
{
    const { id } = match.params

    const [ action, setAction ] = useState(null)
    const [ tab, setTab ] = useState(TABS[0].key)
    
    const [ original, setOriginal ] = useState({ })
    const [ current,  setCurrent ] = useState({ })

    const [ taxonomy, setTaxonomy ] = useState(undefined)

    const [ validations, setValidations ] = useState(VALIDATIONS)
    const [ temps, setTemps ] = useState([ ])

    const invalid = !action && Post.Document.getInvalidFields(current, validations).length > 0
    const changed = !action && Post.Document.getChangeds(original, current).length > 0

    const canSave = !!changed && !action

    function handleMount ()
    {
        setOriginal({ })
        setCurrent({ })

        setTemps([])
        setValidations(VALIDATIONS)
        setAction(id !== 'create' ? 'loading' : null)

        const unsubscribes = []
        const promises = []

        promises.push(Taxonomy.Query.post(setTaxonomy))

        if (id !== 'create')
        {
            promises.push(
                Post.Query.get(id, function ({ original, current })
                {
                    if (!!original)
                    {
                        setOriginal (original)
                    }

                    if (!!current)
                    {
                        setCurrent (current)
                        setAction (null)
                    }
                })
            )
        }

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleCreateTemp (temp)
    {
        if (!!temp)
        {
            // console.log(temp)
            setTemps([ ...temps, temp ])
        }
    }

    function handleOverviewChange (value)
    {
        setCurrent ({ ...current, overview: value })

        // if (id === 'create')
        // {
        //     const _meta = current.meta || {}

        //     if (!_meta.title)
        //     {
        //         _meta.title = value.title || ''
        //     }

        //     if (!_meta.description)
        //     {
        //         _meta.description = value.excerpt || ''
        //     }

        //     if (!_meta.image)
        //     {
        //         _meta.image = value.image || null
        //     }

        //     setCurrent ({ ...current, meta: _meta })
        // }
    }

    function handleDataChange (name, value)
    {
        setCurrent ({ ...current, [name]: value })
    }

    async function handleSave ()
    {
        const _editor = window.localStorage.getItem('editorID')

        const _info = current.info || {}
        const _over = current.overview || {}

        const createdAt = !!_info.createdAt && !!_info.createdAt.toDate ? _info.createdAt.toDate().getTime() : 'now'
        const publishedAt = !!_over.publishedAt && !!_over.publishedAt.toDate ? _over.publishedAt.toDate().getTime() : 'delete'

        const status = _info.status || 'draft'

        const overview = { ...current.overview, publishedAt }
        const contents = current.contents || []
        const gallery = current.gallery || []
        const meta = current.meta || {}

        const data = 
        {
            overview,
            contents,
            gallery,
            meta,

            status,
            createdAt,

            _editor,
        }

        setAction('saving')

        try
        {
            const result = await Post.Update.save ({ id, data, temps })

            if (result.error)
            {
                setAction(null)
                setValidations(result.error.validations || { })

                message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
            }
            else
            {
                setAction(null)
                setValidations({ })
                setTemps([ ])

                if (result.action === 'create')
                {
                    history.replace (`/posts/${result.id}`)
                }

                message.success('บันทึกบทความเรียบร้อย')
            }
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
        }
    }

    function handleUploading (uploading)
    {
        setAction(!!uploading ? 'uploading' : null)
    }

    async function handlePublish ()
    {
        setAction('publishing')

        try
        {
            await Post.Update.publish ([ id ])

            setAction(null)

            message.success('แผยแพร่บทความเรียบร้อย')
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการเผยแพร่ กรุณาตรวจสอบข้อมูลและเผยแพร่อีกครั้ง')
        }
    }

    async function handleUnpublish ()
    {
        setAction('unpublishing')

        try
        {
            await Post.Update.unpublish ([ id ])

            setAction(null)

            message.success('หยุดแผยแพร่บทความเรียบร้อย')
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการหยุดเผยแพร่ กรุณาตรวจสอบข้อมูลและหยุดเผยแพร่อีกครั้ง')
        }
    }

    async function handleDelete ()
    {
        setAction('deleting')

        try
        {
            await Post.Update.delete ([ id ])

            setAction(null)

            message.success('ลบบทความเรียบร้อย')

            setTab(TABS[0].key)
            handleClose ()
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการลบ กรุณาตรวจสอบข้อมูลและลบอีกครั้ง')
        }
    }

    function handleClose ()
    {
        history.replace (`/posts`)
    }

    useEffect (handleMount, [ id ])

    let ready = action !== 'loading'

    return (
        <Modal
            open={true}

            header={
                <Header
                    title={id !== 'create' ? 'แก้ไขบทความ' : 'สร้างบทความใหม่'}
                    tab={tab}
                    tabs={TABS}
                    disabled={!!action}
                    changed={changed}
                    onChange={setTab}
                    onClose={
                        function ()
                        {
                            if (!!handleClose)
                            {
                                setTab(TABS[0].key)
                                handleClose()
                            }
                        }
                    }
                />
            }

            footer={
                <Footer
                    {...current.info}

                    action={action}
                    canSave={canSave}
                    onSave={handleSave}
                />
            }

            style={
                {
                    backgroundColor: `#fdfdfd`,
                }
            }
        >
            {
                !!ready &&
                <Fragment>
                    {
                        tab === TABS[0].key && 
                        <TabOverview 
                            id={id}
                            data={current.overview}
                            info={current.info}
                            action={action}
                            onChange={handleOverviewChange}
                            onDelete={handleDelete}
                            onPublish={handlePublish}
                            onUnpublish={handleUnpublish}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}

                            changed={changed}
                            invalid={invalid}

                            validations={validations}
                            setValidations={setValidations}
                            
                            taxonomy={taxonomy}
                        />
                    }
                    {
                        tab === TABS[1].key && 
                        <TabContent 
                            id={id}
                            action={action}
                            contents={current.contents}
                            onChange={value => handleDataChange ('contents', value)}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === TABS[2].key && 
                        <TabGallery 
                            contents={current.gallery}
                            action={action}
                            onChange={value => handleDataChange ('gallery', value)}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === TABS[3].key && 
                        <TabMeta 
                            data={current.meta}
                            action={action}
                            onChange={value => handleDataChange ('meta', value)}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                </Fragment>
            }
            {!ready && <Loader />}
        </Modal>
    )
}

export default Comp