import React from 'react'
import { Menu, Icon, Modal } from 'antd'
import { Link, withRouter } from 'react-router-dom'

import styled from 'styled-components'
import firebase from 'firebase/app'

const SubMenu = Menu.SubMenu

const Container = styled.div`
    .ant-menu
    {
        background: #fafafa;
    }

    .ant-menu-item,
    .ant-menu-submenu-title
    {
        padding-left: 12px !important;

        height: 38px;
        line-height: 38px;

        i
        {
            font-size: 12px;
        }
    }
    
    .ant-menu-submenu
    {
        .ant-menu-item
        {
            padding-left: 36px !important;

            height: 24px;
            line-height: 24px;
        }
    }
`

const StyledLink = styled(Link)`
    &&
    {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`

function getOpenKeys (path = '')
{
    // if (path.startsWith('/posts/'))
    // {
    //     return [ '/posts' ]
    // }

    // if (path.startsWith('/projects/'))
    // {
    //     return [ '/projects' ]
    // }

    if (path.startsWith('/hq-'))
    {
        return [ '/hq' ]
    }

    // if (path.startsWith('/settings/'))
    // {
    //     return [ '/settings' ]
    // }

    return [ path ]
}

const Comp = function (
    {
        theme = 'light',
        match,
        history,
    }
)
{
    async function handleSignOut ()
    {
        await firebase.auth ().signOut ()

        // await new Promise (r => setTimeout(r, 300))

        // window.sessionStorage.removeItem ('auth')

        // const path = window.location.pathname
        // const search = window.location.search
        // const href = path + search

        // history.push (`/sign-in?redirect=${encodeURIComponent (href)}`)
    }

    function handleSignOutPrompt ()
    {
        const m = Modal.confirm (
            {
                title: 'ต้องการออกจากระบบ ?',
                cancelText: 'ไม่ใช่',
                okText: 'ใช่',
                onOk: () =>
                {
                    m.destroy ()
                    handleSignOut ()
                }
            }
        )
    }

    return (
        <Container>
            <Menu
                theme={theme}
                mode="inline"
                defaultSelectedKeys={[ match.path ]}
                defaultOpenKeys={getOpenKeys(match.path)}
                style={{ borderRight: 'none' }}
            >
                <Menu.Divider />

                <SubMenu 
                    key="/hq" 
                    title={
                        <span>
                            <Icon type="appstore" />
                            <span>หน้าหลัก</span>
                        </span>
                    }
                >
                    <Menu.Item key="/hq-parent">
                        <StyledLink to="/hq-parent" >
                            <span>ส่วนหัวและท้ายของหน้า</span>
                        </StyledLink>
                    </Menu.Item>
                    
                    <Menu.Item key="/hq-home">
                        <StyledLink to="/hq-home" >
                            <span>หน้าแรก</span>
                        </StyledLink>
                    </Menu.Item>
                    
                    <Menu.Item key="/hq-posts">
                        <StyledLink to="/hq-posts" >
                            <span>หน้ารวมบทความ</span>
                        </StyledLink>
                    </Menu.Item>
                    
                    <Menu.Item key="/hq-projects">
                        <StyledLink to="/hq-projects" >
                            <span>หน้ารวมโครงการ</span>
                        </StyledLink>
                    </Menu.Item>
                    
                    <Menu.Item key="/hq-about">
                        <StyledLink to="/hq-about" >
                            <span>หน้าเกี่ยวกับ</span>
                        </StyledLink>
                    </Menu.Item>
                    
                    <Menu.Item key="/hq-contact">
                        <StyledLink to="/hq-contact" >
                            <span>หน้าติดต่อ</span>
                        </StyledLink>
                    </Menu.Item>

                    <Menu.Item key="/hq-privacy-policy">
                        <StyledLink to="/hq-privacy-policy" >
                            <span>หน้านโยบาย</span>
                        </StyledLink>
                    </Menu.Item>
                </SubMenu>
                
                <Menu.Divider />

                <Menu.Item key="/posts">
                    <StyledLink to="/posts" >
                        <Icon type="read" />
                        <span>บทความ</span>
                    </StyledLink>
                </Menu.Item>
                
                <Menu.Item key="/projects">
                    <StyledLink to="/projects" >
                        <Icon type="home" />
                        <span>โครงการ</span>
                    </StyledLink>
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item key="/users">
                    <StyledLink to="/users" >
                        <Icon type="team" />
                        <span>ผู้ดูแล</span>
                    </StyledLink>
                </Menu.Item>

                {/* <Menu.Item key="/site">
                    <StyledLink to="/site" >
                        <Icon type="setting" />
                        <span>ตั้งค่า</span>
                    </StyledLink>
                </Menu.Item> */}

                <Menu.Item key="/cloudinary">
                    <StyledLink to="/cloudinary" >
                        <Icon type="picture" />
                        <span>Cloudinary</span>
                    </StyledLink>
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item key="/registrations">
                    <StyledLink to="/registrations" >
                        <Icon type="idcard" />
                        <span>การลงทะเบียน</span>
                    </StyledLink>
                </Menu.Item>
                
                <Menu.Item key="/contacts">
                    <StyledLink to="/contacts" >
                        <Icon type="contacts" />
                        <span>การติดต่อ</span>
                    </StyledLink>
                </Menu.Item>

                <Menu.Item key="/blacklists">
                    <StyledLink to="/blacklists" >
                        <Icon type="close-circle" />
                        <span>อีเมลที่บล็อกไว้</span>
                    </StyledLink>
                </Menu.Item>
            
                <Menu.Divider />

                <Menu.Item 
                    key="sign-out"  
                    onClick={handleSignOutPrompt}
                >
                    <span>
                        <Icon type="logout" />
                        <span>ออกจากระบบ</span>
                    </span>
                </Menu.Item>
            </Menu>
        </Container>
    )
}

const WrappedRouter = withRouter(Comp)

export default WrappedRouter