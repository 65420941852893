import { useState, useEffect } from 'react'

function Component(
    {
        delay = 500,

        value,
        onChange,

        children,
    }
)
{
    const [ temp, setTemp ] = useState(value || '')
    const [ timeoutId, setTimeoutId ] = useState(undefined)

    function handleInit ()
    {
        if (value !== temp)
        {
            setTemp (value)
        }

        return function ()
        {
            if (!!timeoutId)
            {
                clearTimeout (timeoutId)
            }
        }
    }

    function handleChange (changedValue)
    {
        setTemp (changedValue)
        
        if (!!timeoutId)
        {
            clearTimeout (timeoutId)
        }

        const t = setTimeout(function ()
        {
            if (typeof onChange === 'function')
            {
                onChange (changedValue)  
            }  
        }, delay)

        setTimeoutId (t)
    }

    useEffect (handleInit, [ value ])

    return children(
        {  
            value: temp,
            onChange: handleChange,
        }
    )
}

export default Component