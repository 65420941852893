import React from 'react'
import {
    Form,
    Select,
} from 'antd'

const { Option } = Select

function Component(
    {
        layout,
        disabled,

        label = 'ประเภท',
        name = 'category',

        options,
        value,
        onChange,
    }
)
{
    return (
        <Form.Item 
            {...layout}
            label={label}
        >
            <Select 
                disabled={disabled}
                value={value}
                onChange={option => onChange(name, option)}
            >
                {
                    options.map(option =>
                    {
                        return (
                            <Option
                                key={`overview-category-${option.value}`}
                                value={option.value}
                            >
                                {option.text}
                            </Option>
                        )  
                    })
                }
            </Select>
        </Form.Item>
    )
}

export default Component