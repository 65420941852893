import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Row } from 'antd'

import Image from './node/Image'

function Comp (
    {
        action,
        items = [],
        createTemp,

        onChange,
        onUploading,
    }
)
{
    return (
        <Row type="flex" >
            {
                items.map((item, i) =>
                {
                    return (
                        <Image
                            key={item.id || `slider-${i}`}
                            index={i}
                            sortIndex={i}
                            length={items.length}
                            onChange={onChange}
                            onUploading={onUploading}
                            action={action}
                            disabled={!!action}
                            createTemp={createTemp}
                            value={item}
                        />
                    )
                })
            }
        </Row>
    )
}

export default SortableContainer(Comp)