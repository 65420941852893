import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="ลงทะเบียน" >
            <Group>
                <Item>
                    <Label></Label>
                    <Group group >
                        <Item>
                            <Color 
                                // disabled={disabled}
                                disabled={true}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="registration.bgColor"
                                alpha={false}
                                value={select('registration.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ตัวอักษร"
                                name="registration.text.color"
                                alpha={false}
                                value={select('registration.text.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>

                <Item>
                    <Label>ปุ่มลงทะเบียน</Label>
                    <Group group>
                        <Item>
                            <Label>Normal</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="registration.button.normal.bgColor"
                                        alpha={false}
                                        value={select('registration.button.normal.bgColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ตัวอักษร"
                                        name="registration.button.normal.color"
                                        alpha={false}
                                        value={select('registration.button.normal.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>
                        <Item>
                            <Label>Hover</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="registration.button.hover.bgColor"
                                        alpha={false}
                                        value={select('registration.button.hover.bgColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ตัวอักษร"
                                        name="registration.button.hover.color"
                                        alpha={false}
                                        value={select('registration.button.hover.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>
                    </Group>
                </Item>
            </Group>
        </Card>
    )
}

export default Component