
function view (doc)
{
    return {
        key: doc.id,
        id: doc.id,

        name: doc.get('name'),
        email: doc.get('email'),
        phone: doc.get('phone'),
        budget: doc.get('budget'),
        project: doc.get('project'),
        createdAt: doc.get('createdAt'),
    }
}

export default 
{
    view,
}