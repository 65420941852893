import Axios from 'axios'
import firebase from 'firebase/app'

async function update (action, ids = [])
{
    const space = window.config.space
    const url = window.config.url.project[action]

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    for (let i = 0; i < ids.length; i++)
    {
        const id = ids[i]
        await Axios.post(url, { space, id }, { headers })
    }
}

async function _delete (ids = [])
{
    await update ('delete', ids)
}

async function _publish (ids = [])
{
    await update ('publish', ids)
}

async function _unpublish (ids = [])
{
    await update ('unpublish', ids)
}

async function _save ({ id, data, temps })
{
    const space = window.config.space
    const url = window.config.url.project.save

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    return (await Axios.post(url, { space, id, data, temps }, { headers })).data
}

export default 
{
    save: _save,
    delete: _delete,
    publish: _publish,
    unpublish: _unpublish,
}