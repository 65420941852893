import React from 'react'
import styled from 'styled-components'

import { Row, Col, Form } from 'antd'

import Title from '../Tab.Overview/Field.Title'
import Excerpt from '../Tab.Overview/Field.Excerpt'

import Image from '../../../components/Field.Image'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        data = {},

        action,

        onChange,
        onUploading,
 
        createTemp,
    }
)
{
    function handleChange (name, value)
    {
        onChange({ ...data, [name]: value })
    }

    const fieldLayout = { colon: false }

    const title = data.title || ''
    const description = data.description || ''

    const image = data.image

    return (
        <Container>
            <Form 
                layout="vertical"
            >
                <Row 
                    gutter={32} 
                >
                    <Col 
                        xs={24}
                        // sm={12} 
                        // md={16}
                        lg={16}
                    >
                        <Title 
                            layout={fieldLayout}
                            value={title}
                            disabled={!!action}
                            onChange={handleChange}
                        />
                        
                        <Excerpt 
                            layout={fieldLayout}
                            label="คำอธิบาย"
                            name="description"
                            value={description}
                            disabled={!!action}
                            onChange={handleChange}
                        />
                    {/* </Col>
                    <Col
                        xs={24}
                        // sm={12}
                        // md={8}
                        // lg={10}
                    > */}
                        <Image 
                            layout={fieldLayout}
                            label="รูปประกอบ (1200x628 px)"
                            ratio={0.523}
                            value={image}
                            disabled={!!action}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default Component