import Axios from 'axios'
import firebase from 'firebase/app'

async function update (action, ids = [])
{
    const url = window.config.url.user[action]

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    for (let i = 0; i < ids.length; i++)
    {
        const id = ids[i]
        await Axios.post(url, { id }, { headers })
    }
}

async function _delete (ids = [])
{
    await update ('delete', ids)
}

async function _save ({ id, data })
{
    const url = window.config.url.user.save

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    return (await Axios.post(url, { id, data }, { headers })).data
}

export default 
{
    save: _save,
    delete: _delete,
}