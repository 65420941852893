// import CompareContent from '../Compare/content'
import Obj from '../Compare/object'

function getInvalidFields (data, validations = {})
{
    // const 
    // {
        
    // } = data

    // let overviewErrors = []

    // if (!overview.title)
    // {
    //     overviewErrors.push('title')
    // }

    // if (!overview.slug || [ 'validating', 'error' ].indexOf(validations.slug) >= 0)
    // {
    //     overviewErrors.push('slug')
    // }

    // if (!overview.excerpt)
    // {
    //     overviewErrors.push('excerpt')
    // }

    // if (!overview.image)
    // {
    //     overviewErrors.push('image')
    // }   

    // return [ ...overviewErrors ]
    return []
}

function getChangeds (o, c)
{
    const ignoreds = [ '_editor' ]
    return Obj.getChangeds (o, c, ignoreds)
}

export default
{
    getInvalidFields,
    getChangeds,
}