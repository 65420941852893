import { Modal } from 'antd'

import firebase from 'firebase/app'

import ParseUtil from './Parse'

async function get (id, callback)
{
    const { space } = window.config

    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/blacklists/${id}`)

    let m = null
    let init = false

    const parse = ParseUtil.edit

    return await queryRef.onSnapshot(function (doc)
    {
        if (!!m)
        {
            m.destroy ()
            m = null
        }

        const _editor = doc.get ('_editor')

        if (!init || !_editor || _editor === window.localStorage.getItem('editorID'))
        {
            init = true

            callback ({ original: parse(doc), current: parse(doc) })
        }
        else
        {
            m = Modal.confirm(
                {
                    title: `มีการแก้ไขข้อมูลจากบัญชีผู้ใช้อื่น ต้องการอัพเดทข้อมูลล่าสุดหรือไม่ ?`,
                    okButtonProps: { type: 'primary' },
                    okText: 'ใช่',
                    cancelText: 'ไม่ใช่',
                    onOk: () => 
                    {
                        init = true

                        callback ({ original: parse(doc), current: parse(doc) })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    },
                    onCancel: () =>
                    {
                        init = true

                        callback ({ original: parse(doc) })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    }
                }
            )
        }
    })
}

async function list (callback, page, filters, sorters)
{
    const { space, query } = window.config
    
    const orderBy = (sorters.field || 'createdAt');
    const order = sorters.order === 'ascend' ? 'asc' : 'desc'

    const limit = Math.min (query.limit * page, 10000)
    const cfs = firebase.firestore()
        
    let queryRef = cfs.collection(`${space}/blacklists`)

    if (orderBy === 'createdAt')
    {
        if (!!filters['createdAt'] && filters['createdAt'].length > 0)
        {
            const m1 = filters['createdAt'][0]
            const m2 = filters['createdAt'][1]

            const has1 = !!m1 && !!m1.valueOf
            const has2 = !!m2 && !!m2.valueOf

            if (has1)
            {
                queryRef = queryRef.where ('createdAt', '>=', m1.startOf ('day').valueOf ())
            }

            if (has2)
            {
                queryRef = queryRef.where ('createdAt', '<=', m2.endOf ('day').valueOf ())
            }
        }
    }
    
    queryRef = queryRef.orderBy(orderBy, order)
    queryRef = queryRef.limit(limit)

    const parse = ParseUtil.view

    return await queryRef.onSnapshot(function (snapshot)
    {
        let items = []
        let keys = []
        
        snapshot.docs.forEach(function (doc)
        {
            keys.push (doc.id)
            items.push (parse (doc))
        })

        callback ({ keys, items })
    })
}

export default 
{
    get,
    list,
}