import React from 'react'

import { SortableContainer } from 'react-sortable-hoc'
import { Row } from 'antd'

import Item from './Item'

function Comp (
    {
        parent,

        items = [],

        action,
        createTemp,

        onChange,
        onUploading,
    }
)
{
    const slugs = items.map (item => { return { id: item.id, slug: item.slug } })
    
    return (
        <Row type="flex">
            {
                items.map((item, i) =>
                {
                    return (
                        <Item
                            key={item.id}

                            index={i}
                            sortIndex={i}

                            length={items.length}

                            action={action}
                            createTemp={createTemp}

                            onChange={onChange}
                            onUploading={onUploading}

                            data={item}

                            parent={parent}
                            slugs={slugs}
                            // {...item}
                        />
                    )
                })
                .filter (e => !!e)
            }
        </Row>
    )
}

export default SortableContainer(Comp)