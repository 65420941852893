import React from 'react'

import { SortableElement } from 'react-sortable-hoc'
import { 
    Row,
    Col,
    Modal,
    Button,
} from 'antd'

import styled from 'styled-components'

import Handle from '../../../post/Tab.Content/node/parent/Handle'
// import Image from '../../../post/Tab.Overview/Field.Thumbnail/Image'
import Image from '../../../../components/Field.Image/Image.Preview'

const Container = styled.div`
    margin: 4px 0;

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    overflow: hidden;

    .ant-form-item.alt
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`

const Toolbar = styled.div`
    height: 40px;

    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;

    padding: 4px 4px;
    font-weight: bold;

    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-start;

    .title
    {
        font-weight: bold;
        padding-right: 8px;

        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .grow
    {
        flex-grow: 1;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`



function Component (
    {
        action,

        index,
        length,

        sortIndex, 

        value = {},
        
        onEdit,
        onDelete,
    }
)
{
    const { image } = value

    return (
        <Col
            xs={12}
            sm={8}
            // md={6}
            // lg={6}
            style={{ zIndex: 101 }}
        >
            <Container>
                <Toolbar disabled={!!action} >
                    {length > 1 && <Handle />}
                    <span className="title">{image.title}</span>
                    <div className="grow" />
                    <Row
                        type="flex"
                        gutter={8}
                    >
                        <Col>
                            <Button 
                                type="danger" 
                                icon="delete"
                                style={{ fontSize: 16 }} 
                                disabled={!!action}
                                onClick={() =>
                                {
                                    const m = Modal.confirm(
                                        {
                                            title: `ต้องการลบ "${image.title}" ?`,
                                            okButtonProps: { type: 'danger' },
                                            okText: 'ใช่',
                                            cancelText: 'ไม่ใช่',
                                            onOk: () => 
                                            {
                                                onDelete (sortIndex)
                                                m.destroy ()
                                            },
                                        }
                                    )
                                }}
                            />
                        </Col>
                    </Row>
                </Toolbar>
                <div style={{ padding: 8 }} >
                    <Image 
                        // {...image}
                        data={image} 
                        alt={''}
                        disabled={!!action}
                        ratio={1}
                        fit="scale-down"
                    />
                </div>
            </Container>
        </Col>
    )
}

export default SortableElement(Component)