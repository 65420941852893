import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { Form, Select } from 'antd'

import styled from 'styled-components'

import Node from '../../../post/Tab.Content/node/parent'
import Title from '../../../post/Tab.Overview/Field.Title'
import Link from '../../../../components/Field.Link'

const { Option } = Select

const Container = styled.div`
    background: rgba(0,0,0, 0.025);
    
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 8px;

    .ant-form-item
    {
        width: 100%;
        margin-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

const Row = styled.div`
    width: 100%;

    @media (min-width: 992px)
    {    
        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;
        flex-grow: 1;

        justify-content: space-between;
    }
`

const Col = styled.div`
    @media (min-width: 992px)
    {
        width: calc(50% - 4px);
    }
`

function Formable (
    {
        children,
        form,
    }
)
{
    if (!!form)
    {
        return (
            <Form layout="vertical" >
                {children}
            </Form>
        )
    }

    return children
}

function Comp (
    {
        form,

        action,
        disabled,

        sortIndex,
        length,

        value,

        onChange,
    }
)
{
    const { 
        type = 'phone', 
        title = 'เบอร์โทรติดต่อ', 
        href = '', 
    } = value

    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            onChange ('edit', sortIndex, { ...value, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `รายการที่ ${sortIndex + 1}`

    const nodeTitle = !!title ? title : defaultTitle

    return (
        <Node
            title={nodeTitle}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
            cols={
                {
                    xs: 24,
                }
            }
        >
            <Formable form={form}>
                <Container disabled={!!disabled} >
                    <Row>
                        <Col>
                            <Form.Item
                                {...formLayout}
                                label="ประเภท"
                            >
                                <Select
                                    value={type}
                                    onChange={value => handleChange ('type', value)}
                                >
                                    <Option value="phone">Phone</Option>
                                    <Option value="facebook">Facebook</Option>
                                    <Option value="youtube">Youtube</Option>
                                    <Option value="line">Line</Option>
                                    <Option value="instagram">Instagram</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Title 
                                layout={formLayout}
                                label="ชื่อลิงก์"
                                name="title"
                                value={title}
                                limit={100}
                                disabled={!!disabled}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Link 
                        layout={formLayout}
                        label="ลิงก์"
                        name="href"
                        value={href}
                        limit={250}
                        disabled={!!disabled}
                        onChange={handleChange}
                    />
                </Container>
            </Formable>
        </Node>
    )
}

export default SortableElement(Comp)