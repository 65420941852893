import React from 'react'
import styled from 'styled-components'

import Form from './Form'
import Copyright from '../../components/Copyright'

const Container = styled.div`
    width: 100%;
    height: 100%;

    position: relative;

    .bg
    {
        position: fixed;
        user-select: none;

        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        z-index: 0;
        
        img
        {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }

        &::after
        {
            content: '';

            position: absolute;

            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            z-index: 1;
            
            background-color: rgba(0,0,0, 0.75);
        }
    }

    .fg
    {
        position: fixed;
        z-index: 1;

        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        display: flex;

        flex-direction: column;

        align-items: center;
        justify-content: center;

        padding: 16px;

        .flex
        {
            flex-grow: 1;
        }

        .copyright
        {
            text-shadow: 1px 1px 2px black;
        }
    }
`

function Comp (
    {
        history,
    }
)
{
    return (
        <Container>
            <div className="bg" >
                <img src="/static/images/bg.jpg" alt="background" />
            </div>
            <div className="fg" >
                <div className="flex" />
                <Form history={history} />
                <div className="flex" />
                <div className="copyright" >
                    <Copyright color="white" />
                </div>
            </div>
        </Container>
    )
}

export default Comp