import Obj from '../Compare/object'

function getInvalidFields (data, validations = {})
{
    const 
    {
        overview = {},
    } = data

    let overviewErrors = []

    if (!overview.title)
    {
        overviewErrors.push('title')
    }

    if (!overview.slug || [ 'validating', 'error' ].indexOf(validations.slug) >= 0)
    {
        overviewErrors.push('slug')
    }

    if (!overview.excerpt)
    {
        overviewErrors.push('excerpt')
    }

    if (!overview.image)
    {
        overviewErrors.push('image')
    }

    return [ ...overviewErrors ]
}

function getChangeds (o, c)
{
    // console.log (Obj.getChangeds (o, c))
    const ignoreds = [ '_editor' ]
    return Obj.getChangeds (o, c, ignoreds)

    // const changeds = []

    // const oO = o.overview || {}
    // const cO = c.overview || {}

    // if ((oO.title || '') !== (cO.title || ''))
    // {
    //     changeds.push('overview.title')
    // }

    // if ((oO.slug || '') !== (cO.slug || ''))
    // {
    //     changeds.push('overview.slug')
    // }
    
    // const pa = oO.publishedAt && oO.publishedAt.toDate ? oO.publishedAt.toDate().getTime () : null
    // const pb = cO.publishedAt && cO.publishedAt.toDate ? cO.publishedAt.toDate().getTime () : null

    // if (pa !== pb)
    // {
    //     changeds.push('overview.publishedAt')
    // }
    
    // const oM = o.meta || {}
    // const cM = c.meta || {}

    // if ((oM.title || '') !== (cM.title || ''))
    // {
    //     changeds.push('meta.title')
    // }

    // if ((oM.description || '') !== (cM.description || ''))
    // {
    //     changeds.push('meta.description')
    // }

    // const oMi = oM.image || {}
    // const cMi = cM.image || {}

    // if (oMi.id !== cMi.id)
    // {
    //     changeds.push('meta.image')
    // }
    
    // return changeds
}

export default
{
    getInvalidFields,
    getChangeds,
}