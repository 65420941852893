// import CompareContent from '../Compare/content'
import Obj from '../Compare/object'

function getInvalidFields (data, validations = {})
{
    const 
    {
        overview = {},
    } = data

    let overviewErrors = []

    if (!overview.title)
    {
        overviewErrors.push('title')
    }

    if (!overview.slug || [ 'validating', 'error' ].indexOf(validations.slug) >= 0)
    {
        overviewErrors.push('slug')
    }

    if (!overview.excerpt)
    {
        overviewErrors.push('excerpt')
    }

    if (!overview.image)
    {
        overviewErrors.push('image')
    }   

    return [ ...overviewErrors ]
}

////////////
// function hasTagChangeds (a, b)
// {
//     if (!a && !b)
//     {
//         return false
//     }

//     if (!a && !!b)
//     {
//         return true
//     }

//     if (!!a && !b)
//     {
//         return true
//     }

//     if (a.length !== b.length)
//     {
//         return true
//     }

//     for (let i = 0; i < a.length; i++)
//     {
//         if (b.indexOf(a[i]) < 0)
//         {
//             return true
//         }
//     }

//     for (let i = 0; i < b.length; i++)
//     {
//         if (a.indexOf(b[i]) < 0)
//         {
//             return true
//         }
//     }

//     return false
// }

// function hasGalleryChangeds (a, b)
// {
//     if (!a && !b)
//     {
//         return false
//     }

//     if (!a && !!b)
//     {
//         return true
//     }

//     if (!!a && !b)
//     {
//         return true
//     }

//     if (a.length !== b.length)
//     {
//         return true
//     }

//     for (let i = 0; i < a.length; i++)
//     {
//         if (hasGalleryItemChangeds (a[i], b[i]))
//         {
//             return true
//         }
//     }

//     return false
// }

// function hasGalleryItemChangeds (a, b)
// {
//     if (a.id !== b.id)
//     {
//         return true
//     }

//     if (a.type !== b.type)
//     {
//         return true
//     }

//     const va = a.video || {}
//     const vb = b.video || {}

//     if (va.id !== vb.id)
//     {
//         return true
//     }

//     const la = a.link || ''
//     const lb = b.link || ''

//     if (la !== lb)
//     {
//         return true
//     }

//     const aa = a.caption || ''
//     const ab = b.caption || ''

//     if (aa !== ab)
//     {
//         return true
//     }

//     const ia = a.image || {}
//     const ib = b.image || {}

//     if (ia.id !== ib.id)
//     {
//         return true
//     }

//     return false
// }

function getChangeds (o, c)
{
    const ignoreds = [ '_editor' ]
    return Obj.getChangeds (o, c, ignoreds)
    // const changeds = []

    // const oO = o.overview || {}
    // const cO = c.overview || {}

    // if ((oO.title || '') !== (cO.title || ''))
    // {
    //     changeds.push('overview.title')
    // }

    // if ((oO.slug || '') !== (cO.slug || ''))
    // {
    //     changeds.push('overview.slug')
    // }

    // if ((oO.excerpt || '') !== (cO.excerpt || ''))
    // {
    //     changeds.push('overview.excerpt')
    // }

    // const oImage = oO.image || {}
    // const cImage = cO.image || {}

    // if (oImage.id !== cImage.id)
    // {
    //     changeds.push('overview.image')
    // }

    // if (oO.author !== cO.author)
    // {
    //     changeds.push('overview.author')
    // }

    // if (oO.category !== cO.category)
    // {
    //     changeds.push('overview.category')
    // }

    // const pa = oO.publishedAt && oO.publishedAt.toDate ? oO.publishedAt.toDate().getTime () : null
    // const pb = cO.publishedAt && cO.publishedAt.toDate ? cO.publishedAt.toDate().getTime () : null

    // if (pa !== pb)
    // {
    //     changeds.push('overview.publishedAt')
    // }

    // if (hasTagChangeds(oO.tags, cO.tags))
    // {
    //     changeds.push('overview.tags')
    // }

    // if (hasGalleryChangeds(o.gallery, c.gallery))
    // {
    //     changeds.push('gallery')
    // }

    // if (CompareContent(o.contents, c.contents))
    // {
    //     changeds.push('content')
    // }

    // const oM = o.meta || {}
    // const cM = c.meta || {}

    // if ((oM.title || '') !== (cM.title || ''))
    // {
    //     changeds.push('meta.title')
    // }

    // if ((oM.description || '') !== (cM.description || ''))
    // {
    //     changeds.push('meta.description')
    // }

    // const oMi = oM.image || {}
    // const cMi = cM.image || {}

    // if (oMi.id !== cMi.id)
    // {
    //     changeds.push('meta.image')
    // }
    
    // return changeds
}

export default
{
    getInvalidFields,
    getChangeds,
}