import React from 'react'
import { Link } from 'react-router-dom'

import DateTimeUtil from '../DateTime'

import Title from '../../components/Datatable/Column.Title'
import DateTime from '../../components/Datatable/Column.DateTime'
import Status from '../../pages/posts/Column.Status'

import DatePicker from '../../pages/posts/Filter.DatePicker'

const COLUMNS = 
[
    {
        title: 'โครงการ',
        dataIndex: 'overview.title',
        width: 720,
    },

    {
        title: 'สถานะ',
        dataIndex: 'info.status',
        width: 100,
        render: e => (
            <div style={{ minWidth: 100 }} >
                <Status value={e} />
            </div>
        ),
        filterMultiple: false,
        filters:
        [
            {
                text: 'DRAFT',
                value: 'draft',
            },
            {
                text: 'PUBLISHED',
                value: 'published',
            },
            {
                text: 'CHANGED',
                value: 'changed',
            },
        ],
    },

    {
        title: 'วันที่สร้าง',
        dataIndex: 'info.createdAt',
        width: 104,
        render: e => (
            <div style={{ minWidth: 104 }} >
                <DateTime value={e} />
            </div>
        ),

        defaultSortOrder: 'descend',
        sorter: (a, b) => DateTimeUtil.compare(a.createdAt, b.createdAt),
        sortDirections: ['descend', 'ascend'],

        filterDropdown: DatePicker,
    },
]

function columns (
    {
        keyword,
    }
)
{
    const _columns = [ ...COLUMNS ]
    
    _columns[0].render = function (value, record)
    {
        return (
            <div style={{ minWidth: 220 }} >
                <Link
                    to={`/projects/${record.id}`}
                >
                    <Title 
                        value={value}
                        keyword={keyword}
                    />
                </Link>
            </div>
        )
    }

    return _columns
}

export default
{
    columns,
}