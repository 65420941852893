import React, { useState, useEffect } from 'react'

import User from '../../utils/Space.User'

import Infinite from '../../components/Datatable/Infinite'
import Table from '../../components/Datatable/Table'

import Page from '../../components/Page'

function Comp (
    {
        history,
    }
)
{
    const _page = parseInt (window.sessionStorage.getItem ('users.page') || 1, 10)

    const [ keyword, setKeyword ] = useState('')
    const [ filters, setFilters ] = useState({ })
    const [ sorters, setSorters ] = useState({ })

    const [ loading, setLoading ] = useState(0)
    const [ page, setPage ] = useState(_page)

    const [ items, setItems ] = useState ([])

    let _items = User.Search.filter(items, keyword)

    function handleMount ()
    {
        const unsubscribes = []
        const promises = 
        [
            User.Query.list (function ({ items })
            {
                setItems (items)
                setLoading (2)
            }, page, filters, sorters),
        ]

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleTableChange (pagination, filters, sorters)
    {
        setLoading(0)
        setFilters(filters || {})
        setSorters(sorters || {})
    }

    function handleLoadMore (page)
    {
        window.sessionStorage.setItem ('users.page', page)

        setLoading(1)
        setPage(page)
    }

    function handleCreate ()
    {
        const id = 'create'
        history.push (`/users/${id}`)
    }

    useEffect (handleMount, [ filters, sorters, page ])

    const loadingPage = loading === 0

    const hasMore = loading === 2 && _items.length >= page * window.config.query.limit
    const loadingMore = loading === 1

    return (
        <Page title="รายการผู้ดูแล" >
            <Infinite
                more={hasMore}
                loading={loadingMore}
                onLoad={handleLoadMore}
            >
                <Table 
                    unit="ผู้ดูแล"
                    columns={User.Table.columns ({ keyword })}

                    emptyText="ไม่พบข้อมูล"

                    keyword={keyword}
                    loading={loadingPage}

                    // selection={selection}
                    // actions={actions}

                    items={_items}

                    onCreate={handleCreate}

                    onTableChange={handleTableChange}
                    // onSelectionChange={handleSelectionChange}
                    onKeywordChange={setKeyword}
                />
            </Infinite>
        </Page>
    )
}

export default Comp