import React, { useState } from 'react'

import { SortableElement } from 'react-sortable-hoc'
import { Tabs } from 'antd'

import Node from '../../../../post/Tab.Content/node/parent'

import TabOverview from './Tab.Overview'
import TabPlan from './Tab.Plan'
import Tab3D from './Tab.3D'
import TabGallery from './Tab.Gallery'
import TabMeta from './Tab.Meta'

const { TabPane } = Tabs

const TABS = 
[
    {
        tab: 'ภาพรวม',
        key: 'overview',
    },

    {
        tab: 'ผังบ้าน',
        key: 'plan',
    },

    {
        tab: '3D',
        key: '3d',
    },

    {
        tab: 'แกลเลอรี่',
        key: 'gallery',
    },

    {
        tab: 'เมต้า',
        key: 'meta',
    },
]

function Comp (
    {
        ////
        action,

        sortIndex,
        length,
        
        onChange,
        onUploading,

        createTemp,
        ////

        data = {},

        parent,
        slugs = [],

        // id,
        // slug,

        // meta,

        // heading,
        // image,
        // title,

        // price,
        // detail,

        // plan,
        // walkthrough,
        // gallery,
    }
)
{
    const [ tab, setTab ] = useState(TABS[0].key)
    const disabled = !!action

    function handleChange (name, value)
    {
        onChange ('edit', sortIndex, { ...data, [name]: value })
    }

    const title = !!data.title ? data.title : `แบบที่ ${sortIndex + 1}`

    return (
        <Node
            title={title}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
            cols={{ xs: 24 }}
        >
            <Tabs 
                type="line"
                activeKey={tab}
                onChange={setTab}
                tabBarStyle={{ marginBottom: 0 }}
            >
                {
                    TABS.map(function (tab)
                    {
                        return <TabPane disabled={disabled} {...tab} />
                    })
                }
            </Tabs>

            {
                tab === 'overview' && 
                <TabOverview 
                    parent={parent}
                    slugs={slugs}
                    data={data}
                    action={action}
                    createTemp={createTemp}
                    onChange={handleChange}
                    onUploading={onUploading}
                />
            }
            {
                tab === 'plan' && 
                <TabPlan 
                    data={data.plan}
                    action={action}
                    createTemp={createTemp}
                    onChange={handleChange}
                    onUploading={onUploading}
                />
            }
            {
                tab === '3d' && 
                <Tab3D 
                    data={data.walkthrough}
                    action={action}
                    createTemp={createTemp}
                    onChange={handleChange}
                    onUploading={onUploading}
                />
            }
            {
                tab === 'gallery' && 
                <TabGallery 
                    data={data.gallery}
                    action={action}
                    createTemp={createTemp}
                    onChange={handleChange}
                    onUploading={onUploading}
                />
            }
            {
                tab === 'meta' && 
                <TabMeta 
                    data={data.meta}
                    action={action}
                    createTemp={createTemp}
                    onChange={handleChange}
                    onUploading={onUploading}
                />
            }
        </Node>
    )
}

export default SortableElement(Comp)