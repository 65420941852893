import React from 'react'
import styled from 'styled-components'

import ColorUtil from '@bit/wirunekaewjai.utils.color'

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    text-align: center;
    white-space: nowrap;

    width: 100%;

    padding: 4px;
    font-size: 0.9em;

    @media (min-width: 768px)
    {
        font-size: 1em;
    }
`

const Text = styled.span`
    padding-right: 4px;
`

const Anchor = styled.a`
    font-family: inherit;
    color: #1890ff;
`

function Link ()
{
    return (
        <Anchor
            href="https://www.wolveswork.com"
            target="_blank"
        >
            Wolves Work
        </Anchor>
    )
}

function Comp (
    {
        color = `#333`,
    }
)
{
    const style = 
    {
        color: ColorUtil.rgba (color)
    }

    return (
        <Container style={style} >
            <Text>© Content Management System by</Text><Link />
        </Container>
    )
}

export default Comp