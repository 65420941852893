import React, { useState, useEffect } from 'react'

import Contact from '../../utils/Space.Contact'

import Infinite from '../../components/Datatable/Infinite'
import Table from '../../components/Datatable/Table'

import Page from '../../components/Page'

import SettingsButton from './Settings.Button'

function Comp (
    {
        history,
    }
)
{
    const _page = parseInt (window.sessionStorage.getItem ('contacts.page') || 1, 10)

    const [ expandeds, setExpandeds ] = useState([ ])
    const [ keyword, setKeyword ] = useState('')
    const [ filters, setFilters ] = useState({ })
    const [ sorters, setSorters ] = useState({ })

    const [ loading, setLoading ] = useState(0)
    const [ page, setPage ] = useState(_page)

    const [ contacts, setContacts ] = useState([])
    
    let _contacts = contacts

    _contacts = Contact.Search.filter(_contacts, keyword)

    function handleMount ()
    {
        const unsubscribes = []
        const promises = 
        [
            Contact.Query.list(function ({ keys, items })
            {
                setExpandeds (keys)
                setContacts (items)
                setLoading (2)
            }, page, filters, sorters),
        ]

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleTableChange (pagination, filters, sorters)
    {
        setLoading(0)
        setFilters(filters || {})
        setSorters(sorters || {})
        setExpandeds(contacts.map (c => c.key))
    }

    function handleLoadMore (page)
    {
        window.sessionStorage.setItem ('contacts.page', page)

        setLoading(1)
        setPage(page)
    }

    function handleKeywordChange (keyword)
    {
        setKeyword (keyword)
        setExpandeds (contacts.map (c => c.key))
    }

    function handleSettings ()
    {
        history.push (`/contacts/settings`)
    }

    useEffect (handleMount, [ filters, sorters, page ])

    const loadingPage = loading === 0

    const hasMore = loading === 2 && contacts.length >= page * window.config.query.limit
    const loadingMore = loading === 1

    return (
        <Page title="รายการติดต่อ" >
            <Infinite
                more={hasMore}
                loading={loadingMore}
                onLoad={handleLoadMore}
            >
                <Table 
                    unit="รายการ"
                    columns={Contact.Table.columns ({ keyword })}
                    expanded={Contact.Table.expanded ({ keyword })}
                    expandeds={expandeds}
                    // expandIcon={() => <span></span>}

                    emptyText="ไม่มีรายการ"

                    keyword={keyword}
                    loading={loadingPage}

                    // selection={selection}
                    // actions={actions}

                    items={_contacts}

                    onTableChange={handleTableChange}
                    // onSelectionChange={handleSelectionChange}
                    onKeywordChange={handleKeywordChange}
                    onExpandChange={setExpandeds}

                    extra={
                        <SettingsButton 
                            loadingText="กำลังบันทึก"
                            onClick={handleSettings}
                        />
                    }
                />
            </Infinite>
        </Page>
    )
}

export default Comp