import React from 'react'
import styled from 'styled-components'

const Label = styled.span`
    width: 100%;
    
    display: flex;

    flex-direction: row;
    align-items: flex-end;
`

const Counter = styled.small`
    color: #a7a7a7;

    display: flex;

    flex-direction: row;
    flex-grow: 1;

    align-items: center;
    justify-content: flex-end;
`

function Component(
    {
        text,

        value,
        limit,
    }
)
{
    const style = {}

    if (value >= limit)
    {
        style.color = `#f81d22`
    }
    else if (value / limit >= 0.8)
    {
        style.color = `#faad14`
    }

    return (
        <Label>
            {text}
            <Counter style={style} >
                {`${value} / ${limit} ตัวอักษร`}
            </Counter>
        </Label>
    )
}

export default Component