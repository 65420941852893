import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="Slider" >
            <Group>
                <Item>
                    <Label>Arrow</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="Normal"
                                name="arrow.normal.bgColor"
                                alpha={true}
                                value={select('arrow.normal.bgColor')}
                                onChange={onChange}
                            />
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="Hover"
                                name="arrow.hover.bgColor"
                                alpha={true}
                                value={select('arrow.hover.bgColor')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
                
                <Item>
                    <Label>Dot</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="Normal"
                                name="bullet.normal.bgColor"
                                alpha={true}
                                value={select('bullet.normal.bgColor')}
                                onChange={onChange}
                            />
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="Hover"
                                name="bullet.hover.bgColor"
                                alpha={true}
                                value={select('bullet.hover.bgColor')}
                                onChange={onChange}
                            />
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="Active"
                                name="bullet.active.bgColor"
                                alpha={true}
                                value={select('bullet.active.bgColor')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
            </Group>
        </Card>
    )
}

export default Component