import moment from 'moment'

function parent (doc)
{
    let { 
        status = 'published', 
        publishedAt = new Date().getTime (), 
        ...rests
    } = doc.data ()

    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    return {
        ...rests,

        status,
        publishedAt,
    }
}

function about (doc)
{
    let { 
        status = 'published', 
        publishedAt = new Date().getTime (), 
        ...rests
    } = doc.data ()

    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    return {
        ...rests,

        status,
        publishedAt,
    }
}

function home (doc)
{
    let { 
        status = 'published', 
        publishedAt = new Date().getTime (), 
        ...rests
    } = doc.data ()

    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    return {
        ...rests,

        status,
        publishedAt,
    }
}

function contact (doc)
{
    let { 
        status = 'published', 
        publishedAt = new Date().getTime (), 
        ...rests
    } = doc.data ()

    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    return {
        ...rests,

        status,
        publishedAt,
    }
}

function posts (doc)
{
    let { 
        status = 'published', 
        publishedAt = new Date().getTime (), 
        ...rests
    } = doc.data ()

    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    return {
        ...rests,

        status,
        publishedAt,
    }
}

function projects (doc)
{
    let { 
        status = 'published', 
        publishedAt = new Date().getTime (), 
        ...rests
    } = doc.data ()

    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    return {
        ...rests,

        status,
        publishedAt,
    }
}

function privacyPolicy (doc)
{
    let { 
        status = 'published', 
        publishedAt = new Date().getTime (), 
        ...rests
    } = doc?.data () || {};

    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    return {
        ...rests,

        status,
        publishedAt,
    }
}

export default 
{
    about,
    contact,
    home,
    parent,
    posts,
    projects,
    privacyPolicy,
}