import firebase from 'firebase/app'

async function validateSlug (slug, id)
{
    const { space } = window.config
    
    const cfs = firebase.firestore()
        
    let queryRef = cfs.collection(`${space}/posts`)

    queryRef = queryRef.where('overview.slug', '==', slug)
    queryRef = queryRef.limit(2)

    const snapshot = await queryRef.get()

    if (!!id)
    {
        return snapshot.docs.filter(doc => doc.id !== id).length > 0 ? 'error' : 'success'
    }

    return snapshot.docs.length > 0 ? 'error' : 'success'
}

export default 
{
    slug: validateSlug,
}