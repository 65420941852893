import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Row } from 'antd'

import Item from './node/Item'

function Comp (
    {
        action,
        contents = [],
        createTemp,

        onChange,
        onUploading,
    }
)
{
    return (
        <Row type="flex" >
            {
                contents.map((content, i) =>
                {
                    return (
                        <Item
                            key={content.id}
                            // id={content.id}
                            index={i}
                            sortIndex={i}
                            length={contents.length}
                            onChange={onChange}
                            onUploading={onUploading}
                            action={action}
                            createTemp={createTemp}
                            {...content}
                        />
                    )
                })
                .filter (e => !!e)
            }
        </Row>
    )
}

export default SortableContainer(Comp)