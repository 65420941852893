import React from 'react'
import {
    Icon,
} from 'antd'

import styled from 'styled-components'

const StyledIcon = styled(Icon)`
    border-radius: 50%;
    padding: 4px;

    transition: background-color 0.2s ease;

    @media (hover: hover)
    {
        &:hover
        {
            background-color: #ddd;
        }
    }
`

function Close (
    {
        onClick,
    }
)
{
    return (
        <StyledIcon 
            type="close"
            className="button-close"
            style={{ fontSize: 18 }}
            onClick={onClick}
        />
    )
}

export default Close