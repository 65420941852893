import React from 'react'
import Slider from '../../../components/Field.Slider.1'

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('banner', { ...data, [name]: value })
    }

    return (
        <Slider 
            name="items"
            data={data.items}
            action={action}
            createTemp={createTemp}
            onChange={handleChange}
            onUploading={onUploading}
        />
    )
}

export default Component