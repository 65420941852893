import React from 'react'
import DateTimeUtil from '../DateTime'

import Title from '../../components/Datatable/Column.Title'
import DateTime from '../../components/Datatable/Column.DateTime'

import DatePicker from '../../pages/posts/Filter.DatePicker'

const COLUMNS = 
[
    {
        title: 'ชื่อ - นามสกุล',
        dataIndex: 'name',
        width: 250,
    },

    {
        title: 'อีเมล',
        dataIndex: 'email',
        width: 320,
    },

    {
        title: 'เบอร์โทรศัพท์',
        dataIndex: 'phone',
        width: 150,
    },

    {
        title: 'งบประมาณ',
        dataIndex: 'budget',
        width: 120,
    },

    {
        title: 'โครงการ',
        dataIndex: 'project',
        width: 200,
        
        filterMultiple: false,
        filters:
        [
            
        ],
    },

    {
        title: 'วันที่ลงทะเบียน',
        dataIndex: 'createdAt',
        width: 104,
        render: e => (
            <div style={{ minWidth: 104 }} >
                <DateTime value={e} />
            </div>
        ),

        defaultSortOrder: 'descend',
        sorter: (a, b) => DateTimeUtil.compare(a.createdAt, b.createdAt),
        sortDirections: ['descend', 'ascend'],
        
        filterDropdown: DatePicker,
    },
]

function columns (
    {
        keyword,
        projects = [],
    }
)
{
    const _projects = {}

    projects.forEach (function (p) { _projects[p.value] = p.text })

    const _columns = [ ...COLUMNS ]

    _columns[0].render = function (value, record)
    {
        return (
            <div style={{ minWidth: 160 }} >
                <Title 
                    value={value}
                    keyword={keyword}
                />
            </div>
        )
    }

    _columns[1].render = function (value)
    {
        return (
            <div style={{ minWidth: 240, wordBreak: 'break-word' }} >
                <a href={`mailto:${value}`} style={{ textDecoration: 'underline' }} >
                    <Title 
                        value={value}
                        keyword={keyword}
                    />
                </a>
            </div>
        )
    }

    _columns[2].render = function (value)
    {
        return (
            <div style={{ minWidth: 120 }} >
                <a href={`tel:${value}`} style={{ textDecoration: 'underline' }} >
                    <Title 
                        value={value}
                        keyword={keyword}
                    />
                </a>
            </div>
        )
    }

    _columns[3].render = function (value)
    {
        return (
            <div style={{ minWidth: 100 }} >
                {value || `-`}
            </div>
        )
    }
    
    _columns[4].filters = projects
    _columns[4].render = function (value)
    {
        return (
            <div style={{ minWidth: 180 }} >
                {_projects[value]}
            </div>
        )
    }

    return _columns
}

export default
{
    columns,
}