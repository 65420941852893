
function post (doc)
{
    const authors = (doc.get('authors') || []).map(function (e)
    {
        return { text: e.title, value: e.id }
    })

    const categories = (doc.get('categories') || []).map(function (e)
    {
        return { text: e.title, value: e.id }
    })

    const tags = Object.keys(doc.get('tags') || {})

    return {
        authors,
        categories,
        tags,
    }
}

function site (doc)
{
    return doc.data ()
}

function project (snapshot)
{
    const items = []

    snapshot.docs.forEach (function (doc)
    {
        items.push (
            {
                text: doc.get ('overview').title,
                value: doc.id,
            }
        )
    })

    return items
}

export default
{
    post,
    project,
    
    site,
}