import React from 'react'
import styled from 'styled-components'

import { Form } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'

import Image from '../../../components/Field.Image'

import Box from '../Box'

import Carousel from '../Tab.Concept/Carousel'
import Message from '../Tab.Concept/Message'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('detail', { ...data, [name]: value })
    }

    function handleCarouselChange (name, value)
    {
        const newValue = { ...data.carousel, [name]: value }
        handleChange ('carousel', newValue)
    }

    function handleFeatureChange (name, value)
    {
        const newValue = { ...data.feature, [name]: value }
        handleChange ('feature', newValue)
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const bg = data.bg

    const carousel = data.carousel || {}
    const feature = data.feature || {}
    
    return (
        <Container>
            <Form layout="vertical">
                <Box>
                    <Title 
                        layout={fieldLayout}
                        label="ชื่อหัวข้อ"
                        name="title"
                        value={title}
                        limit={50}
                        disabled={disabled}
                        onChange={handleChange}
                    />

                    <Image 
                        layout={fieldLayout}
                        label="รูปพื้นหลัง"
                        name="bg"
                        position="top"
                        ratio={0.25}
                        value={bg}
                        disabled={disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Box>
            </Form>
            
            <Carousel 
                value={carousel.items}
                title="อัลบั้มรูป"
                name="items"
                action={action}
                onChange={handleCarouselChange}
                onUploading={onUploading}
                createTemp={createTemp}
            />

            <Message 
                value={feature.items}
                title="รายละเอียด"
                name="items"
                limit={3}
                action={action}
                onChange={handleFeatureChange}
            />
        </Container>
    )
}

export default Component