import Document from './Document'
import Parse from './Parse'
import Query from './Query'
import Search from './Search'
import Table from './Table'
import Update from './Update'

export default
{
    Document,
    Parse,
    Query,
    Search,
    Table,
    Update,
}