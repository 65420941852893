import Axios from 'axios'
import firebase from 'firebase/app'

async function update (action, ids = [])
{
    const _url = window.config.url

    const space = window.config.space
    // const url = window.config.url.hq[action]
    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    for (let i = 0; i < ids.length; i++)
    {
        const id = ids[i] === 'posts' ? 'post' : ids[i]
        const url = id === 'post' ? _url.taxonomy[action] : _url.hq[action]

        await Axios.post(url, { space, id }, { headers })
    }
}

async function _publish (ids = [])
{
    await update ('publish', ids)
}

async function _save ({ id, data, temps })
{
    const _url = window.config.url
    const _id = id === 'posts' ? 'post' : id

    const space = window.config.space
    const url = _id === 'post' ? _url.taxonomy.save : _url.hq.save

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    return (await Axios.post(url, { space, id: _id, data, temps }, { headers })).data
}

export default 
{
    save: _save,
    publish: _publish,
}