import React from 'react'

import styled from 'styled-components'

import Screen from '../Screen'
import Header from '../Lightbox/Header'

const Modal = styled.div`
    width: 100%;
    
    /* height: calc(100% - 48px); */

    margin: 24px;
    
    max-width: ${props => props.maxWidth}px;
    max-height: calc(100% - 48px);

    position: relative;

    overflow-x: hidden;
    overflow-y: hidden;
    
    background: white;

    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);

    transform: scale(0);
    transition: transform 0.2s ease;

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    ${props => !!props.open && `
        transform: none;
    `}
`

const Content = styled.div`
    width: 100%;

    overflow-x: hidden;
    overflow-y: auto;
    
    > div
    {
        width: 100%;
        position: relative;

        video
        {
            position: absolute;

            left: 0;
            right: 0;

            top: 0;

            max-width: 100%;

            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }
`

export default function (
    {
        open,

        zIndex = 2,
        onClose,

        video,
        alt = '',
    }
)
{
    if (!video)
    {
        return null
    }

    const { bucket, path, title, mime, width, height } = video
    const paddingBottom = `${(height / width) * 100}%`

    if (!bucket || !path)
    {
        return null
    }

    return (
        <Screen 
            open={!!open} 
            zIndex={zIndex}
        >
            <Modal open={!!open} maxWidth={Math.min (1024, width)} >
                <Header 
                    title={title} 
                    alt={alt}

                    onClose={onClose}
                />
                <Content>
                    <div style={{ paddingBottom }} >
                        {
                            !!open &&
                            <video controls={true} >
                                <source 
                                    type={mime} 
                                    src={`${window.config.url.cloudinary}/${bucket}/video/upload/${path}`} 
                                />
                            </video>
                        }
                    </div>
                </Content>
            </Modal>
        </Screen>
    )
}