import React, { useState, useEffect } from 'react'
import {
    Form,
    Input,
} from 'antd'

import styled from 'styled-components'

import Taxonomy from '../../../../../utils/Space.Taxonomy'
import Label from '../../../../../components/Label.Counter'

const Extra = styled.small`
    word-break: break-all;
    word-wrap: break-word;

    strong
    {
        color: #666;
        text-decoration: underline;
    }
`

function Component(
    {
        parent,

        id,

        layout,
        limit = 80,

        disabled,

        label = 'ชื่อลิงก์',
        name = 'slug',

        value,
        onChange,

        validate,
        validations,
        setValidations,

        errorText = 'มีการใช้ชื่อลิงก์นี้ในแบบบ้านอื่นแล้ว',
    }
)
{
    const [ site, setSite ] = useState(undefined)
    const [ timeoutId, setTimeoutId ] = useState(undefined)

    const path = value || label

    function handleMount ()
    {
        const unsubscribes = []
        const promises = []

        promises.push (Taxonomy.Query.site(setSite))

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())

            if (!!timeoutId)
            {
                clearTimeout(timeoutId)
            }
        }
    }

    function handleChange (e)
    {
        let value = e.target.value

        value = value.toLowerCase()
        value = value.replace(/[^a-z0-9-_]/igm, '')
        value = value.replace(/[-]+/g, '-')
        value = value.slice(0, limit)

        onChange(name, value)
    }

    function handleValidate ()
    {
        if (!!timeoutId)
        {
            clearTimeout (timeoutId)
        }

        if (validations.slug === 'idle')
        {
            setValidations({ ...validations, slug: null })
            return 
        }

        setValidations({ ...validations, slug: 'validating' })

        setTimeoutId(
            setTimeout(function ()
            {
                validate(value, id).then(function (status)
                {
                    if (status === 'error')
                    {
                        setValidations({ ...validations, slug: 'error' })
                    }
                    else
                    {
                        setValidations({ ...validations, slug: null })
                    }
                })
            }, 500)
        )
    }

    useEffect(handleMount, [])
    useEffect(handleValidate, [ value ])

    return (
        <div>
            <Form.Item 
                {...layout}
                label={<Label text={label} value={value.length} limit={limit} />}
                extra={!!site && <Extra>{site.url}/projects/{parent}/models/<strong>{path}</strong></Extra>}
                validateStatus={validations.slug === 'idle' ? '' : validations.slug}
                hasFeedback={true}
                help={validations.slug === 'error' && errorText}
            >
                <Input 
                    // addonBefore={'/'}
                    disabled={disabled}
                    value={value}
                    onChange={handleChange}
                />
            </Form.Item>
        </div>
    )
}

export default Component