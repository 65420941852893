import React from 'react'
import styled from 'styled-components'

import { Form } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'
import Excerpt from '../../post/Tab.Overview/Field.Excerpt'

import Receivers from '../../contacts/Settings/Field.Receivers'

import Image from '../../../components/Field.Image'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('registration', { ...data, [name]: value })
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const bg = data.bg

    const description = data.description || ''
    const phone = data.phone || ''
    const emails = data.emails || []
    
    return (
        <Container>
            <Form layout="vertical">
                <Title 
                    layout={fieldLayout}
                    label="ชื่อหัวข้อ"
                    name="title"
                    value={title}
                    limit={50}
                    disabled={disabled}
                    onChange={handleChange}
                />

                <Excerpt 
                    layout={fieldLayout}
                    label="ข้อความ"
                    name="description"
                    value={description}
                    limit={300}
                    rows={3}
                    minRows={3}
                    maxRows={6}
                    disabled={!!disabled}
                    onChange={handleChange}
                />

                <Title 
                    layout={fieldLayout}
                    label="เบอร์โทรศัพท์"
                    name="phone"
                    value={phone}
                    limit={50}
                    disabled={disabled}
                    onChange={handleChange}
                />

                <Image 
                    layout={fieldLayout}
                    label="รูปพื้นหลัง"
                    name="bg"
                    position="top"
                    ratio={0.25}
                    value={bg}
                    disabled={disabled}
                    onChange={handleChange}
                    onUploading={onUploading}
                    createTemp={createTemp}
                />

                <div style={{ height: 16 }} />

                <Receivers 
                    layout={{ colon: false }}
                    disabled={disabled}
                    data={emails}
                    name="emails"
                    onChange={handleChange}
                />
            </Form>
        </Container>
    )
}

export default Component