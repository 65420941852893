
import React from 'react'

import { 
    Button, 
} from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    button
    {
        padding: 0 8px;

        i
        {
            font-size: 12px;
        }

        @media (min-width: 768px)
        {
            padding: 0 16px;
        }
    }
`

function Comp (
    {
        className,

        loading,
        loadingText,

        disabled,

        icon = 'cloud-download',
        text = 'ดาวโหลด',
        
        onClick,
    }
)
{
    return (
        <Container className={`${className}`} >
            <Button
                type="primary"
                icon={icon}
                disabled={disabled}
                loading={loading}
                onClick={onClick}
            >
                {!!loading && !!loadingText ? loadingText : text}
            </Button>
        </Container>
    )
}

export default Comp