import React from 'react'
import styled from 'styled-components'

import Message from './Message'

const Container = styled.div`
    min-height: 200px;
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('review', { ...data, [name]: value })
    }

    return (
        <Container>
            <Message 
                value={data.items}
                title=""
                name="items"
                limit={20}
                action={action}
                onChange={handleChange}
                onUploading={onUploading}
                createTemp={createTemp}
            />
        </Container>
    )
}

export default Component