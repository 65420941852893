import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'

import {
    Tabs,
    message,
} from 'antd'

import HQ from '../../utils/Space.HQ'

import Page from '../../components/Page'

import Publish from '../hq-home/Field.Publish'
import Loader from '../hq-home/Loader'

import TabProjects from './Tab.Projects'
import TabHQ from './Tab.HQ'
import TabMeta from '../post/Tab.Meta'

const { TabPane } = Tabs

const TABS = 
[
    {
        tab: 'โครงการบ้านเดี่ยว',
        key: 'projects',
    },
    {
        tab: 'สำนักงานใหญ่',
        key: 'hq',
    },
    {
        tab: 'เมต้า',
        key: 'meta',
    },
]

// const VALIDATIONS = 
// {
    
// }

const Container = styled.div`
    @media (min-width: 768px)
    {
        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;
    }
`

const Left = styled.div`
    flex-grow: 1;
`

const Right = styled.div`
    flex-grow: 1;
    flex-shrink: 0;

    max-width: 260px;
    padding: 8px;
`

function Comp ()
{
    const [ action, setAction ] = useState(null)
    const [ tab, setTab ] = useState(TABS[0].key)

    const [ original, setOriginal ] = useState({ })
    const [ current,  setCurrent ] = useState({ })

    // const [ validations, setValidations ] = useState(VALIDATIONS)
    const [ temps, setTemps ] = useState([ ])
    
    // const invalid = !action && HQ.Document.getInvalidFields(current, validations).length > 0
    const changed = !action && HQ.Document.getChangeds(original, current).length > 0

    const canSave = !!changed && !action

    let ready = action !== 'loading'
    let disabled = !!action

    function handleMount ()
    {
        setOriginal({ })
        setCurrent({ })

        setTemps([])
        // setValidations(VALIDATIONS)
        setAction(`loading`)

        const unsubscribes = []
        const promises = []

        promises.push(
            HQ.Query.contact (function ({ original, current })
            {
                if (!!original)
                {
                    setOriginal (original)
                }

                if (!!current)
                {
                    setCurrent (current)
                    setAction (null)
                }
            })
        )

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleChange (name, value)
    {
        setCurrent ({ ...current, [name]: value })
    }

    function handleTabChange (index, value)
    {
        const tabs = current.tabs
        tabs[index] = value

        setCurrent ({ ...current, tabs })
    }

    function handleUploading (uploading)
    {
        setAction(!!uploading ? 'uploading' : null)
    }

    function handleCreateTemp (temp)
    {
        if (!!temp)
        {
            // console.log(temp)
            setTemps([ ...temps, temp ])
        }
    }

    async function handleSave ()
    {
        const _editor = window.localStorage.getItem('editorID')
      
        const tabs = current.tabs || []
        const meta = current.meta || {}

        const data =
        {
            tabs,
            meta,

            _editor,
        }

        setAction('saving')

        try
        {
            const id = 'contact'
            const result = await HQ.Update.save ({ id, data, temps })

            if (result.error)
            {
                setAction(null)
                // setValidations(result.error.validations || { })

                message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
            }
            else
            {
                setAction(null)
                // setValidations({ })
                setTemps([ ])

                message.success('บันทึกเรียบร้อย')
            }
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
        }
    }

    async function handlePublish ()
    {
        setAction('publishing')

        try
        {
            await HQ.Update.publish ([ 'contact' ])

            setAction(null)

            message.success('แผยแพร่เรียบร้อย')
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการเผยแพร่ กรุณาตรวจสอบข้อมูลและเผยแพร่อีกครั้ง')
        }
    }

    useEffect (handleMount, [ ])

    const status = current.status || 'published'
    const publishedAt = current.publishedAt

    const tab0 = !!ready && !!current && !!current.tabs && current.tabs.length > 0 ? current.tabs[0] : {}
    const tab1 = !!ready && !!current && !!current.tabs && current.tabs.length > 1 ? current.tabs[1] : {}

    return (
        <Page title="จัดการข้อมูลหน้าติดต่อ" >
            <Container>
                <Left>
                    <Tabs 
                        type="line"
                        activeKey={tab}
                        onChange={setTab}
                        tabBarStyle={{ marginBottom: 0 }}
                    >
                        {
                            TABS.map(function (tab)
                            {
                                return <TabPane disabled={disabled} {...tab} />
                            })
                        }
                    </Tabs>

                    {
                        !!ready &&
                        <Fragment>
                            {
                                tab === 'projects' && 
                                <TabProjects 
                                    data={tab0}
                                    action={action}
                                    onChange={value => handleTabChange (0, value)}
                                />
                            }
                            {
                                tab === 'hq' && 
                                <TabHQ 
                                    data={tab1}
                                    action={action}
                                    onChange={value => handleTabChange (1, value)}
                                    onUploading={handleUploading}
                                    createTemp={handleCreateTemp}
                                />
                            }
                            {
                                tab === 'meta' && 
                                <TabMeta 
                                    data={current.meta}
                                    action={action}
                                    onChange={value => handleChange ('meta', value)}
                                    onUploading={handleUploading}
                                    createTemp={handleCreateTemp}
                                />
                            }
                        </Fragment>
                    }
                    {!ready && <Loader />}
                </Left>
                <Right>
                    <Publish 
                        action={action}
                        changed={changed}
                        canSave={canSave}
                        status={status}
                        publishedAt={publishedAt}
                        onPublish={handlePublish}
                        onSave={handleSave}
                    />
                </Right>
            </Container>
        </Page>
    )
}

export default Comp