import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'

import styled from 'styled-components'

const Container = styled.div`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: center;

    cursor: move;
    user-select: none;

    line-height: 1;

    width: 40px;
    height: 40px;
`

function Comp ()
{
    return (
        <Container>
            ::
        </Container>
    )
}

export default SortableHandle(Comp)