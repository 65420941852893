import moment from 'moment'

function edit (doc)
{
    const overview = doc.get ('overview') || {}
    
    let createdAt = doc.get('createdAt') || null
    let updatedAt = doc.get('updatedAt') || null
    let publishedAt = overview.publishedAt || new Date ().getTime ()

    if (!!createdAt)
    {
        createdAt = moment(createdAt)
    }

    if (!!updatedAt)
    {
        updatedAt = moment(updatedAt)
    }
    
    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    overview.publishedAt = publishedAt

    const header = doc.get ('header') || {}

    const banner = doc.get('banner') || {}
    const gallery = doc.get('gallery') || {}
    const concept = doc.get('concept') || {}
    const detail = doc.get('detail') || {}
    const facility = doc.get('facility') || {}
    const review = doc.get ('review') || {}
    const model = doc.get ('model') || {}
    const map = doc.get ('map') || {}

    header.contact = (header.contact || []).map ((e, i) =>
    {
        if (!e.id)
        {
            e.id = `header-contact-${i}`
        }

        return e
    })

    banner.items = (banner.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `banner-${i}`
        }

        if (!e.type)
        {
            e.type = 'link'
        }

        return e
    })

    gallery.items = (gallery.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `gallery-${i}`
        }

        if (!e.type)
        {
            e.type = 'image'
        }

        return e
    })

    concept.carousel = concept.carousel || {}
    concept.carousel.items = (concept.carousel.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `concept-carousel-${i}`
        }

        if (!e.type)
        {
            e.type = 'image'
        }

        return e
    })
    
    concept.message = concept.message || {}
    concept.message.items = (concept.message.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `concept-message-${i}`
        }

        return e
    })

    detail.carousel = detail.carousel || {}
    detail.carousel.items = (detail.carousel.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `detail-carousel-${i}`
        }

        return e
    })
    
    detail.feature = detail.feature || {}
    detail.feature.items = (detail.feature.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `detail-feature-${i}`
        }

        return e
    })

    facility.carousel = facility.carousel || {}
    facility.carousel.items = (facility.carousel.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `facility-carousel-${i}`
        }

        return e
    })

    review.items = (review.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `review-${i}`
        }

        return e
    })

    map.nearest = map.nearest || {}
    map.nearest.items = (map.nearest.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `map-nearest-${i}`
        }

        if ((!e.locations || !e.locations.length || e.locations.length === 0) && !!e.items && e.items.length > 0)
        {
            e.locations = e.items.map ((title, j) => 
            { 
                return { 
                    distance: '0',
                    id: `map-nearest-${i}-location-${j}`,
                    title, 
                }
            })
        }

        return e
    })

    model.items = (model.items || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `model-${i}`
        }

        e.gallery = e.gallery || {}
        e.gallery.items = (e.gallery.items || []).map ((e1, j) => 
        {
            if (!e1.id)
            {
                e1.id = `model-${i}-gallery-${j}`
            }

            return e1
        })

        e.plan = e.plan || {}
        e.plan.items = (e.plan.items || []).map ((e1, j) => 
        {
            if (!e1.id)
            {
                e1.id = `model-${i}-plan-${j}`
            }

            return e1
        })

        return e
    })

    return {
        // header: doc.get ('header') || {},
        footer: doc.get ('footer') || {},
        style:  doc.get ('style') || {},

        header,
        overview,
        banner,
        gallery,
        concept,
        detail,
        facility,
        review,
        model,
        map,

        ribbon:   doc.get ('ribbon') || {},
        // model:    doc.get ('model') || {},
        // map:      doc.get ('map') || {},
        registration: doc.get ('registration') || {},
        
        meta: doc.get ('meta') || {},

        info: 
        {
            status: doc.get ('status'),
            createdAt,
            updatedAt,
        },
    }
}

function view (doc)
{
    const overview = doc.get ('overview') || {}
    
    let createdAt = doc.get('createdAt') || null
    let updatedAt = doc.get('updatedAt') || null
    let publishedAt = overview.publishedAt || null

    if (!!createdAt)
    {
        createdAt = moment(createdAt)
    }

    if (!!updatedAt)
    {
        updatedAt = moment(updatedAt)
    }
    
    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    overview.publishedAt = publishedAt

    return {
        key: doc.id,
        id: doc.id,

        overview,

        info: 
        {
            status: doc.get ('status'),
            createdAt,
            updatedAt,
        },
    }
}

export default 
{
    edit,
    view,
}