import React from 'react'
import {
    Divider,
} from 'antd'

import styled from 'styled-components'

import Save from './Save'
import UpdatedAt from './UpdatedAt'

const Toolbar = styled.div`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

    padding: 12px;

    > div
    {
        padding: 4px;
    }

    .grow
    {
        flex-grow: 1;
    }
`

export default function (
    {
        updatedAt,
        exists,

        action,
        canSave,
        onSave,
    }
)
{
    return (
        <div>
            <Divider style={{ margin: 0 }} />
            <Toolbar>
                <div>
                    <UpdatedAt value={updatedAt} />
                </div>
                <div className="grow" />
                <div>
                    <Save 
                        exists={exists}
                        action={action}
                        canSave={canSave}
                        onSave={onSave}
                    />
                </div>
            </Toolbar>
        </div>
    )
}