import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="Header" >
            <Group>
                <Item>
                    <Label>Primary</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="header.primary.bgColor"
                                alpha={false}
                                value={select('header.primary.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ตัวอักษร"
                                name="header.primary.color"
                                alpha={false}
                                value={select('header.primary.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>

                <Item>
                    <Label>Secondary</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="header.secondary.bgColor"
                                alpha={false}
                                value={select('header.secondary.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ตัวอักษร"
                                name="header.secondary.color"
                                alpha={false}
                                value={select('header.secondary.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
            </Group>
        </Card>
    )
}

export default Component