import firebase from 'firebase/app'

import ParseUtil from './Parse'

async function post (callback)
{
    const { space } = window.config
     
    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/taxonomies/post`)

    return await queryRef.onSnapshot(function (doc)
    {
        callback (ParseUtil.post (doc))
    })
}

async function site (callback)
{
    const { space } = window.config
     
    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/taxonomies/site`)

    return await queryRef.onSnapshot(function (doc)
    {
        callback (ParseUtil.site (doc))
    })
}

async function project (callback)
{
    const { space } = window.config
     
    const cfs = firebase.firestore()
        
    let queryRef = cfs.collection(`${space}/projects`)

    return await queryRef.onSnapshot(function (snapshot)
    {
        callback (ParseUtil.project (snapshot))
    })
}

export default 
{
    post,
    project,
    
    site,
}