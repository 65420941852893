import React from 'react'
import styled from 'styled-components'
import ColorUtil from '@bit/wirunekaewjai.utils.color'

import { Form, Divider } from 'antd'

import Group from './Group'
import Item from './Group.Item'

import Overview from './Style.Overview'
import Header from './Style.Header'
import Footer from './Style.Footer'
import FAB from './Style.FAB'
import Slider from './Style.Slider'
import Ribbon from './Style.Ribbon'
import Concept from './Style.Concept'
import Detail from './Style.Detail'
import Model from './Style.Model'
import ModelPage from './Style.Model.Page'
import Facility from './Style.Facility'
import Gallery from './Style.Gallery'
import Map from './Style.Map'
import Review from './Style.Review'
import Registration from './Style.Registration'

const Container = styled.div`
    min-height: 200px;
    padding: 4px;
`

function Component(
    {
        data = {},
        action,

        onChange,
    }
)
{
    function handleChange (name, value)
    {
        const keys = name.split ('.')
        let target = data

        for (let i = 0; i < keys.length - 1; i++)
        {
            const key = keys[i]

            if (!target[key])
            {
                target[key] = {}
            }
            
            target = target[key]
        }

        target[keys[keys.length - 1]] = value

        onChange ('style', { ...data })
    }

    function select (name)
    {
        const keys = name.split ('.')
        let target = data

        for (let i = 0; i < keys.length - 1; i++)
        {
            const key = keys[i]

            if (!target[key])
            {
                const _rgba = ColorUtil.rgba (`#000000`)
                handleChange (name, _rgba)

                return _rgba
            }
            
            target = target[key]
        }

        const color = target[keys[keys.length - 1]]
        const rgba = ColorUtil.rgba (color || `#000000`)

        if (rgba !== color)
        {
            handleChange (name, rgba)
        }

        return rgba
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    return (
        <Form layout="vertical" >
            <Container>
                <Group>
                    <Item>
                        <Overview 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>
                    <Item>
                        <Header 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Footer 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <FAB 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>
                    
                    <Item>
                        <Slider 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Ribbon 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Concept 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Detail 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>
                    
                    <Item>
                        <Model 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Facility 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Gallery 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Map 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Review 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>

                    <Item>
                        <Registration 
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>
                </Group>

                <Divider />
                
                <Group>
                    <Item>
                        <ModelPage
                            fieldLayout={fieldLayout}
                            disabled={disabled}
                            select={select}
                            onChange={handleChange}
                        />
                    </Item>
                </Group>
            </Container>
        </Form>
    )
}

export default Component