import Axios from 'axios'
import firebase from 'firebase/app'

async function update (action, data)
{
    const space = window.config.space
    const url = window.config.url.service.cloudinary[action]

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    await Axios.post(url, { space, ...data }, { headers })
}

async function _delete (data)
{
    await update ('delete', data)
}

async function _activate (data)
{
    await update ('activate', data)
}

async function _save (data)
{
    const space = window.config.space
    const url = window.config.url.service.cloudinary.save

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    return (await Axios.post(url, { space, ...data }, { headers })).data
}

export default 
{
    save: _save,
    delete: _delete,
    activate: _activate,
}