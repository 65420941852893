import React from 'react'
import { Button } from 'antd'

function Component(
    {
        loading,
        disabled,
    }
)
{
    return (
        <Button 
            disabled={disabled}
            loading={loading} 
            icon="upload"  
            block={true}
        >
            {!!loading ? `กำลังอัพโหลด` : `อัพโหลด`}
        </Button>
    )
}

export default Component