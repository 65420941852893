import React from 'react'
import arrayMove from 'array-move'
import styled from 'styled-components'

import { 
    Empty, Dropdown, Button, Menu, Icon,
} from 'antd'

import Util from './util'
import Footer from './Footer'

import Nodes from './Nodes'

const Container = styled.div`
    overflow-x: auto;
    padding: 4px;

    @media (min-width: 768px)
    {
        padding: 8px;
    }
`

const Center = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: 100%;
    padding: 24px 0 48px 0;

    .ant-empty
    {
        padding: 24px;
    }
`

const Note = styled.small`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-start;

    padding: 8px;

    i
    {
        margin-right: 8px;
    }
`

function Comp (
    {
        id,
        action,
        contents = [],

        onChange,
        onUploading,

        createTemp,
    }
)
{
    function handleChange (action, index, node)
    {
        if (typeof onChange === 'function')
        {
            if (action === 'add')
            {
                onChange(Util.add(contents, index, node))
            }
            else if (action === 'edit')
            {
                onChange(Util.edit(contents, index, node))
            }
            else if (action === 'remove')
            {
                onChange(Util.remove(contents, index))
            }
        }
    }

    function handleAdd (type)
    {
        const id = new Date ().getTime ().toString (35)
        const options = 
        {
            // span: 1,
        }

        handleChange ('add', -1, { id, type, options })
    }

    function handleSort ({ oldIndex, newIndex })
    {
        onChange(arrayMove (contents, oldIndex, newIndex))
    }

    if (contents.length === 0)
    {
        return (
            <Center>
                <Empty description="ไม่มีเนื้อหา" />
                <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu>
                            <Menu.Item 
                                key="0"
                                onClick={() => handleAdd('paragraph')}
                            >
                                <span>ข้อความ</span>
                            </Menu.Item>
                            
                            <Menu.Item 
                                key="1"
                                onClick={() => handleAdd('image')}
                            >
                                <span>รูป</span>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button 
                        title="เพิ่มเนื้อหา"
                        type="primary" 
                        // shape="round" 
                        icon="plus" 
                        // size="small"
                    >
                        เพิ่มเนื้อหา
                    </Button>
                </Dropdown>
            </Center>
        )
    }

    return (
        <Container id="content-editor">
            {
                contents.length > 1 &&
                <Note>
                    <Icon type="info-circle" /> คลิกที่ :: ค้างไว้แล้วลากเพื่อเปลี่ยนแปลงลำดับ
                </Note>
            }
            <Nodes 
                axis="xy"
                useDragHandle={true}

                id={id}
                action={action}
                contents={contents}
                createTemp={createTemp}

                onAdd={handleAdd}
                onChange={handleChange}
                onUploading={onUploading}

                onSortEnd={handleSort}
            />
            <Footer 
                disabled={!!action}
                onAdd={handleAdd}
            />
        </Container>
    )
}

export default Comp