import React from 'react'
import {
    Typography,
    Icon,
} from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    flex-shrink: 0;

    width: 100%;
    padding: 0;
`

const Title = styled.div`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

    padding: 8px 16px;

    .label-group
    {
        width: 100%;
    }

    .label
    {
        width: 100%;
        margin-top: 4px;
    }

    ${props => !!props.disabled && `
        .button-close
        {
            pointer-events: none;
            opacity: 0.5;
        }
    `}
`

const StyledIcon = styled(Icon)`
    border-radius: 50%;
    padding: 4px;

    transition: background-color 0.2s ease;

    @media (hover: hover)
    {
        &:hover
        {
            background-color: #ddd;
        }
    }
`

export default function (
    {
        title = '',
        alt = '',

        onClose,
    }
)
{
    return (
        <Container>
            <Title>
                <div className="label-group" >
                    <div className="label">
                        <Typography.Text strong={true}>
                            {title || `Untitled`}
                        </Typography.Text>
                    </div>
                    <div className="label">
                        <Typography.Text>
                            <em></em>
                            {/* <em>{alt || `ไม่มีคำอธิบายรูป`}</em> */}
                        </Typography.Text>
                    </div>
                </div>
                <StyledIcon 
                    type="close"
                    className="button-close"
                    style={{ fontSize: 18 }}
                    onClick={() => onClose ()}
                />
            </Title>
        </Container>
    )
}