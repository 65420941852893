import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { Form, Row, Col } from 'antd'

import Node from '../../../pages/post/Tab.Content/node/parent'
import Image from '../../Field.Image'
import Video from '../../Field.Video'

function Comp (
    {
        action,

        sortIndex,
        length,

        value = {},

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    const { image, video } = value

    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            onChange ('edit', sortIndex, { ...value, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }

    return (
        <Node
            title="วิดีโอ"
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <Form layout="vertical" >
                {/* <Container disabled={!!disabled} > */}
                    <Row
                        style={{ padding: 8 }}
                        gutter={8}
                    >
                        <Col xs={24} md={12} >
                            <Image 
                                layout={formLayout}
                                label="รูป (1280x641)"
                                name="image"
                                value={image}
                                ratio={0.5007}
                                disabled={!!disabled}
                                onChange={handleChange}
                                onUploading={onUploading}
                                createTemp={createTemp}
                            />
                        </Col>

                        <Col xs={24} md={12} >
                            <Video 
                                layout={formLayout}
                                name="video"
                                value={video}
                                ratio={0.5007}
                                disabled={!!disabled}
                                onChange={handleChange}
                                onUploading={onUploading}
                                createTemp={createTemp}
                            />
                        </Col>
                    </Row>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)