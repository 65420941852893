import React, { Fragment, useEffect } from 'react'
import { BrowserRouter, Route } from "react-router-dom"

import querystring from 'querystring'
import firebase from 'firebase/app'

import Global from './styles/core'

import PageSignIn from './pages/sign-in'

import PageBlacklists from './pages/blacklists';

import PageHQAbout from './pages/hq-about'
import PageHQContact from './pages/hq-contact'
import PageHQHome from './pages/hq-home'
import PageHQParent from './pages/hq-parent'
import PageHQPosts from './pages/hq-posts'
import PageHQProjects from './pages/hq-projects'
import PageHQPrivacyPolicy from './pages/hq-privacy-policy'

import PagePosts from './pages/posts'
import PagePost from './pages/post'

import PageProjects from './pages/projects'
import PageProject from './pages/project'

import PageCloudinaries from './pages/cloudinaries'
import PageCloudinary from './pages/cloudinary'

import PageContacts from './pages/contacts'
import PageContactsSettings from './pages/contacts/Settings'
import PageRegistrations from './pages/registrations'

import PageUsers from './pages/users'
import PageUser from './pages/user'

function App () 
{   
    function handleMount ()
    {
        const auth = firebase.auth ()
        
        return auth.onAuthStateChanged (async user =>
            {
                if (!!user)
                {
                    const token = await user.getIdTokenResult (true)
                
                    if (token.claims.role !== 'admin')
                    {
                        await firebase.auth ().signOut ()
                        return
                    }
                }

                const path = window.location.pathname
                const search = window.location.search
                const href = path + search

                if (href.startsWith ('/sign-in'))
                {
                    if (!!user)
                    {
                        const query = querystring.parse (search.slice (1))
                        const redirect = query.redirect || '/posts'
                
                        window.location.href = redirect
                    }
                }
                else if (!user)
                {
                    window.location.href = `/sign-in?redirect=${encodeURIComponent (href)}`
                }
                else
                {
                    const prefixes = 
                    [
                        'hq-about',
                        'hq-contact',
                        'hq-home',
                        'hq-parent',
                        'hq-posts',
                        'hq-projects',
                        'hq-privacy-policy',
                        'posts',
                        'projects',
                        'cloudinary',
                        'registrations',
                        'contacts',
                        'users',
                        'blacklists',
                    ]

                    const pathes = path.split ('/').filter (e => !!e)

                    if (pathes.length === 0 || prefixes.indexOf (pathes[0]) < 0)
                    {
                        window.location.href = '/posts'
                    }
                }
            })
    }

    useEffect (handleMount, [  ])

    return (
        <Fragment>
            <BrowserRouter>
                <Route path="/sign-in" component={PageSignIn} />

                <Route path="/hq-about" component={PageHQAbout} />
                <Route path="/hq-contact" component={PageHQContact} />
                <Route path="/hq-home" component={PageHQHome} />
                <Route path="/hq-parent" component={PageHQParent} />
                <Route path="/hq-posts" component={PageHQPosts} />
                <Route path="/hq-projects" component={PageHQProjects} />
                <Route path="/hq-privacy-policy" component={PageHQPrivacyPolicy} />
                
                <Route path="/posts" component={PagePosts} />
                <Route path="/posts/:id" component={PagePost} />

                <Route path="/projects" component={PageProjects} />
                <Route path="/projects/:id" component={PageProject} />

                <Route path="/cloudinary" component={PageCloudinaries} />
                <Route path="/cloudinary/:id" component={PageCloudinary} />
                
                <Route path="/contacts" component={PageContacts} />
                <Route path="/contacts/settings" component={PageContactsSettings} />
                <Route path="/registrations" component={PageRegistrations} />

                <Route path="/users" component={PageUsers} />
                <Route path="/users/:id" component={PageUser} />

                <Route path="/blacklists" component={PageBlacklists} />
            </BrowserRouter>
            <Global />
        </Fragment>
    )
}

export default App;
