import React, { useState } from 'react'
import styled from 'styled-components'

import Header from './Header'
import Sidebar from './Sidebar'

import Copyright from '../Copyright'

const Container = styled.div`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    width: 100%;
    min-height: 100%;

    overflow-x: hidden;

    .content
    {
        background: #fcfcfc;

        width: 100%;
        min-width: 320px;
        
        overflow: hidden;

        display: flex;
        flex-direction: column;
    }

    .copyright
    {
        padding: 15px 0;
    }

    .ant-layout-sider
    {
        border-right: 1px solid #e8e8e8;
    }
`

function Comp (
    {
        title,
        children,
    }
)
{
    const [ collapsed, setCollapse ] = useState(false)

    function handleToggle ()
    {
        setCollapse (!collapsed)
    }

    return (
        <Container>
            <Sidebar 
                collapsed={collapsed}
                setCollapse={setCollapse}
            />
            <div className="content">
                <Header
                    title={title}
                    toggle={handleToggle}
                />
                {children}
                <div className="copyright">
                    <Copyright />
                </div>
            </div>
        </Container>
    )
}

export default Comp