
function hasChanged (a, b)
{
    if (a.id !== b.id)
    {
        return true
    }

    if (a.type !== b.type)
    {
        return true
    }

    if (a.path !== b.path)
    {
        return true
    }

    if (a.bucket !== b.bucket)
    {
        return true
    }

    return false
}

export default
{
    hasChanged,
}