import React from 'react'
import {
    Form,
    Input,
} from 'antd'

import Label from '../../components/Label.Counter'
import Delay from '../../components/DelayedInput'

function Component(
    {
        layout,
        limit = 80,

        disabled,

        label = 'ชื่อลิงก์',
        name = 'slug',

        value,
        onChange,
    }
)
{
    function handleChange (changedValue)
    {
        onChange(name, changedValue)
    }

    function handleTransform (e)
    {
        let value = e.target.value

        value = value.toLowerCase()
        value = value.replace(/[^a-z0-9-_]/igm, '')
        value = value.replace(/[-]+/g, '-')
        value = value.slice(0, limit)

        return value
    }

    return (
        <Delay 
            value={value}
            onChange={handleChange}
        >
            {
                ({ value, onChange }) => (
                    <Form.Item 
                        {...layout}
                        label={<Label text={label} value={value.length} limit={limit} />}
                    >
                        <Input 
                            disabled={disabled}
                            value={value}
                            onChange={function (e)
                            {
                                onChange (handleTransform (e))
                            }}
                        />
                    </Form.Item>
                )
            }
        </Delay>
    )
}

export default Component