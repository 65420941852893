import React from 'react'
import Infinite from 'react-infinite-scroller'

import { 
    Spin,
} from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    background-color: white;

    .ant-table-body
    {
        overflow-x: auto !important;
    }
`

function Loader (
    {
        className,
    }
)
{
    return (
        <div 
            className={className} 
            style={{ textAlign: 'center', padding: 16 }} 
        >
            <Spin />
        </div>
    )
}

function Comp (
    {
        loading,
        more,

        onLoad,

        children,
    }
)
{
    return (
        <Container>
            <Infinite
                pageStart={1}
                threshold={100}
                hasMore={more}
                loadMore={onLoad}
            >
                {children}
            </Infinite>
            {!!loading && <Loader />}
        </Container>
    )
}

export default Comp