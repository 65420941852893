import React, { Fragment } from 'react'
// import styled from 'styled-components'

import { Button, Modal } from 'antd'

// const Container = styled.div`
//     background-color: #f9f9f9;
//     border: 1px solid #d9d9d9;
//     border-radius: 4px;

//     padding: 8px;

//     .label
//     {
//         padding-bottom: 8px;

//         display: flex;

//         flex-direction: row;
//         flex-wrap: nowrap;

//         .field
//         {
//             padding-right: 6px;
//         }

//         .grow
//         {
//             flex-grow: 1;
//         }
//     }

// `

// function Group (
//     {
//         children,
//     }
// )
// {
//     return (
//         <Container>
//             {children}
//         </Container>
//     )
// }

function Component(
    {
        action,

        canReset,
        canDelete,

        onSendResetPassword,
        onDelete,
    }
)
{
    return (
        <Fragment>
            {
                !!canReset &&
                <Fragment>
                    <Button
                        icon="mail"
                        type="primary"
                        block={true}
                        disabled={!!action}
                        loading={action === 'sending'}
                        style={{ margin: `4px 0` }}
                        onClick={
                            () =>
                            {
                                const m = Modal.confirm(
                                    {
                                        title: `ต้องการส่งคำขอเปลี่ยนรหัสผ่าน ?`,
                                        okButtonProps: { type: 'primary' },
                                        okText: 'ใช่',
                                        cancelText: 'ไม่ใช่',
                                        onOk: () => 
                                        {
                                            onSendResetPassword ()
                                            m.destroy ()
                                        },
                                    }
                                )
                            }
                        }
                    >
                        ส่งคำขอเปลี่ยนรหัสผ่าน
                    </Button>
                    {/* <Divider style={{ margin: `8px 0` }} /> */}
                </Fragment>
            }
            {
                !!canDelete &&
                <Button
                    icon="delete"
                    type="danger"
                    block={true}
                    disabled={!!action}
                    loading={action === 'deleting'}
                    onClick={
                        () =>
                        {
                            const m = Modal.confirm(
                                {
                                    title: `ต้องการลบ ?`,
                                    okButtonProps: { type: 'danger' },
                                    okText: 'ใช่',
                                    cancelText: 'ไม่ใช่',
                                    onOk: () => 
                                    {
                                        onDelete ()
                                        m.destroy ()
                                    },
                                }
                            )
                        }
                    }
                >
                    ลบ
                </Button>
            }
        </Fragment>
    )
}

export default Component