import React from 'react'
import styled from 'styled-components'

import DateTimeUtil from '../DateTime'

import Title from '../../components/Datatable/Column.Title'
import DateTime from '../../components/Datatable/Column.DateTime'

import DatePicker from '../../pages/posts/Filter.DatePicker'

const Expanded = styled.span`
    display: flex;
    margin-bottom: 16px;
`

const COLUMNS = 
[
    {
        title: 'ชื่อ - นามสกุล',
        dataIndex: 'name',
        width: 250,
    },

    {
        title: 'อีเมล',
        dataIndex: 'email',
        width: 320,
    },

    {
        title: 'เบอร์โทรศัพท์',
        dataIndex: 'phone',
        width: 150,
    },

    // {
    //     title: 'ข้อความ',
    //     dataIndex: 'message',
    //     width: 480,
    //     render: e => (
    //         <small style={{ minWidth: 250 }} >
    //             {e}
    //         </small>
    //     ),
    // },

    {
        title: 'วันที่ส่งข้อความ',
        dataIndex: 'createdAt',
        width: 104,
        render: e => (
            <div style={{ minWidth: 104 }} >
                <DateTime value={e} />
            </div>
        ),

        defaultSortOrder: 'descend',
        sorter: (a, b) => DateTimeUtil.compare(a.createdAt, b.createdAt),
        sortDirections: ['descend', 'ascend'],
        
        filterDropdown: DatePicker,
    },
]

function columns (
    {
        keyword,
    }
)
{
    const _columns = [ ...COLUMNS ]

    _columns[0].render = function (value, record)
    {
        return (
            <div style={{ minWidth: 160 }} >
                <Title 
                    value={value}
                    keyword={keyword}
                />
            </div>
        )
    }

    _columns[1].render = function (value)
    {
        return (
            <div style={{ minWidth: 240, wordBreak: 'break-word' }} >
                <a href={`mailto:${value}`} style={{ textDecoration: 'underline' }} >
                    <Title 
                        value={value}
                        keyword={keyword}
                    />
                </a>
            </div>
        )
    }

    _columns[2].render = function (value)
    {
        return (
            <div style={{ minWidth: 120 }} >
                <a href={`tel:${value}`} style={{ textDecoration: 'underline' }} >
                    <Title 
                        value={value}
                        keyword={keyword}
                    />
                </a>
            </div>
        )
    }

    return _columns
}

function expanded ({ keyword })
{
    return function (record)
    {
        return (
            <Expanded>
                <Title
                    value={record.message}
                    keyword={keyword}
                />
            </Expanded>
        )
    }
}

export default
{
    columns,
    expanded,
}