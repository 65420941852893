import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="แผนที่" >
            <Group>
                <Item>
                    <Label></Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="map.bgColor"
                                alpha={false}
                                value={select('map.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="หัวข้อ"
                                name="map.title.color"
                                alpha={false}
                                value={select('map.title.color')}
                                onChange={onChange}
                            />

                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ชื่อสถานที่"
                                name="map.nearest.color"
                                alpha={false}
                                value={select('map.nearest.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
                
                <Item>
                    <Label>ปุ่ม</Label>
                    <Group group>
                        <Item>
                            <Label>Normal</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="map.button.normal.bgColor"
                                        alpha={false}
                                        value={select('map.button.normal.bgColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="เส้นขอบ"
                                        name="map.button.normal.borderColor"
                                        alpha={false}
                                        value={select('map.button.normal.borderColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ตัวอักษร"
                                        name="map.button.normal.color"
                                        alpha={false}
                                        value={select('map.button.normal.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>
                        <Item>
                            <Label>Hover</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="map.button.hover.bgColor"
                                        alpha={false}
                                        value={select('map.button.hover.bgColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="เส้นขอบ"
                                        name="map.button.hover.borderColor"
                                        alpha={false}
                                        value={select('map.button.hover.borderColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ตัวอักษร"
                                        name="map.button.hover.color"
                                        alpha={false}
                                        value={select('map.button.hover.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>
                        <Item>
                            <Label>Active</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="map.button.active.bgColor"
                                        alpha={false}
                                        value={select('map.button.active.bgColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="เส้นขอบ"
                                        name="map.button.active.borderColor"
                                        alpha={false}
                                        value={select('map.button.active.borderColor')}
                                        onChange={onChange}
                                    />
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ตัวอักษร"
                                        name="map.button.active.color"
                                        alpha={false}
                                        value={select('map.button.active.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>
                    </Group>
                </Item>
            </Group>
        </Card>
    )
}

export default Component