import Document from './Document'
// import Parse from './Parse'
import Query from './Query'
import Search from './Search'
// import Selection from './Selection'
import Table from './Table'
import Update from './Update'
// import Validate from './Validate'

export default
{
    Document,
    // Parse,
    Query,
    Search,
    // Selection,
    Table,
    Update,
    // Validate,
}