import React from 'react'
import {
    Button,
} from 'antd'

export default function (
    {
        action,

        canSave,
        onSave,
    }
)
{
    return (
        <Button 
            type="primary" 
            onClick={onSave}
            loading={action === 'saving'}
            disabled={(!!action && action !== 'saving') || !canSave}
        >
            {action === 'saving' && `กำลัง`}
            บันทึก
        </Button>
    )
}