import React from 'react'
import ReactSVG from 'react-svg'

import Input from './Input'

function InputEmail (
    {
        defaultValue = '',
        onChange,

        error,
        disabled,
    }
)
{
    return (
        <Input 
            type="email"
            placeholder="อีเมล"
            defaultValue={defaultValue}
            minLength={1}
            maxLength={120}
            onChange={onChange}
            error={error}
            disabled={disabled}
            prefix={
                <div className="icon" >
                    <ReactSVG src="/static/icons/twotone-account-circle.svg" />
                </div>
            }
        />
    )
}

export default InputEmail