import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="แนวคิด" >
            <Group>
                <Item>
                    <Label> </Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="concept.bgColor"
                                alpha={false}
                                value={select('concept.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="หัวข้อ"
                                name="concept.title.color"
                                alpha={false}
                                value={select('concept.title.color')}
                                onChange={onChange}
                            />

                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="แนวคิด"
                                name="concept.quote.color"
                                alpha={false}
                                value={select('concept.quote.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
                <Item>
                    <Label>รายละเอียด</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="หัวข้อ"
                                name="concept.message.primary.color"
                                alpha={false}
                                value={select('concept.message.primary.color')}
                                onChange={onChange}
                            />

                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ข้อความ"
                                name="concept.message.secondary.color"
                                alpha={false}
                                value={select('concept.message.secondary.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
            </Group>
        </Card>
    )
}

export default Component