import React from 'react'
import arrayMove from 'array-move'
import styled from 'styled-components'

import { 
    Empty, Button, Icon, Divider,
} from 'antd'

import Util from '../../../post/Tab.Content/util'

import Footer from './Footer'
import Grid from './Grid'

const Container = styled.div`
    overflow-x: auto;

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    margin-top: 16px;
`

const Center = styled.div`

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: 100%;
    padding: 24px 0 48px 0;

    margin-top: 16px;

    .ant-empty
    {
        padding: 24px;
    }
`

const Note = styled.small`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-start;

    padding: 8px;

    i
    {
        margin-right: 8px;
    }
`

function Comp (
    {
        title = '',
        action,

        name,
        value = [],

        onChange,
    }
)
{
    function handleChange (action, index, node)
    {
        if (typeof onChange === 'function')
        {
            if (action === 'add')
            {
                onChange(name, Util.add(value, index, node))
            }
            else if (action === 'edit')
            {
                onChange(name, Util.edit(value, index, node))
            }
            else if (action === 'remove')
            {
                onChange(name, Util.remove(value, index))
            }
        }
    }

    function handleAdd ()
    {
        const id = new Date ().getTime ().toString (35)

        handleChange ('add', -1, { id })
    }

    function handleSort ({ oldIndex, newIndex })
    {
        onChange(name, arrayMove (value, oldIndex, newIndex))
    }

    if (value.length === 0)
    {
        return (
            <Center>
                {!!title && <Divider type="horizontal" orientation="center">{title}</Divider>}
                <Empty description="ไม่มีข้อมูล" />
                {/* <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu>
                            <Menu.Item 
                                key="1"
                                onClick={() => handleAdd('link')}
                            >
                                <span>รูป</span>
                            </Menu.Item>

                            <Menu.Item 
                                key="2"
                                onClick={() => handleAdd('video')}
                            >
                                <span>วิดีโอ</span>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button 
                        title="เพิ่มเนื้อหา"
                        type="primary" 
                        icon="plus" 
                        disabled={!!action}
                    >
                        เพิ่มเนื้อหา
                    </Button>
                </Dropdown> */}
                <Button 
                    title="เพิ่มเนื้อหา"
                    type="primary" 
                    icon="plus" 
                    disabled={!!action}
                    onClick={() => handleAdd()}
                >
                    เพิ่มเนื้อหา
                </Button>
            </Center>
        )
    }

    return (
        <Container id="content-editor">
            {!!title && <Divider type="horizontal" orientation="center">{title}</Divider>}
            {
                value.length > 1 &&
                <Note>
                    <Icon type="info-circle" /> คลิกที่ :: ค้างไว้แล้วลากเพื่อเปลี่ยนแปลงลำดับ
                </Note>
            }
            <Grid 
                axis="xy"
                useDragHandle={true}

                action={action}
                contents={value}

                onChange={handleChange}
                onSortEnd={handleSort}
            />
            <Footer 
                disabled={!!action}
                onAdd={handleAdd}
            />
        </Container>
    )
}

export default Comp