import styled from 'styled-components'

const Item = styled.div`
    padding: 2px;

    ${props => props.row && `
        width: 100%;
    `}

    ${props => props.group && `
        border: 1px solid #d9d9d9;
        border-radius: 4px;
    `}
`

export default Item