import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'

import { Form, Row, Col } from 'antd'

import Node from '../../../Tab.Content/node/parent'
import Image from '../../../../../components/Field.Image'
import Video from '../../../../../components/Field.Video'
// import Caption from '../../../../../components/Field.Caption'

const StyledForm = styled(Form)`
    padding: 8px;

    .ant-form-item
    {
        margin-bottom: 0;
    }
`

function Comp (
    {
        // title = 'วิดีโอ',
        
        action,

        sortIndex,
        length,

        id,
        type,

        image,
        video,
        caption = '',

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const old = { id, type, image, video, caption }
            onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `รายการที่ ${sortIndex + 1} (วิดีโอ)`

    let title = defaultTitle

    return (
        <Node
            title={title}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <StyledForm layout="vertical" >
                <Row gutter={8} >
                    <Col xs={24} sm={12} md={8} >
                        <Image 
                            layout={formLayout}
                            label="ไฟล์รูป (Thumbnail)"
                            name="image"
                            value={image}
                            ratio={0.5625}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={8} >
                        <Video 
                            layout={formLayout}
                            name="video"
                            value={video}
                            ratio={0.5625}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </Col>
                    {/* <Col xs={24} md={10} >
                        <Caption 
                            id={id}
                            layout={formLayout}
                            rows={10}
                            minRows={6}
                            disabled={disabled}
                            value={caption}
                            onChange={handleChange}
                        />
                    </Col> */}
                </Row>
            </StyledForm>
        </Node>
    )
}

export default SortableElement(Comp)