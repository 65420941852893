import React, { useState }  from 'react'
import styled from 'styled-components'

import {
    Form,
    Upload,
    Modal,
    Icon,
} from 'antd'

import Media from '../../utils/Space.Media'
import Image from './Image.Preview'

import ButtonUpload from './Button.Upload'
import ButtonDelete from './Button.Delete'

const Label = styled.span`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;

    cursor: pointer;

    @media (hover: hover)
    {
        &:hover
        {
            text-decoration: underline;
        }
    }

    ${props => props.disabled && `
        pointer-events: none;
    `}

    .title
    {
        padding-right: 8px;
    }
`

const Uploader = styled.div`
    .ant-upload
    {
        width: 100%;
    }

    .actions
    {
        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;

        align-items: center;

        padding: 4px 0;

        .upload
        {
            flex-grow: 1;
        }

        .delete
        {
            padding-left: 8px;
            width: 80px;
        }
    }
`

function Comp (
    {
        layout,
        label = 'ไฟล์รูป',

        ratio = 1,
        
        fit = 'cover',
        position = 'center',

        name = 'image',
        value,

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    const [ uploading, setUploading ] = useState(false)
    const [ open, setOpen ] = useState(true)
 
    function handleUpload (info)
    {
        setUploading (info.file.status === 'uploading')
        
        if (typeof onUploading === 'function')
        {
            onUploading (info.file.status === 'uploading')
        }
        
        if (info.file.status === 'done')
        {
            handleTemp ()
            handleChange (info.file.response[0])
        }
    }

    function handleChange (newValue)
    {
        if (typeof onChange === 'function')
        {
            onChange (name, newValue)
        }
    }

    function handleDelete ()
    {
        handleTemp ()
        handleChange (undefined)
    }

    function handleTemp ()
    {
        if (!!value && typeof createTemp === 'function')
        {
            createTemp (value)
        }
    }

    async function handleRequest ({ file, onSuccess })
    {
        const result = await Media.Upload.image (file)
        onSuccess ([ result ])
    }

    return (
        <Form.Item
            {...layout}
            label={
                <Label
                    disabled={!!disabled || !!uploading || !label} 
                    onClick={() => setOpen (!open)} 
                >
                    <span className="title" >{label}</span>
                    {
                        !!label &&
                        <Icon
                            type={'right'}
                            style={{ fontSize: 8 }}
                            rotate={!!open ? 90 : 0}
                        />
                    }
                </Label>
            }
            style={{ margin: 0, padding: 0 }}
        >
            {
                !!open &&
                <Uploader>
                    <Image 
                        data={value} 
                        ratio={ratio} 
                        fit={fit}
                        position={position}
                    />
                    <div className="actions">
                        <div className="upload">
                            <Upload
                                listType="picture"
                                showUploadList={false}
                                // action={window.config.url.upload.image}
                                action={null}
                                customRequest={handleRequest}
                                beforeUpload={Media.Validate.image}
                                onChange={handleUpload}
                                disabled={!!disabled || !!uploading}
                            >
                                <ButtonUpload
                                    disabled={!!disabled}
                                    loading={!!uploading} 
                                />
                            </Upload>
                        </div>
                        <div className="delete">
                            <ButtonDelete
                                disabled={!!disabled || !!uploading || !value} 
                                onClick={
                                    () =>
                                    {
                                        const m = Modal.confirm(
                                            {
                                                title: 'ต้องการลบรูปนี้ ?',
                                                okButtonProps: { type: 'danger' },
                                                okText: 'ใช่',
                                                cancelText: 'ไม่ใช่',
                                                onOk: () => 
                                                {
                                                    handleDelete ()
                                                    m.destroy ()
                                                },
                                            }
                                        )
                                    }
                                }
                            />
                        </div>
                    </div>
                </Uploader>
            }
        </Form.Item>
    )
}

export default Comp