
export default {
    colors: 
    [
        '#000000', // Black
        '#F44336', // Red
        '#FF9800', // Amber
        '#FFEB3B', // Yellow
        '#009688', // Teal
        '#03A9F4', // Light Blue
        '#3F51B5', // Indigo

        //
        '#FAFAFA',
        '#FFCDD2',
        '#FFE0B2',
        '#FFF59D',
        '#B2DFDB',
        '#B3E5FC',
        '#C5CAE9',

        //
        '#E0E0E0',
        '#E57373',
        '#FFB74D',
        '#FFEE58',
        '#4DB6AC',
        '#4FC3F7',
        '#7986CB',

        //
        '#9E9E9E',
        '#D32F2F',
        '#F57C00',
        '#FDD835',
        '#00796B',
        '#0288D1',
        '#303F9F',

        //
        '#616161',
        '#B71C1C',
        '#E65100',
        '#FBC02D',
        '#004D40',
        '#01579B',
        '#1A237E',
    ] 
}