import React from 'react'
import styled from 'styled-components'

import { Form } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'
import Image from '../../../components/Field.Image'

import Box from '../Box'
import Carousel from './Carousel'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('review', { ...data, [name]: value })
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const bg = data.bg

    const items = data.items || []

    return (
        <Container>
            <Form layout="vertical">
                <Box>
                    <Title 
                        layout={fieldLayout}
                        label="ชื่อหัวข้อ"
                        name="title"
                        value={title}
                        limit={50}
                        disabled={disabled}
                        onChange={handleChange}
                    />

                    <Image 
                        layout={fieldLayout}
                        label="รูปพื้นหลัง"
                        name="bg"
                        position="top"
                        ratio={0.25}
                        value={bg}
                        disabled={disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Box>
            </Form>

            <Carousel 
                value={items}
                title="รายการรีวิว"
                name="items"
                action={action}
                onChange={handleChange}
                onUploading={onUploading}
                createTemp={createTemp}
            />
        </Container>
    )
}

export default Component