import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="ภาพรวม" >
            <Group>
                <Item>
                    <Label>เว็บไซต์</Label>
                    <Group group >
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="root.bgColor"
                                alpha={false}
                                value={select('root.bgColor')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>

                <Item>
                    <Label>เนื้อหา</Label>
                    <Group group >
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="content.bgColor"
                                alpha={false}
                                value={select('content.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ตัวอักษร"
                                name="content.color"
                                alpha={false}
                                value={select('content.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
            </Group>
        </Card>
    )
}

export default Component