import { Modal } from 'antd'

// import DocumentUtil from './Document'

const COLORS = 
{
    disabled: `rgba(0, 0, 0, 0.25)`,
    delete: `#f5222d`,
    reset: `#1890ff`,
}

function getActions (
    {
        selection = [],
        items = [],

        onPublish,
        onUnpublish,
        onDelete,
        onClear,
    }
)
{
    const selecteds = items.filter(item => selection.indexOf(item.id) >= 0)
    const canDelete = selecteds.length > 0;

    const actions = 
    [
        {
            key: `selection-delete`,
            title: `ลบ`,
            style:
            {
                olor: !!canDelete ? COLORS.delete : COLORS.disabled
            },
            disabled: !canDelete,
            onClick: () => 
            {
                const m = Modal.confirm(
                    {
                        title: `ต้องการลบอีเมล ?`,
                        okButtonProps: { type: 'danger' },
                        okText: 'ใช่',
                        cancelText: 'ไม่ใช่',
                        onOk: () => 
                        {
                            onDelete (selecteds)
                            m.destroy ()
                        },
                    }
                )
            },
        },
        {
            key: `selection-divider`,
            title: null,
        },
        {
            key: `selection-reset`,
            title: `รีเซ็ต`,
            style:
            {
                color: COLORS.reset
            },
            disabled: false,
            onClick: () => onClear(),
        }
    ]

    return actions
}

export default
{
    COLORS,
    getActions,
}