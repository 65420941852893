import React from 'react'
import { Tag } from 'antd'

const COLORS = 
{
    'active': 'green',
    'inactive': 'orange',
}

export default function (
    {
        value = 'inactive',
    }
)
{
    return (
        <Tag color={COLORS[value]}>{value}</Tag>
    )
}