import React from 'react'
import { Spin } from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    position: absolute;

    left: 0;
    right: 0;

    top: 0;
    bottom: 0;

    text-align: center;
    padding: 16px;
`

function Loader ()
{
    return (
        <Container>
            <Spin />
        </Container>
    )
}

export default Loader