import React from 'react'
import { Icon } from 'antd'
import styled from 'styled-components'

const Label = styled.span`
    /* display: flex;

    flex-direction: column;
    justify-content: center; */
`

export default function (
    {
        count = 0,
    }
)
{
    return (
        <Label>
            {/* <span>จำนวน {count} รูป</span> */}
            {
                count > 0 &&
                <small><Icon type="info-circle" /> คลิกที่ :: ค้างไว้แล้วลากเพื่อเปลี่ยนแปลงลำดับ</small>
            }
        </Label>
    )
}