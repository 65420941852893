import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="แกลเลอรี่" >
            <Group>
                <Item>
                    <Color 
                        disabled={disabled}
                        layout={fieldLayout}
                        label="พื้นหลัง"
                        name="gallery.bgColor"
                        alpha={false}
                        value={select('gallery.bgColor')}
                        onChange={onChange}
                    />
                    
                    <Color 
                        disabled={disabled}
                        layout={fieldLayout}
                        label="หัวข้อ"
                        name="gallery.title.color"
                        alpha={false}
                        value={select('gallery.title.color')}
                        onChange={onChange}
                    />
                </Item>
            </Group>
        </Card>
    )
}

export default Component