function getInvalidFields (data)
{
    let errors = []

    if (!data.name)
    {
        errors.push('name')
    }

    if (!data.key)
    {
        errors.push('key')
    }

    if (!data.secret)
    {
        errors.push('secret')
    }   

    return errors
}

function getChangeds (o, c)
{
    let changeds = []

    if (o.name !== c.name)
    {
        changeds.push('name')
    }

    if (o.key !== c.key)
    {
        changeds.push('key')
    }

    if (o.secret !== c.secret)
    {
        changeds.push('secret')
    }   

    return changeds
}

export default
{
    getInvalidFields,
    getChangeds,
}