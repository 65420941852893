import moment from 'moment'

function edit (doc)
{
    const overview = doc.get ('overview') || {}
    
    let createdAt = doc.get('createdAt') || null
    let updatedAt = doc.get('updatedAt') || null
    let publishedAt = overview.publishedAt || null

    if (!!createdAt)
    {
        createdAt = moment(createdAt)
    }

    if (!!updatedAt)
    {
        updatedAt = moment(updatedAt)
    }
    
    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    overview.publishedAt = publishedAt

    const gallery = (doc.get('gallery') || []).map ((e, i) => 
    {
        if (!e.id)
        {
            e.id = `gallery-${i}`
        }

        if (!e.type)
        {
            e.type = 'image'
        }

        return e
    })

    return {

        contents: doc.get ('contents') || [],
        meta: doc.get ('meta') || {},

        overview,
        gallery,

        info: 
        {
            status: doc.get ('status'),
            createdAt,
            updatedAt,
        },
    }
}

function view (doc)
{
    const overview = doc.get ('overview') || {}
    
    let createdAt = doc.get('createdAt') || null
    let updatedAt = doc.get('updatedAt') || null
    let publishedAt = overview.publishedAt || null

    if (!!createdAt)
    {
        createdAt = moment(createdAt)
    }

    if (!!updatedAt)
    {
        updatedAt = moment(updatedAt)
    }
    
    if (!!publishedAt)
    {
        publishedAt = moment(publishedAt)
    }

    overview.publishedAt = publishedAt

    return {
        key: doc.id,
        id: doc.id,

        overview,

        info: 
        {
            status: doc.get ('status'),
            createdAt,
            updatedAt,
        },
    }
}

export default 
{
    edit,
    view,
}