import React from 'react'

import { 
    Dropdown,
    Menu,
    Button,
    Icon,
} from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    display: flex;

    flex-direction: column;

    justify-content: center;
`

function Selection (
    {
        unit = 'รายการ',
        count = 0,

        actions = [],
    }
)
{
    return (
        <Container>
            <Dropdown
                trigger={[ 'click' ]}
                overlay={
                    <Menu>
                        {
                            actions.map ((
                                {
                                    key,
                                    title,
                                    style,
                                    disabled,

                                    onClick,
                                }
                            ) =>
                            {
                                if (!title)
                                {
                                    return <Menu.Divider key={key} />
                                }

                                return (
                                    <Menu.Item
                                        key={key}
                                        disabled={disabled}
                                        onClick={onClick}
                                    >
                                        <span style={style} >{title}</span>
                                    </Menu.Item>
                                )
                            })
                        }
                    </Menu>
                }
            >
                <Button type="primary" >
                    <span style={{ paddingRight: 8 }} >จัดการ{unit}ที่เลือก ({count} {unit})</span>
                    <Icon type="down" style={{ fontSize: 10 }} />
                </Button>
            </Dropdown>
        </Container>
    )
}

export default Selection