import React from 'react'
import styled from 'styled-components'

import Slugs from './Field.Slugs'
import { Form } from 'antd';

const Container = styled.div`
    min-height: 200px;

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px 8px 0;
    margin: 16px;
`

function Component(
    {
        data = {},
        action,

        onChange,
    }
)
{
    const fieldLayout = { colon: false }

    function handleChange (name, value)
    {
        onChange ('recommend', { ...data, [name]: value })
    }

    return (
        <Container>
            <Form layout="vertical" >
                <Slugs 
                    name="items"
                    data={data.items}
                    disabled={!!action}
                    layout={fieldLayout}
                    onChange={handleChange}
                />
            </Form>
        </Container>
    )
}

export default Component