import React from 'react'
import { Row, Col } from 'antd'

import Contact from './Field.Contact'

function Component(
    {
        name = 'contacts',
        data = [],

        disabled,
        onChange,
    }
)
{
    function handleChange (index, value)
    {
        const items = [ ...data ]
        items[index] = value

        onChange (name, items)
    }

    const fieldLayout = { colon: false }

    return (
        <Row>
            <Col xs={24} >
                {
                    data.map ((item, i) =>
                    {
                        return (
                            <Contact 
                                key={`contact-${i}`}
                                layout={fieldLayout}
                                index={i}
                                data={item}
                                disabled={disabled}
                                onChange={handleChange}
                            />
                        )
                    })
                }
            </Col>
        </Row>
    )
}

export default Component