import React from 'react'

import {
    Button,
    DatePicker,
    Divider,
} from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    
`

const Content = styled.div`
    padding: 4px;
`

const Footer = styled.div`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: space-between;
    
    padding: 4px 8px;
`

function Comp (
    {
        setSelectedKeys, 
        selectedKeys, 
        confirm, 
        clearFilters,
    }
)
{
    let startAt = selectedKeys[0]
    let endAt = selectedKeys[1]

    function handleStartAtChange (dateTime)
    {
        setSelectedKeys ([ dateTime, endAt ])
    }

    function handleEndAtChange (dateTime)
    {
        setSelectedKeys ([ startAt, dateTime ])
    }

    return (
        <Container>
            <Content>
                <Content>
                    <DatePicker  
                        placeholder="วันที่เริ่มต้น"
                        format="DD MMM YYYY" 
                        showTime={false}
                        style={{ width: `100%` }}
                        autoFocus={false}
                        allowClear={false}

                        value={startAt}
                        onChange={handleStartAtChange}
                    />
                </Content>
                <Content>
                    <DatePicker  
                        placeholder="วันที่สิ้นสุด"
                        format="DD MMM YYYY" 
                        showTime={false}
                        style={{ width: `100%` }}
                        autoFocus={false}
                        allowClear={false}

                        value={endAt}
                        onChange={handleEndAtChange}
                    />
                </Content>
            </Content>
            <Divider style={{ margin: 0 }} />
            <Footer>
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={confirm}
                >
                    ยืนยัน
                </Button>
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={clearFilters}
                >
                    รีเซ็ต
                </Button>
            </Footer>
        </Container>
    )
}

export default Comp