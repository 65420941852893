import Axios from 'axios'
import firebase from 'firebase/app'

async function _save (data)
{
    const space = window.config.space
    const url = window.config.url.service.mailgun.save

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    return (await Axios.post(url, { space, ...data }, { headers })).data
}

export default 
{
    save: _save,
}