import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Row } from 'antd'

import Image from './node/Image'
import Video from './node/Video'
import Youtube from './node/Youtube'

function Comp (
    {
        action,
        items = [],
        createTemp,

        onChange,
        onUploading,
    }
)
{
    return (
        <Row type="flex" >
            {
                items.map((item, i) =>
                {
                    if (item.type === 'youtube')
                    {
                        return (
                            <Youtube
                                key={item.id}
                                index={i}
                                sortIndex={i}
                                length={items.length}
                                onChange={onChange}
                                onUploading={onUploading}
                                action={action}
                                disabled={!!action}
                                createTemp={createTemp}
                                value={item}
                            />
                        )
                    }
                    else if (item.type === 'video')
                    {
                        return (
                            <Video
                                key={item.id}
                                index={i}
                                sortIndex={i}
                                length={items.length}
                                onChange={onChange}
                                onUploading={onUploading}
                                action={action}
                                disabled={!!action}
                                createTemp={createTemp}
                                value={item}
                            />
                        )
                    }

                    return (
                        <Image
                            key={item.id}
                            index={i}
                            sortIndex={i}
                            length={items.length}
                            onChange={onChange}
                            onUploading={onUploading}
                            action={action}
                            disabled={!!action}
                            createTemp={createTemp}
                            value={item}
                        />
                    )
                })
            }
        </Row>
    )
}

export default SortableContainer(Comp)