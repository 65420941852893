import React from 'react'
import styled from 'styled-components'
import { Icon } from 'antd';

const Container = styled.div`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;

    cursor: pointer;

    @media (hover: hover)
    {
        &:hover
        {
            text-decoration: underline;
        }
    }

    ${props => props.disabled && `
        pointer-events: none;
    `}
`

const Title = styled.div`
    font-weight: bold;
    padding: 0 8px 0 0;

    ${props => props.inset && `
        padding-left: 8px;
    `}
`

function Comp (
    {
        disabled,
        title,
        inset,
        
        open = true,
        onChange,
    }
)
{
    return (
        <Container
            disabled={disabled} 
            onClick={() => onChange (!open)} 
        >
            <Title inset={inset}>{title}</Title>
            {
                !!title && 
                <Icon
                    type={'right'}
                    style={{ fontSize: 8 }}
                    rotate={!!open ? 90 : 0}
                />
            }
        </Container>
    )
}

export default Comp