import React from 'react'
import {
    Typography,
    Modal,
} from 'antd'

import styled from 'styled-components'
import Close from './Close'

const Container = styled.div`
    width: 100%;
    padding: 0;
`

const Title = styled.div`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-end;

    padding: 8px 16px;

    .label
    {
        width: 100%;
        margin-top: 8px;
    }

    ${props => !!props.disabled && `
        .button-close
        {
            pointer-events: none;
            opacity: 0.5;
        }
    `}
`

export default function (
    {
        title,
   
        onClose,

        disabled,
        changed,
    }
)
{
    return (
        <Container>
            <Title disabled={disabled} >
                <div className="label">
                    <Typography.Title level={4} >
                        {title}
                    </Typography.Title>
                </div>
                <Close 
                    onClick={() =>
                    {
                        if (!!changed)
                        {
                            Modal.confirm(
                                {
                                    title: 'ต้องการปิดหน้าต่างและยกเลิกการเปลี่ยนแปลง ?',
                                    okButtonProps: { type: 'primary' },
                                    okText: 'ใช่',
                                    cancelText: 'ไม่ใช่',
                                    onOk: () => onClose(),
                                }
                            )
                        }
                        else
                        {
                            onClose ()
                        }
                    }}
                />
            </Title>
        </Container>
    )
}