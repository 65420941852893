import Html from './html'
import Media from './media'

function traversal (src, parent, ignoreds = [])
{
    if (!src)
    {
        return []
    }

    let fields = []

    try
    {
        if (Array.isArray (src))
        {
            if (ignoreds.indexOf (parent) < 0)
            {
                fields.push ({ path: parent, type: 'array', value: src.length })
            }
        }

        if (typeof src === 'object')
        {
            const keys = Object.keys (src).sort ()

            for (let i = 0; i < keys.length; i++)
            {
                const key = keys[i]
                const val = src[key]

                if (!!val)
                {
                    const path = !!parent ? `${parent}.${key}` : key

                    if (ignoreds.indexOf (path) >= 0)
                    {
                        continue
                    }

                    if (typeof val === 'string')
                    {
                        fields.push ({ path, type: 'string', value: val })
                    }
                    else if (typeof val === 'number')
                    {
                        fields.push ({ path, type: 'number', value: val })
                    }
                    else if (typeof val === 'boolean')
                    {
                        fields.push ({ path, type: 'boolean', value: val })
                    }
                    else if (!!val.id && !!val.bucket && !!val.path)
                    {
                        fields.push ({ path, type: 'media', value: val })
                    }
                    else if (!!val.toDate && typeof val.toDate === 'function')
                    {
                        fields.push ({ path, type: 'moment', value: val.toDate ().getTime () })
                    }
                    else
                    {
                        fields = fields.concat (traversal (val, path, ignoreds))
                    }
                }
            }
        }
    }
    catch (err)
    {
        console.log (err)
    }

    return fields.sort ((a, b) => a.path.localeCompare (b.path))
}

function indexOf (fields, path)
{
    for (let i = 0; i < fields.length; i++)
    {
        if (fields[i].path === path)
        {
            return i
        }
    }

    return -1
}

function changeds (src, dst)
{
    const changeds = []

    for (let i = 0; i < src.length; i++)
    {
        const di = indexOf (dst, src[i].path)

        if (di < 0)
        {
            changeds.push (src[i].path)
            continue
        }

        if (src[i].type !== dst[di].type)
        {
            changeds.push (src[i].path)
            continue
        }

        if (src[i].type === 'media')
        {
            if (Media.hasChanged (src[i].value, dst[di].value))
            {
                changeds.push (src[i].path)
            }
        }
        else if (src[i].type === 'string')
        {
            if (Html.hasChanged (src[i].value, dst[di].value))
            {
                changeds.push (src[i].path)
            }
        }
        else if (src[i].type === 'moment')
        {
            if (src[i].value !== dst[di].value)
            {
                changeds.push (src[i].path)
            }
        }
        else if (src[i].type === 'array')
        {
            if (src[i].value !== dst[di].value)
            {
                changeds.push (src[i].path)
            }
        }
        else 
        {
            if (src[i].value !== dst[di].value)
            {
                changeds.push (src[i].path)
            }
        }
    }

    return changeds
}

function getChangeds (previous, current, ignoreds)
{
    const a = traversal (previous, null, ignoreds)
    const b = traversal (current, null, ignoreds)

    const src = a.length > b.length ? a : b
    const dst = a.length > b.length ? b : a

    const c1 = changeds (src, dst)
    const c2 = changeds (dst, src)

    return c1.concat (c2.filter (e => c1.indexOf (e) < 0)).sort ()
}

export default 
{
    getChangeds,
}