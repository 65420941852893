import React from 'react'
import { Row, Col, Form, Button, Icon, Modal } from 'antd'

import styled from 'styled-components'

import Title from '../../../post/Tab.Overview/Field.Title'

const CounterOffset = styled.small`
    color: transparent;

    display: flex;

    flex-direction: row;
    flex-grow: 1;

    align-items: center;
    justify-content: flex-end;
`

function Field(
    {
        index = 0,
        limit = 60,
        layout,
        label,

        disabled,
        
        value,
        onChange,
        onDelete,
    }
)
{
    function handleChange (name, changedValue)
    {
        onChange(index, changedValue)
    }

    function handleDelete ()
    {
        const m = Modal.confirm(
            {
                title: 'ต้องการลบ ?',
                okButtonProps: { type: 'danger' },
                okText: 'ใช่',
                cancelText: 'ไม่ใช่',
                onOk: () => 
                {
                    onDelete (index)
                    m.destroy ()
                },
            }
        )
    }

    const style = {}
    const count = value.length

    if (count >= limit)
    {
        style.color = `#f81d22`
    }
    else if (count / limit >= 0.8)
    {
        style.color = `#faad14`
    }

    return (
        <Row gutter={8} type="flex" align="middle" >
            <Col style={{ width: `calc(100% - 48px)` }} >
                <Title 
                    layout={layout}
                    limit={limit}
                    label={index === 0 ? label : ''}
                    name="item"
                    value={value}
                    disabled={disabled}
                    onChange={handleChange}
                    style={{ margin: 0 }}
                />
            </Col>
            <Col style={{ width: 40 }} >
                <Form.Item 
                    {...layout} 
                    style={{ margin: 0 }}
                    label={
                        <CounterOffset>{'-'}</CounterOffset>
                    } 
                >
                    <Button 
                        type="danger"
                        icon="delete"
                        shape="circle-outline"
                        onClick={handleDelete}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

function Component(
    {
        name = "locations",
        data = [ ],

        limit = 50,
        length = 3,

        layout,
        label = "รายการสถานที่ใกล้เคียง",

        disabled,
        onChange,
    }
)
{
    function handleChange (value)
    {
        onChange (name, value)
    }

    function handleEdit (index, value)
    {
        const arr = [ ...data ]
        arr[index] = value

        handleChange (arr)
    }

    function handleAdd ()
    {
        handleChange ([ ...data, '' ])
    }

    function handleDelete (index)
    {
        handleChange (data.filter ((e, i) => i !== index))
    }

    return (
        <Row>
            <Col xs={24}>
                {
                    data.map ((item, i) =>
                    {
                        return (
                            <Field 
                                key={`${name}-${i}`}
                                index={i}
                                layout={layout}
                                limit={limit}
                                label={label}
                                value={item}
                                disabled={disabled}
                                onDelete={handleDelete}
                                onChange={handleEdit}
                            />
                        )
                    })
                }
            </Col>
            {
                data.length < length &&
                <Col xs={18} sm={12}>
                    <Form.Item {...layout} label={data.length === 0 ? label : ''} >
                        <Button type="dashed" onClick={handleAdd} block={true} >
                            <Icon type="plus" /> เพิ่ม
                        </Button>
                    </Form.Item>
                </Col>
            }
        </Row>
    )
}

export default Component