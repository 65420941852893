import React, { useState, useEffect } from 'react'
import { message } from 'antd'

import Post from '../../utils/Space.Post'
import Taxonomy from '../../utils/Space.Taxonomy'

import Infinite from '../../components/Datatable/Infinite'
import Table from '../../components/Datatable/Table'

import Page from '../../components/Page'

function Comp (
    {
        history,
        // onCreate,
        // onEdit,
    }
)
{
    const _page = parseInt (window.sessionStorage.getItem ('posts.page') || 1, 10)

    const [ selection, setSelection ] = useState([ ])
    const [ keyword, setKeyword ] = useState('')
    const [ filters, setFilters ] = useState({ })
    const [ sorters, setSorters ] = useState({ })
    const [ actions, setActions ] = useState([ ])

    const [ processing, setProcessing ] = useState(false)
    const [ loading, setLoading ] = useState(0)
    const [ page, setPage ] = useState(_page)

    const [ posts, setPosts ] = useState([])
    const [ taxonomy, setTaxonomy ] = useState({ authors: [], categories: [] })
    
    let _posts = posts

    _posts = Post.Search.filter(_posts, keyword)

    function handleMount ()
    {
        const unsubscribes = []
        const promises = 
        [
            Post.Query.list(function ({ items, keys })
            {
                setPosts(items)
                setSelection(selection.filter (key => keys.indexOf(key) >= 0) )

                if (!processing)
                {
                    setLoading(2)
                }

            }, page, filters, sorters),

            Taxonomy.Query.post(setTaxonomy),
        ]

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleTableChange (pagination, filters, sorters)
    {
        setLoading(0)
        setFilters(filters || {})
        setSorters(sorters || {})
        setSelection([ ])
    }

    function handleSelectionChange (selectedRowKeys)
    {
        setSelection (selectedRowKeys)
        setActions (
            Post.Selection.getActions (
                { 
                    selection: selectedRowKeys, 
                    items: _posts,

                    onPublish: handlePublish,
                    onUnpublish: handleUnpublish,
                    onDelete: handleDelete,
                    onClear: handleClear,
                }
            )
        )
    }

    function setAction (status, text)
    {
        if (status === 'begin')
        {
            document.getElementById ('root').classList.add ('loading')

            setLoading (0)
            setProcessing (true)
        }
        else if (status === 'success')
        {
            setLoading (2)
            setProcessing (false)
            setSelection ([])

            message.success (text)
            
            document.getElementById ('root').classList.remove ('loading')
        }
        else if (status === 'error')
        {
            setLoading (2)
            setProcessing (false)

            message.error (text)

            document.getElementById ('root').classList.remove ('loading')
        }
    }

    async function handlePublish (items = [])
    {
        setAction ('begin')

        try
        {
            await Post.Update.publish (items.filter (item => item.info.status !== 'published').map (e => e.id))
            setAction ('success', 'แผยแพร่บทความเรียบร้อย')
        }
        catch (err)
        {
            setAction ('error', 'พบข้อผิดพลาดระหว่างการเผยแพร่ กรุณาตรวจสอบข้อมูลและเผยแพร่อีกครั้ง')
        }
    }

    async function handleUnpublish (items = [])
    {
        setAction ('begin')

        try
        {
            await Post.Update.unpublish (items.filter (item => item.info.status !== 'draft').map (e => e.id))
            setAction ('success', 'หยุดแผยแพร่บทความเรียบร้อย')
        }
        catch (err)
        {
            setAction ('error', 'พบข้อผิดพลาดระหว่างการหยุดเผยแพร่ กรุณาตรวจสอบข้อมูลและหยุดเผยแพร่อีกครั้ง')
        }
    }

    async function handleDelete (items = [])
    {
        setAction ('begin')

        try
        {
            await Post.Update.delete (items.filter (item => item.info.status === 'draft').map (e => e.id))
            setAction ('success', 'ลบบทความเรียบร้อย')
        }
        catch (err)
        {
            setAction ('error', 'พบข้อผิดพลาดระหว่างการลบ กรุณาตรวจสอบข้อมูลและลบอีกครั้ง')
        }
    }

    function handleClear ()
    {
        setSelection ([])
    }

    function handleLoadMore (page)
    {
        window.sessionStorage.setItem ('posts.page', page)

        setLoading(1)
        setPage(page)
    }

    function handleCreate ()
    {
        const id = 'create'
        history.push (`/posts/${id}`)
    }

    useEffect (handleMount, [ filters, sorters, page ])

    const ready = taxonomy.authors.length > 0 && taxonomy.categories.length > 0
    const loadingPage = loading === 0 || !!processing || !ready

    const hasMore = !!ready && loading === 2 && posts.length >= page * window.config.query.limit
    const loadingMore = !!ready && loading === 1

    return (
        <Page title="จัดการบทความ" >
            <Infinite
                more={hasMore}
                loading={loadingMore}
                onLoad={handleLoadMore}
            >
                <Table 
                    unit="บทความ"
                    columns={Post.Table.columns ({ keyword, taxonomy })}

                    emptyText="ไม่มีบทความ"

                    keyword={keyword}
                    loading={loadingPage}

                    selection={selection}
                    actions={actions}

                    items={_posts}

                    onCreate={handleCreate}

                    onTableChange={handleTableChange}
                    onSelectionChange={handleSelectionChange}
                    onKeywordChange={setKeyword}
                />
            </Infinite>
        </Page>
    )
}

export default Comp