import React from 'react'
import styled from 'styled-components'

const Title = styled.span`
    > span
    {
        background-color: rgba(0,0,0, 0.05);

        font-weight: bold;
        text-decoration: underline;
    }
`

function Component (
    {
        keyword,
        value,
    }
)
{
    if (!!keyword)
    {
        const regex = new RegExp(keyword, 'ig')
        const html = value.replace(regex, v => `<span>${v}</span>`)

        return <Title dangerouslySetInnerHTML={{ __html: html }} />
    }
    
    return (
        <Title>{value}</Title>
    )
}

export default Component