import React from 'react'

import styled from 'styled-components'
import Screen from './Screen'

const Modal = styled.div`
    width: 100%;
    
    height: calc(100% - 48px);

    margin: 24px;
    /* min-height: 280px; */
    
    max-width: 992px;
    max-height: calc(100% - 48px);

    position: relative;

    overflow-x: hidden;
    overflow-y: hidden;
    
    background: white;

    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);

    transform: scale(0);
    transition: transform 0.2s ease;

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    ${props => !!props.open && `
        transform: none;
    `}
`

const Content = styled.div`
    width: 100%;
    height: 100%;

    flex-grow: 1;

    overflow-x: hidden;
    overflow-y: auto;
`

const Header = styled.div`
    flex-shrink: 0;
`

const Footer = styled.div`
    flex-shrink: 0;
`

export default function (
    {
        header,
        footer,

        children,
        open,

        style,
        zIndex = 1,
    }
)
{
    return (
        <Screen 
            open={!!open} 
            zIndex={zIndex}
        >
            <Modal open={!!open} >
                <Header>
                    {header}
                </Header>
                <Content style={style} >
                    {children}
                </Content>
                <Footer>
                    {footer}
                </Footer>
            </Modal>
        </Screen>
    )
}