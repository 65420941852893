import React, { useState } from 'react'
import { Icon } from 'antd'

import styled from 'styled-components'

import Lightbox from '../Modal/Lightbox.Video'

const Container = styled.div`
    width: 100%;
    height: 100%;
        
    border-radius: 4px;
    border: 1px solid #d9d9d9;

    background-color: #f0f0f0;

    margin-bottom: 4px;

    > div
    {
        width: 100%;

        position: relative;
        padding-bottom: ${props => props.ratio * 100}%;
        
        > img
        {
            position: absolute;

            left: 0;
            right: 0;

            top: 0;

            max-width: 100%;

            width: 100%;
            height: 100%;

            object-fit: ${props => props.fit};
        }

        > div
        {
            position: absolute;

            left: 0;
            right: 0;

            top: 0;
            bottom: 0;

            display: flex;

            flex-direction: row;

            align-items: center;
            justify-content: center;
        }

        &.zoomable
        {
            cursor: pointer;
            position: relative;

            @media (hover: hover)
            {
                &::after
                {
                    content: '';
                    
                    position: absolute;

                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;

                    background-color: transparent;
                    transition: background-color 0.2s ease;
                }

                &:hover::after
                {
                    content: 'คลิกเพื่อดูวิดีโอ';

                    background-color: rgba(0,0,0, 0.75);
                    color: white;

                    display: flex;

                    flex-direction: row;

                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &:not(.zoomable)
        {
            pointer-events: none;
        }
    }
`

function Component(
    {
        icon = 'picture',
        data = {},

        ratio = 1.25,
        fit = 'cover',
        
        disabled,
    }
)
{
    const [ open, setOpen ] = useState (false)

    const { bucket, path, width } = data || {}
    const exists = !!bucket && !!path

    if (width < 250)
    {
        disabled = true
    }

    return (
        <Container ratio={ratio} fit={fit} >
            <div
                className={exists && !disabled ? 'zoomable' : ''}
                onClick={() => setOpen (true)}
            >
                {
                    exists ?
                    <img 
                        src={`${window.config.url.cloudinary}/${bucket}/video/upload/w_640,c_fit,q_auto:good,f_auto/${path}.jpg`}
                        alt="thumbnail"
                    />
                    :
                    <div>
                        <Icon 
                            type={icon} 
                            style={
                                {
                                    color: '#ccc',
                                    fontSize: 32,
                                }
                            }
                        />
                    </div>
                }
            </div>
            <Lightbox 
                onClose={() => setOpen(false)}
                open={exists && open} 
                video={data}
                zIndex={2}
            />
        </Container>
    )
}

export default Component