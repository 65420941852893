import React, { useState, useEffect } from 'react'

import Cloudinary from '../../utils/Space.Service.Cloudinary'

import Infinite from '../../components/Datatable/Infinite'
import Table from '../../components/Datatable/Table'

import Page from '../../components/Page'

function Comp (
    {
        history,
    }
)
{
    const _page = parseInt (window.sessionStorage.getItem ('settings.cloudinary.page') || 1, 10)

    const [ keyword, setKeyword ] = useState('')
    const [ filters, setFilters ] = useState({ })
    const [ sorters, setSorters ] = useState({ })
    // const [ actions, setActions ] = useState([ ])
    const [ syncing, setSyncing ] = useState(false)

    // const [ processing, setProcessing ] = useState(false)
    const [ loading, setLoading ] = useState(0)
    const [ page, setPage ] = useState(_page)

    const [ buckets, setBuckets ] = useState([])
    // const [ bucket, setBucket ] = useState(0)
    
    let _buckets = buckets

    _buckets = Cloudinary.Search.filter(_buckets, keyword, filters)

    function handleMount ()
    {
        const unsubscribes = []
        const promises = 
        [
            Cloudinary.Query.list(function (data)
            {
                // setBucket(data.bucket)
                setBuckets(data.buckets)
                setSyncing(data.syncing)

                setLoading(2)
                // if (!processing)
                // {
                //     setLoading(2)
                // }

            }, page, filters, sorters),
        ]

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleTableChange (pagination, filters, sorters)
    {
        setLoading(0)
        setFilters(filters || {})
        setSorters(sorters || {})
    }

    function handleLoadMore (page)
    {
        window.sessionStorage.setItem ('buckets.page', page)

        setLoading(1)
        setPage(page)
    }

    function handleCreate ()
    {
        const id = 'create'
        history.push (`/cloudinary/${id}`)
    }

    useEffect (handleMount, [ filters, sorters, page ])

    const loadingPage = loading === 0// || !!processing

    const hasMore = loading === 2 && buckets.length >= page * window.config.query.limit
    const loadingMore = loading === 1

    return (
        <Page title="จัดการ Cloudinary" >
            <Infinite
                more={hasMore}
                loading={loadingMore}
                onLoad={handleLoadMore}
            >
                <Table 
                    unit="รายการ"
                    columns={Cloudinary.Table.columns ({ keyword, syncing })}

                    emptyText="ไม่มีรายการ"

                    keyword={keyword}
                    loading={loadingPage}

                    // actions={actions}

                    items={_buckets}

                    onCreate={handleCreate}

                    onTableChange={handleTableChange}
                    onKeywordChange={setKeyword}
                />
            </Infinite>
        </Page>
    )
}

export default Comp