import styled from 'styled-components'

const Grid = styled.div`
    padding: 4px;

    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;

    ${props => props.group && `
        background: rgba(0,0,0, 0.025);
        border: 1px solid #ddd;
        border-radius: 4px;
    `}
`

export default Grid