
import { Rule } from '@cesium133/forgjs'
import Obj from '../Compare/object'

function getInvalidFields (data)
{
    const errors = []
    const { config, receivers } = data

    if (!config || !config.domain || !config.key || !config.sender)
    {
        errors.push ('config')
    }
    
    if (!Array.isArray (receivers))
    {
        errors.push ('receivers')
    }
    else
    {
        const emailRule = new Rule(
            {
                type: 'email',
                notEmpty: true,
            }
        )

        let emailCount = 0

        receivers.forEach (email =>
        {
            const isEmailValid = emailRule.test (email)

            if (!!isEmailValid)
            {
                emailCount += 1
            }
        })

        if (emailCount !== receivers.length)
        {
            errors.push ('receivers')
        }
    }

    return [ ...errors ]
}

function getChangeds (o, c)
{
    const ignoreds = [ 'updatedAt' ]

    return Obj.getChangeds (o, c, ignoreds)
}

export default
{
    getInvalidFields,
    getChangeds,
}