import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'

import { Form } from 'antd'

import Node from '../../../Tab.Content/node/parent'
import Image from '../../../../../components/Field.Image'
import Caption from '../../../../../components/Field.Caption'

const Container = styled.div`
    
    background: rgba(0,0,0, 0.01);

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 4px;

    .ant-form-item.alt
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .ant-form-item.thumbnail
    {
        margin-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}

    .col-uploader,
    .col-alt-and-align
    {
        padding: 4px;
    }
    
    .col-uploader,
    .col-align
    {
        width: 100%;
    }

    .col-align
    {
        max-width: 360px;
    }

    .col-alt
    {
        .ant-form-item
        {
            margin-bottom: 0;
        }
    }

    .col-alt-and-align
    {
        width: 100%;
        flex-grow: 1;
    }

    .col-align
    {
        display: flex;

        flex-direction: row;
        flex-wrap: wrap;

        align-items: flex-start;

        .col-align-label
        {
            padding-top: 4px;
            width: 100%;
        }
        
        .col-align-image
        {
            padding-right: 8px;
        }

        .col-align-image,
        .col-align-alt
        {
            width: 50%;
        }
    }

    @media (min-width: 576px)
    {
        flex-direction: row;

        .col-uploader
        {
            width: 200px;
        }
    }

`

function Comp (
    {
        // title = 'รูป',

        action,

        sortIndex,
        length,

        id,
        type,

        image,
        caption = '',

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const old = { id, type, image, caption }
            onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `รายการที่ ${sortIndex + 1} (รูป)`

    let title = defaultTitle

    // if (!!image && !!image.title)
    // {
    //     title = image.title
    // }

    return (
        <Node
            title={title}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <Form layout="vertical" >
                <Container disabled={!!disabled} >
                    <div className="col-uploader">
                        <Image 
                            layout={formLayout}
                            name="image"
                            value={image}
                            ratio={0.75}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </div>
                    <div className="col-alt-and-align">
                        <div className="col-alt">
                            <Caption 
                                id={id}
                                layout={formLayout}
                                rows={10}
                                minRows={6}
                                disabled={disabled}
                                value={caption}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </Container>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)