import React from 'react'
import { Row, Col, Divider } from 'antd'

import Title from '../../../post/Tab.Overview/Field.Title'
import Excerpt from '../../../post/Tab.Overview/Field.Excerpt'
import Locations from './Field.Locations'

function Component(
    {
        data = {},
        disabled,
        onChange,
    }
)
{
    function handleChange (name, value)
    {
        onChange(name, value)
    }

    const fieldLayout = { colon: false }

    const area = data.area || ''
    const nearest = data.nearest || ''
    const locations = data.locations || []

    return (
        <Row>
            <Col xs={24} >
                <Divider orientation="center" >สถานที่</Divider>

                <Title 
                    layout={fieldLayout}
                    limit={50}
                    label="ย่าน"
                    name="area"
                    value={area}
                    disabled={disabled}
                    onChange={handleChange}
                />

                <Excerpt 
                    layout={fieldLayout}
                    rows={3}
                    limit={150}
                    label="สถานที่ใกล้เคียง (ข้อความ)"
                    name="nearest"
                    value={nearest}
                    disabled={disabled}
                    onChange={handleChange}
                />
                
                <Locations 
                    layout={fieldLayout}
                    data={locations}
                    disabled={disabled}
                    onChange={handleChange}
                />
            </Col>
        </Row>
    )
}

export default Component