import React, { Fragment } from 'react'
import styled from 'styled-components'

import { Divider, Button, Modal } from 'antd'
import StatusLabel from '../cloudinaries/Column.Status'

const Container = styled.div`
    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px;

    .label
    {
        padding-bottom: 8px;

        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;

        .field
        {
            padding-right: 6px;
        }

        .grow
        {
            flex-grow: 1;
        }
    }

`

function Group (
    {
        status,
        children,
    }
)
{
    return (
        <Container>
            <Status value={status} />
            <Divider style={{ margin: `0 0 4px 0` }} />
            {children}
        </Container>
    )
}

function Status (
    {
        value
    }
)
{
    return (
        <span className="label">
            <span className="field" >สถานะ:</span>
            <StatusLabel value={value} />
        </span>
    )
}

// function Active (
//     {
//         action,
//         status,
//     }
// )
// {
//     if (status !== 'active')
//     {
//         return null
//     }

//     return (
//         <div />
//     )
// }


function Inactive (
    {
        action,
        status,

        onActivate,
        onDelete,
    }
)
{
    if (status !== 'inactive')
    {
        return null
    }

    return (
        <Fragment>
            <Button
                icon="check"
                type="primary"
                block={true}
                disabled={!!action}
                loading={action === 'activating'}
                style={{ margin: `4px 0` }}
                onClick={
                    () =>
                    {
                        const m = Modal.confirm(
                            {
                                title: `ต้องการ Activate ?`,
                                okButtonProps: { type: 'primary' },
                                okText: 'ใช่',
                                cancelText: 'ไม่ใช่',
                                onOk: () => 
                                {
                                    onActivate ()
                                    m.destroy ()
                                },
                            }
                        )
                    }
                }
            >
                Activate
            </Button>
            <Divider style={{ margin: `8px 0` }} />
            <Button
                icon="delete"
                type="danger"
                block={true}
                disabled={!!action}
                loading={action === 'deleting'}
                onClick={
                    () =>
                    {
                        const m = Modal.confirm(
                            {
                                title: `ต้องการลบ ?`,
                                okButtonProps: { type: 'danger' },
                                okText: 'ใช่',
                                cancelText: 'ไม่ใช่',
                                onOk: () => 
                                {
                                    onDelete ()
                                    m.destroy ()
                                },
                            }
                        )
                    }
                }
            >
                ลบ
            </Button>
        </Fragment>
    )
}

function Component(
    {
        action,
        status,

        onDelete,
        onActivate,
    }
)
{
    if ([ 'inactive', 'active' ].indexOf (status) < 0)
    {
        return null
    }

    return (
        <Group status={status} >
            {/* <Active 
                action={action} 
                status={status}
            /> */}
            <Inactive 
                action={action} 
                status={status}
                onDelete={onDelete}
                onActivate={onActivate}
            />
        </Group>
    )
}

export default Component