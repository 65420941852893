import React from 'react'
import { Button } from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    padding: 8px;

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        disabled,
        onAdd,
    }
)
{
    return (
        <Container disabled={!!disabled} >
            <Button 
                title="เพิ่มรายการ"
                type="primary" 
                icon="plus" 
                disabled={!!disabled}
                onClick={() => onAdd()}
            >
                เพิ่มรายการ
            </Button>
        </Container>
    )
}

export default Comp