import React from 'react'
import styled from 'styled-components'

import { Form, Divider } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'
import Image from '../../../components/Field.Image'
import Link from '../../../components/Field.Link'

import Info from './Field.Info'

const Container = styled.div`
    min-height: 200px;

    margin: 16px;
`

const Group = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;

    padding: 8px;

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
`

function Component(
    {
        data = [],
        action,

        onChange,
        onUploading,

        createTemp,
    }
)
{
    const fieldLayout = { colon: false }
    const item = !!data.items && data.items.length > 0 ? data.items[0] : {}

    const title = item.title || ''
    const image = item.image

    const map = item.map || {}
    
    function handleChange (name, value)
    {
        const items = data.items

        items[0] = { ...items[0], [name]: value }

        onChange ({ ...data, items })
    }
    
    function handleMapChange (name, value)
    {
        handleChange ('map', { ...map, [name]: value })
    }

    return (
        <Container>
            <Form layout="vertical" >
                <Group>
                    <div 
                        style={
                            { 
                                width: `100%`, 
                                // maxWidth: `480px` 
                            }
                        } 
                    >
                        <Image 
                            layout={fieldLayout}
                            label="รูป"
                            ratio={0.5625}
                            value={image}
                            disabled={!!action}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </div>

                    <div style={{ height: 16 }} />

                    <Title 
                        layout={fieldLayout}
                        value={title}
                        disabled={!!action}
                        onChange={handleChange}
                    />
                </Group>

                <Group>
                    <Info 
                        data={item.info}
                        disabled={!!action}
                        onChange={handleChange}
                    />
                </Group>

                <Group>
                    <Divider orientation="center" type="horizontal" >แผนที่</Divider>

                    <Link 
                        layout={fieldLayout}
                        label="Google Map"
                        name="google"
                        value={map.google}
                        limit={500}
                        disabled={!!action}
                        onChange={handleMapChange}
                    />

                    <Image 
                        layout={fieldLayout}
                        label="Graphic Map"
                        name="image"
                        position="top"
                        ratio={0.5625}
                        value={map.image}
                        disabled={!!action}
                        onChange={handleMapChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Group>
            </Form>
        </Container>
    )
}

export default Component