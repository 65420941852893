import styled from 'styled-components'

const Box = styled.div`
    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px;

    ${props => props.padding === 'small' && `
        padding: 4px;
    `}

    ${props => props.direction === 'horizontal' && `
        display: flex;

        flex-direction: row;
        flex-wrap: wrap;
    `}
`

export default Box;