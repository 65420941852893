import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'

import {
    Row, 
    Col, 
    Form,
    message,
} from 'antd'

import { Rule } from '@cesium133/forgjs'

import User from '../../utils/Space.User'

import Modal from '../../components/Modal'

import Header from '../post/Header'
import Loader from '../post/Loader'

import Footer from './Footer'

import Text from '../../components/Field.Text'
import Action from './Field.Action'

const Container = styled.div`
    border-top: 1px solid #e8e8e8;

    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Comp (
    {
        match,
        history,
    }
)
{
    const { id } = match.params

    const [ action, setAction ] = useState(null)
    
    const [ original, setOriginal ] = useState({ })
    const [ current,  setCurrent ] = useState({ })

    const [ password, setPassword ] = useState ('')

    const [ me, setMe ] = useState(null)

    const passwordRule = new Rule(
        {
            type: 'password',

            minLength: 6,
            maxLength: 64,
        }
    )

    const isPasswordValid = passwordRule.test (password)

    const invalid = !action && User.Document.getInvalidFields(current).length > 0
    const changed = !action && User.Document.getChangeds(original, current).length > 0

    const canSave = !!changed && !action && !invalid && (id !== 'create' || !!isPasswordValid)
    const disabled = !!action || current.status === 'active'

    function handleMount ()
    {
        setOriginal({ })
        setCurrent({ })

        setAction(id !== 'create' ? 'loading' : null)

        const unsubscribes = []
        const promises = []

        if (id !== 'create')
        {
            promises.push(
                User.Query.get (id, function ({ original, current })
                {
                    if (!!original)
                    {
                        setOriginal (original)
                    }

                    if (!!current)
                    {
                        setCurrent (current)
                        setAction (null)
                    }
                })
            )
        }

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleAuth ()
    {
        const auth = firebase.auth ()

        return auth.onAuthStateChanged (user =>
            {
                setMe (user.uid)
            })
    }

    function handleClose ()
    {
        history.replace (`/users`)
    }

    function handleChange (name, value)
    {
        setCurrent ({ ...current, [name]: value })
    }

    async function handleSave ()
    {
        setAction('saving')

        const data = 
        {
            ...current,
        }

        if (!data.createdAt)
        {
            data.createdAt = 'now'
        }

        if (!id || id === 'create')
        {
            if (!isPasswordValid)
            {
                setAction(null)

                message.error('รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร และไม่เกิน 64 ตัวอักษร')

                return
            }

            data.password = password
        }

        try
        {
            const result = await User.Update.save ({ id, data })

            if (result.error)
            {
                setAction(null)
                setPassword ('')
                // setValidations(result.error.validations || { })

                message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
            }
            else
            {
                setAction(null)

                // setValidations({ })
                // setTemps([ ])

                if (result.action === 'create')
                {
                    history.replace (`/users/${result.id}`)
                }

                message.success('บันทึกบัญชีเรียบร้อย')
            }

        }
        catch (err)
        {
            setAction(null)
            setPassword ('')

            message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
        }
    }
    
    async function handleDelete ()
    {
        setAction('deleting')

        try
        {
            await User.Update.delete ([ id ])

            setAction(null)

            message.success('ลบเรียบร้อยแล้ว')
            
            handleClose ()
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการลบ กรุณาลองใหม่อีกครั้ง')
        }
    }

    async function handleSendResetPassword ()
    {
        setAction('sending')

        const auth = firebase.auth ()

        auth
        .sendPasswordResetEmail (current.email)
        .then (() =>
        {
            setAction(null)
            message.success ('ส่งคำขอตั้งค่ารหัสผ่านใหม่ไปยังอีเมลที่ระบุเรียบร้อยแล้ว')
        })
        .catch (err =>
        {
            setAction(null)
            message.error ('อีเมลไม่ถูกต้อง')
        })
    }

    useEffect (handleMount, [ id ])
    useEffect (handleAuth, [ ])

    let ready = action !== 'loading'
    let formLayout = { colon: false }

    let displayName = current.displayName || ''
    let email = current.email || ''

    let isSelf = me === id
    let exists = id !== 'create'

    let canDelete = !!exists && !isSelf
    let canReset = !!isSelf && original.email === current.email

    return (
        <Modal
            open={true}

            header={
                <Header
                    title={id !== 'create' ? 'แก้ไขบัญชีผู้ดูแล' : 'สร้างบัญชีผู้ดูแล'}
                    disabled={!!action}
                    changed={changed}
                    onClose={
                        function ()
                        {
                            if (!!handleClose)
                            {
                                handleClose ()
                            }
                        }
                    }
                />
            }

            footer={
                <Footer
                    updatedAt={current.updatedAt}
                    exists={exists}

                    action={action}
                    canSave={canSave}
                    onSave={handleSave}
                />
            }

            style={
                {
                    backgroundColor: `#fdfdfd`,
                }
            }
        >
            {
                !!ready &&
                <Container>
                    <Form layout="vertical" >
                        <Row gutter={32} >
                            <Col 
                                xs={24}
                                sm={24} 
                                md={16}
                                lg={18}
                            >
                                <Text 
                                    layout={formLayout}
                                    label="ชื่อผู้ใช้"
                                    name="displayName"
                                    value={displayName}
                                    limit={120}
                                    disabled={!!disabled}
                                    onChange={handleChange}
                                />

                                <Text 
                                    layout={formLayout}
                                    label="อีเมล"
                                    name="email"
                                    value={email}
                                    limit={250}
                                    disabled={!!disabled}
                                    onChange={handleChange}
                                />

                                {
                                    !exists &&
                                    <Text 
                                        layout={formLayout}
                                        label="รหัสผ่าน (6 ถึง 64 ตัวอักษร)"
                                        name="password"
                                        value={password}
                                        limit={64}
                                        disabled={!!disabled}
                                        onChange={(name, value) => setPassword (value)}
                                    />
                                }
                            </Col>
                            <Col 
                                xs={24} 
                                sm={12}
                                md={8}
                                lg={6}
                            >
                                <Row gutter={8} >
                                    <Col 
                                        xs={24}
                                    >
                                        <Action 
                                            action={action}
                                            canReset={canReset}
                                            canDelete={canDelete}
                                            onDelete={handleDelete}
                                            onSendResetPassword={handleSendResetPassword}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            }
            {!ready && <Loader />}
        </Modal>
    )
}

export default Comp