import React from 'react'
import styled from 'styled-components'

import Title from '../../../post/Tab.Overview/Field.Title'

const Container = styled.div`
    /* background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px; */

    margin: 8px 0;

    .ant-col.ant-form-item-label
    {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .ant-row.ant-form-item
    {
        margin-bottom: 0;
    }
`

function Component(
    {
        layout,

        index = 0,
        data = {},

        disabled,
        onChange,
    }
)
{
    function handleChange (name, value)
    {
        onChange (index, { ...data, [name]: value })
    }

    return (
        <Container>
            <Title
                layout={layout}
                label={data.title || 'ลิงก์'}
                name="href"
                limit={255}
                disabled={disabled}
                value={data.href || ''}
                onChange={handleChange}
            />
        </Container>
    )
}

export default Component