import React from 'react'
import {
    Form,
    Input,
} from 'antd'

import Label from '../../../components/Label.Counter'
import Delay from '../../../components/DelayedInput'

function Component(
    {
        layout,
        limit = 120,
        rows = 4,

        minRows = 2,
        maxRows = 6,
        
        newLine = false,
        disabled,

        label = 'เนื้อหาแบบย่อ',
        name = 'excerpt',

        value,
        onChange,
    }
)
{
    function handleChange (changedValue)
    {
        onChange(name, changedValue)
    }

    return (
        <Delay 
            value={value}
            onChange={handleChange}
        >
            {
                ({ value, onChange }) => (
                    <Form.Item 
                        {...layout}
                        label={
                            <Label 
                                text={label} 
                                value={value.length} 
                                limit={limit} 
                            />
                        }
                    >
                        <Input.TextArea 
                            rows={rows} 
                            disabled={disabled}
                            style={{ resize: 'none' }}
                            autosize={{ minRows, maxRows }}
                            value={value}
                            onChange={function (e)
                            {
                                let _text = e.target.value

                                if (!newLine && !!_text.match(/\r?\n/g))// || _text.startsWith(' '))
                                {
                                    
                                }
                                else if (_text.startsWith (' '))
                                {
                                    onChange (_text.slice(1, limit))
                                }
                                else
                                {
                                    onChange (_text.slice(0, limit))
                                }
                            }}
                        />
                    </Form.Item>
                )
            }
        </Delay>
    )
}

export default Component