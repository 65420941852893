import React, { useState, useEffect } from 'react';
import { message } from 'antd';

import Blacklist from '../../utils/Space.Blacklist'

import Infinite from '../../components/Datatable/Infinite'
import Table from '../../components/Datatable/Table'

import Page from '../../components/Page'

function Comp (
  {
    history,
    // onCreate,
    // onEdit,
  }
)
{
  const _page = parseInt(window.sessionStorage.getItem('blacklists.page') || 1, 10)

  const [selection, setSelection] = useState([])
  const [keyword, setKeyword] = useState('')
  const [filters, setFilters] = useState({})
  const [sorters, setSorters] = useState({})
  const [actions, setActions] = useState([])

  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(0)
  const [page, setPage] = useState(_page)

  const [blacklists, setBlacklists] = useState([])

  let _blacklists = blacklists

  _blacklists = Blacklist.Search.filter(_blacklists, keyword)

  function handleMount ()
  {
    const unsubscribes = []
    const promises =
      [
        Blacklist.Query.list(function ({ items, keys })
        {
          setBlacklists(items)
          setSelection(selection.filter(key => keys.indexOf(key) >= 0))

          if (!processing)
          {
            setLoading(2)
          }

        }, page, filters, sorters),
      ]

    Promise.all(promises).then(function (us)
    {
      us.forEach(function (u)
      {
        unsubscribes.push(u)
      })
    })

    return function ()
    {
      unsubscribes.filter(u => !!u).forEach(u => u())
    }
  }

  function handleTableChange (pagination, filters, sorters)
  {
    setLoading(0)
    setFilters(filters || {})
    setSorters(sorters || {})
    setSelection([])
  }

  function handleSelectionChange (selectedRowKeys)
  {
    setSelection(selectedRowKeys)
    setActions(
      Blacklist.Selection.getActions(
        {
          selection: selectedRowKeys,
          items: _blacklists,

          onDelete: handleDelete,
          onClear: handleClear,
        }
      )
    )
  }

  function setAction (status, text)
  {
    if (status === 'begin')
    {
      document.getElementById('root').classList.add('loading')

      setLoading(0)
      setProcessing(true)
    }
    else if (status === 'success')
    {
      setLoading(2)
      setProcessing(false)
      setSelection([])

      message.success(text)

      document.getElementById('root').classList.remove('loading')
    }
    else if (status === 'error')
    {
      setLoading(2)
      setProcessing(false)

      message.error(text)

      document.getElementById('root').classList.remove('loading')
    }
  }

  async function handleDelete (items = [])
  {
    setAction('begin')

    try
    {
      await Blacklist.Update.delete(items.map(e => e.email))
      setAction('success', 'ลบอีเมลที่บล็อคไว้เรียบร้อย')
    }
    catch (err)
    {
      setAction('error', 'พบข้อผิดพลาดระหว่างการลบ กรุณาตรวจสอบข้อมูลและลบอีกครั้ง')
    }
  }

  function handleClear ()
  {
    setSelection([])
  }

  function handleLoadMore (page)
  {
    window.sessionStorage.setItem('blacklists.page', page)

    setLoading(1)
    setPage(page)
  }

  async function handleCreate ()
  {
    // const id = 'create'
    // history.push(`/blacklists/${id}`)
    const email = window.prompt('กรุณากรอกอีเมลที่ต้องการบล็อค');

    if (typeof email !== 'string' || email.length === 0)
    {
      return;
    }

    setAction('begin')

    try
    {
      await Blacklist.Update.save({ email });
      setAction('success', 'เพิ่มอีเมลที่บล็อคไว้เรียบร้อย')
    }
    catch (err)
    {
      setAction('error', 'พบข้อผิดพลาดระหว่างการเพิ่ม กรุณาตรวจสอบข้อมูลและลบอีกครั้ง')
    }
  }

  useEffect(handleMount, [filters, sorters, page])

  const loadingPage = loading === 0 || !!processing

  const hasMore = loading === 2 && blacklists.length >= page * window.config.query.limit
  const loadingMore = loading === 1

  return (
    <Page title="จัดการอีเมลที่บล็อคไว้" >
      <Infinite
        more={hasMore}
        loading={loadingMore}
        onLoad={handleLoadMore}
      >
        <Table
          unit="อีเมล"
          columns={Blacklist.Table.columns({ keyword })}

          emptyText="ไม่มีรายการอีเมลที่บล็อคไว้"

          keyword={keyword}
          loading={loadingPage}

          selection={selection}
          actions={actions}

          items={_blacklists}

          onCreate={handleCreate}

          onTableChange={handleTableChange}
          onSelectionChange={handleSelectionChange}
          onKeywordChange={setKeyword}
        />
      </Infinite>
    </Page>
  )
}

export default Comp