import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import firebase from 'firebase/app'

const Container = styled.div`
    min-height: 50px;

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;
    justify-content: center;

    padding: 8px 12px;
`

const Title = styled.span`
    font-weight: bold;
    word-break: break-word;
`

const Subtitle = styled.span`
    font-size: 0.9em;
    word-break: break-word;
`

function useUser ()
{
    const [ name, setName ] = useState ('')
    const [ email, setEmail ] = useState ('')

    function handleMount ()
    {
        const auth = firebase.auth ()

        if (!!auth.currentUser)
        {
            setName (auth.currentUser.displayName)
            setEmail (auth.currentUser.email)
        }
        
        return auth.onAuthStateChanged (async user =>
            {
                if (!!user)
                {
                    setName (user.displayName)
                    setEmail (user.email)
                }
                else
                {
                    setName ('')
                    setEmail ('')
                }
            })
    }

    useEffect (handleMount, [ ])

    return [ name, email ]
}

function Comp ()
{
    const [ name, email ] = useUser ()
    
    return (
        <Container>
            <Title>{name}</Title>
            <Subtitle>{email}</Subtitle>
        </Container>
    )
}

export default Comp