import React from 'react'
import styled from 'styled-components'
import arrayMove from 'array-move'

import { 
    Button,
    Icon,
    Empty,
} from 'antd'

import Util from '../../pages/post/Tab.Content/util'
import Grid from './Grid'

const Container = styled.div`
    overflow-x: auto;
    padding: 4px;

    @media (min-width: 768px)
    {
        padding: 8px;
    }
`

const Center = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: 100%;
    padding: 24px 0 48px 0;

    .ant-empty
    {
        padding: 24px;
    }
`

const Note = styled.small`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-start;

    padding: 8px;

    i
    {
        margin-right: 8px;
    }
`

const Footer = styled.div`
    padding: 8px;

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        action,

        name = 'items',
        data = [],

        onChange,
        onUploading,

        createTemp,
    }
)
{
    function handleChange (action, index, node)
    {
        if (typeof onChange === 'function')
        {
            if (action === 'add')
            {
                onChange(name, Util.add(data, index, node))
            }
            else if (action === 'edit')
            {
                onChange(name, Util.edit(data, index, node))
            }
            else if (action === 'remove')
            {
                onChange(name, Util.remove(data, index))
            }
        }
    }

    function handleAdd (type)
    {
        const id = new Date ().getTime ().toString (35)

        handleChange ('add', -1, { id, type })
    }

    function handleSort ({ oldIndex, newIndex })
    {
        onChange(name, arrayMove (data, oldIndex, newIndex))
    }

    if (data.length === 0)
    {
        return (
            <Center>
                <Empty description="ไม่มีข้อมูล" />
                <Button 
                    title="เพิ่มรูป"
                    type="primary" 
                    icon="plus" 
                    disabled={!!action}
                    onClick={() => handleAdd('image')}
                >
                    เพิ่มรูป
                </Button>
                {/* <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu>
                            <Menu.Item 
                                key="0"
                                onClick={() => handleAdd('image')}
                            >
                                <span>รูป</span>
                            </Menu.Item>
                            
                            <Menu.Item 
                                key="2"
                                onClick={() => handleAdd('video')}
                            >
                                <span>วิดีโอ</span>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button 
                        title="เพิ่มเนื้อหา"
                        type="primary" 
                        icon="plus" 
                        disabled={!!action}
                    >
                        เพิ่มเนื้อหา
                    </Button>
                </Dropdown> */}
            </Center>
        )
    }

    return (
        <Container id="content-editor">
            {
                data.length > 1 &&
                <Note>
                    <Icon type="info-circle" /> คลิกที่ :: ค้างไว้แล้วลากเพื่อเปลี่ยนแปลงลำดับ
                </Note>
            }

            <Grid 
                axis="xy"
                useDragHandle={true}

                action={action}
                items={data}
                
                createTemp={createTemp}

                onChange={handleChange}
                onUploading={onUploading}

                onSortEnd={handleSort}
            />
            
            <Footer disabled={!!action} >
                {/* <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu>
                            <Menu.Item 
                                key="0"
                                onClick={() => handleAdd('image')}
                            >
                                <span>รูป</span>
                            </Menu.Item>
                            
                            <Menu.Item 
                                key="2"
                                onClick={() => handleAdd('video')}
                            >
                                <span>วิดีโอ</span>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Button 
                        title="เพิ่มเนื้อหา"
                        type="primary" 
                        icon="plus" 
                        disabled={!!action}
                    >
                        เพิ่มเนื้อหา
                    </Button>
                </Dropdown> */}
                <Button 
                    title="เพิ่มรูป"
                    type="primary" 
                    icon="plus" 
                    disabled={!!action}
                    onClick={() => handleAdd('image')}
                >
                    เพิ่มรูป
                </Button>
            </Footer>
        </Container>
    )
}

export default Comp