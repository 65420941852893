import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { Form, Row, Col } from 'antd'

import Node from '../../../pages/post/Tab.Content/node/parent'
import Image from '../../Field.Image'
import Link from '../../Field.Link'

function Comp (
    {
        action,

        sortIndex,
        length,

        value = {},

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    const { id, image, link = '' } = value
    const formLayout = { colon: false }

    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            onChange ('edit', sortIndex, { ...value, [fieldName]: fieldValue })
        }
    }

    // function handleMount ()
    // {
    //     if (type !== 'link')
    //     {
    //         console.log (type)
    //         handleChange ('type', 'link')
    //     }
    // }

    // useEffect (handleMount, [ type ])

    return (
        <Node
            title="รูป"
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <Form layout="vertical" >
                {/* <Container disabled={!!disabled} > */}
                    <Row
                        style={{ padding: 8 }}
                        gutter={8}
                    >
                        <Col xs={24} >
                            <Image 
                                layout={formLayout}
                                label="รูป (1280x641)"
                                name="image"
                                value={image}
                                ratio={0.5007}
                                disabled={!!disabled}
                                onChange={handleChange}
                                onUploading={onUploading}
                                createTemp={createTemp}
                            />

                            <Link 
                                id={id}
                                layout={formLayout}
                                disabled={disabled}
                                value={link}
                                onChange={handleChange}
                                style={{ marginTop: 16, marginBottom: 0 }}
                            />
                        </Col>
                    </Row>

                    {/* <div className="col-uploader">
                        
                    </div>
                    <div className="col-alt-and-align">
                        <div className="col-alt">
                            
                        </div>
                    </div> */}
                {/* </Container> */}
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)