import { Modal } from 'antd'

import DocumentUtil from './Document'

const COLORS = 
{
    disabled: `rgba(0, 0, 0, 0.25)`,
    publish: `#52c41a`,
    unpublish: `#fa8c16`,
    delete: `#f5222d`,
    reset: `#1890ff`,
}

function getActions (
    {
        selection = [],
        items = [],

        onPublish,
        onUnpublish,
        onDelete,
        onClear,
    }
)
{
    let draftCount = 0
    let changedCount = 0
    let publishedCount = 0
    let invalidCount = 0

    const selecteds = items.filter(item => selection.indexOf(item.id) >= 0)

    for (let i = 0; i < selecteds.length; i++)
    {
        const item = selecteds[i]

        if (item.info.status === 'draft')
        {
            draftCount += 1
        }

        if (item.info.status === 'changed')
        {
            changedCount += 1
        }

        if (item.info.status === 'published')
        {
            publishedCount += 1
        }

        const invalids = DocumentUtil.getInvalidFields (item)

        if (invalids.length > 0)
        {
            invalidCount += 1
        }
    }

    const canPublish = invalidCount === 0 && publishedCount !== selecteds.length
    const canUnpublish = draftCount === 0 && changedCount + publishedCount === selecteds.length
    const canDelete = draftCount === selecteds.length

    const actions = 
    [
        {
            key: `selection-publish`,
            title: `เผยแพร่`,
            style:
            {
                color: !!canPublish ? COLORS.publish : COLORS.disabled
            },
            disabled: !canPublish,
            onClick: () => 
            {
                const m = Modal.confirm(
                    {
                        title: `ต้องการเผยแพร่บทความ ?`,
                        okButtonProps: { type: 'primary' },
                        okText: 'ใช่',
                        cancelText: 'ไม่ใช่',
                        onOk: async () => 
                        {
                            onPublish (selecteds)
                            m.destroy ()
                        },
                    }
                )
            }
        },
        {
            key: `selection-unpublish`,
            title: `หยุดเผยแพร่`,
            style:
            {
                color: !!canUnpublish ? COLORS.unpublish : COLORS.disabled
            },
            disabled: !canUnpublish,
            onClick: () => 
            {
                const m = Modal.confirm(
                    {
                        title: `ต้องการหยุดเผยแพร่บทความ ?`,
                        okButtonProps: { type: 'danger' },
                        okText: 'ใช่',
                        cancelText: 'ไม่ใช่',
                        onOk: () => 
                        {
                            onUnpublish (selecteds)
                            m.destroy ()
                        },
                    }
                )
            },
        },
        {
            key: `selection-delete`,
            title: `ลบ`,
            style:
            {
                olor: !!canDelete ? COLORS.delete : COLORS.disabled
            },
            disabled: !canDelete,
            onClick: () => 
            {
                const m = Modal.confirm(
                    {
                        title: `ต้องการลบบทความ ?`,
                        okButtonProps: { type: 'danger' },
                        okText: 'ใช่',
                        cancelText: 'ไม่ใช่',
                        onOk: () => 
                        {
                            onDelete (selecteds)
                            m.destroy ()
                        },
                    }
                )
            },
        },
        {
            key: `selection-divider`,
            title: null,
        },
        {
            key: `selection-reset`,
            title: `รีเซ็ต`,
            style:
            {
                color: COLORS.reset
            },
            disabled: false,
            onClick: () => onClear(),
        }
    ]

    return actions
}

export default
{
    COLORS,
    getActions,
}