import React from 'react'
import styled from 'styled-components'

// import Toggle from '../Sidebar/Menu.Toggle'
import { PageHeader, Icon } from 'antd'

const Container = styled.div`
    .ant-page-header
    {
        padding: 13px 16px;
        border-bottom: 1px solid #e8e8e8;
    }

    .ant-page-header-title-view-title
    {
        font-weight: 500;
    }

    /* width: 100%;
    height: 48px;

    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;

    border-bottom: 1px solid #e8e8e8;

    .flex
    {
        flex-grow: 1;
    } */
`

function Comp (
    {
        title,
        toggle,
    }
)
{
    return (
        <Container>
            <PageHeader 
                backIcon={<Icon type="menu" />}
                onBack={toggle}
                title={title}
            />
        </Container>
    )
}

export default Comp