import React from 'react'
import styled from 'styled-components'

import { Form } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'
import Excerpt from '../../post/Tab.Overview/Field.Excerpt'

import Image from '../../../components/Field.Image'

import Box from '../Box'

import Carousel from './Carousel'
import Message from './Message'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('concept', { ...data, [name]: value })
    }

    function handleCarouselChange (name, value)
    {
        const carousel = { ...data.carousel, [name]: value }
        handleChange ('carousel', carousel)
    }

    function handleMessageChange (name, value)
    {
        const message = { ...data.message, [name]: value }
        handleChange ('message', message)
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const quote = data.quote || ''

    const logo = data.logo
    const bg = data.bg

    const carousel = data.carousel || {}
    const message = data.message || {}
    
    return (
        <Container>
            <Form layout="vertical">
                <Box>
                    <Title 
                        layout={fieldLayout}
                        label="ชื่อหัวข้อ"
                        name="title"
                        value={title}
                        limit={50}
                        disabled={disabled}
                        onChange={handleChange}
                    />

                    <Image 
                        layout={fieldLayout}
                        label="รูปพื้นหลัง"
                        name="bg"
                        position="top"
                        ratio={0.25}
                        value={bg}
                        disabled={disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Box>

                <div style={{ height: 16 }} />

                <Box padding="small" direction="horizontal" >
                    <div style={{ padding: 4, flexGrow: 1, maxWidth: 200 }} >
                        <Image 
                            layout={fieldLayout}
                            label="โลโก้"
                            name="logo"
                            fit="contain"
                            ratio={1}
                            value={logo}
                            disabled={disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </div>

                    <div style={{ padding: 4, flexGrow: 1 }} >
                        <Excerpt 
                            layout={fieldLayout}
                            label="แนวคิด"
                            name="quote"
                            rows={3}
                            minRows={5}
                            maxRows={10}
                            limit={200}
                            value={quote}
                            disabled={disabled}
                            onChange={handleChange}
                        />
                    </div>

                </Box>

            </Form>

            <Carousel 
                value={carousel.items}
                title="อัลบั้มรูป"
                name="items"
                action={action}
                onChange={handleCarouselChange}
                onUploading={onUploading}
                createTemp={createTemp}
            />
            
            <Message 
                value={message.items}
                title="รายละเอียด"
                name="items"
                limit={3}
                action={action}
                onChange={handleMessageChange}
            />
        </Container>
    )
}

export default Component