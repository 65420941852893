function filter (items, keyword)
{
    if (!keyword)
        return items || []

    keyword = keyword.toLowerCase()

    return (items || []).filter (item => 
    {
        return item.email.toLowerCase().indexOf(keyword) >= 0 || item.displayName.toLowerCase().indexOf(keyword) >= 0
    })
}

export default 
{
    filter,
}