import { message } from 'antd'

function image (file)
{
    const types = [ 'image/jpeg', 'image/png', 'image/gif' ]
    const isType = types.indexOf(file.type) >= 0

    if (!isType)
    {
        message.error('กรุณาอัพโหลดรูปที่มีนามสกุล jpg, png หรือ gif เท่านั้น')
    }

    const isSmall = file.size / 1024 / 1024 < 5

    if (!isSmall)
    {
        message.error('กรุณาอัพโหลดรูปที่มีขนาดไม่เกิน 5MB')
    }

    return !!isType && !!isSmall
}

function video (file)
{
    const types = [ 'video/mp4' ]
    const isType = types.indexOf(file.type) >= 0

    if (!isType)
    {
        message.error('กรุณาอัพโหลดวีดีโอที่มีนามสกุล mp4 เท่านั้น')
    }

    const isSmall = file.size / 1024 / 1024 < 30

    if (!isSmall)
    {
        message.error('กรุณาอัพโหลดวิดีโอที่มีขนาดไม่เกิน 30MB')
    }

    return !!isType && !!isSmall
}

export default 
{
    image,
    video,
}