import React from 'react'
import styled from 'styled-components'

import { Divider } from 'antd'

const Card = styled.div`
    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    
    margin: 2px;

    .ant-form-item
    {
        margin: 4px 0;
    }
`

function Component(
    {
        title,
        children,
    }
)
{
    return (
        <Card>
            <Divider 
                type="horizontal" 
                orientation="center"
                style={{ margin: `8px 0 0 0` }}
            >
                {title}
            </Divider>
            {children}
        </Card>
    )
}

export default Component