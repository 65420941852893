import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { Form, Row, Col } from 'antd'

import Node from '../../../pages/post/Tab.Content/node/parent'
import Link from '../../Field.Link'

function Comp (
    {
        action,

        sortIndex,
        length,

        value = {},

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    const { id, link = '' } = value

    function getID (value = '')
    {
        if (value.indexOf ('youtube.com') >= 0)
        {
            if (value.indexOf ('v=') >= 0)
            {
                return value.split (`v=`)[1].split ('&')[0]
            }
            else if (value.indexOf ('embed') >= 0)
            {
                return value.split (`embed/`)[1].split ('?')[0]
            }
        }
        else if (value.indexOf ('youtu.be') >= 0)
        {
            return value.split (`youtu.be/`)[1].split ('?')[0]
        }

        return ''
    }

    function transform (value)
    {
        const id = getID (value)

        if (!!id)
        {
            return `https://www.youtube.com/embed/${id}`
        }

        return ''
    }

    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            onChange ('edit', sortIndex, { ...value, [fieldName]: transform (fieldValue) })
        }
    }

    const formLayout = { colon: false }

    return (
        <Node
            title="Youtube"
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <Form layout="vertical" >
                <Row
                    style={{ padding: 8 }}
                    gutter={8}
                >
                    <Col xs={24} >
                        <Link 
                            delay={10}
                            id={id}
                            layout={formLayout}
                            disabled={disabled}
                            value={link}
                            onChange={handleChange}
                            style={{ marginTop: 8, marginBottom: 0 }}
                        />
                    </Col>
                </Row>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)