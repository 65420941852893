import Axios from 'axios'
import firebase from 'firebase/app'

async function update (action, emails = [])
{
    const space = window.config.space
    const url = window.config.url.blacklist[action]

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    for (let i = 0; i < emails.length; i++)
    {
        const email = emails[i]
        await Axios.post(url, { space, email }, { headers })
    }
}

async function _delete (emails = [])
{
    await update ('remove', emails)
}

async function _save ({ email })
{
    const space = window.config.space
    const url = window.config.url.blacklist.add

    const token = await firebase.auth ().currentUser.getIdToken ()
    const headers = { Authorization: `Bearer ${token}` }

    return (await Axios.post(url, { space, email }, { headers })).data
}

export default 
{
    save: _save,
    delete: _delete,
}