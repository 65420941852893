import React from 'react'
import styled from 'styled-components'

import { Form, Divider } from 'antd'

import Slugs from '../../hq-home/Tab.Recommend/Field.Slugs'
import Links from '../../project/Tab.Overview/Field.Links'

import Image from '../../../components/Field.Image'

const Container = styled.div`
    margin: 8px;
`

const Box = styled.div`
    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px;
    margin: 8px;
`

function Component(
    {
        data = {},
        action,

        onChange,
        onUploading,

        createTemp,
    }
)
{
    const fieldLayout = { colon: false }

    function handleChange (name, value)
    {
        onChange ('footer', { ...data, [name]: value })
    }

    const logo = data.logo
    const contact = data.contact || []
    const projects = data.projects || []

    return (
        <Form layout="vertical" >
            <Box>
                <div style={{ width: `100%`, maxWidth: `300px` }} >
                    <Image 
                        layout={fieldLayout}
                        label="โลโก้"
                        name="logo"
                        fit="contain"
                        ratio={1}
                        value={logo}
                        disabled={!!action}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </div>
            </Box>
            <Box>
                <Divider orientation="center" type="horizontal">โครงการ</Divider>
                <Slugs 
                    name="projects"
                    data={projects}
                    disabled={!!action}
                    layout={fieldLayout}
                    onChange={handleChange}
                />
            </Box>
            <Container>
                <Links 
                    form={false}
                    limit={4}
                    title="ลิงก์"
                    name="contact"
                    value={contact}
                    onChange={handleChange}
                />
            </Container>
        </Form>
    )
}

export default Component