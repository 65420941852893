import React from 'react'
import styled from 'styled-components'

import { Row, Col, Form } from 'antd'

import Image from '../../../components/Field.Image'
import Contacts from './Field.Contacts'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        data = {},
        action,
        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('ribbon', { ...data, [name]: value })
    }

    function handleMobileChange (name, value)
    {
        handleChange ('mobile', { ...data.mobile, [name]: value })
    }

    function handleDesktopChange (name, value)
    {
        handleChange ('desktop', { ...data.desktop, [name]: value })
    }

    const fieldLayout = { colon: false }
    
    const mobile = data.mobile || { target: '#registration' }
    const desktop = data.desktop || { target: '#registration' }

    const contacts = mobile.contacts || [
        {
            "type": "phone",
            "href": "",
            "title": "Phone"
        },
        {
            "href": "",
            "title": "Line",
            "type": "line"
        }
    ]

    return (
        <Container>
            <Form 
                layout="vertical"
            >
                <Row 
                    gutter={24} 
                >
                    <Col 
                        xs={24}
                        sm={11}
                        md={8} 
                    >
                        <Image 
                            ratio={1.2}
                            value={desktop.image}
                            layout={fieldLayout}
                            label="รูป (Desktop)"
                            fit="cover"
                            createTemp={createTemp}
                            onChange={handleDesktopChange}
                            onUploading={onUploading}
                            disabled={!!action}
                        />
        
                    </Col>

                    <Col 
                        xs={24}
                        sm={13}
                        md={16} 
                    >
                        <Image 
                            ratio={0.274}
                            value={mobile.image}
                            layout={fieldLayout}
                            label="รูป (Mobile)"
                            fit="cover"
                            createTemp={createTemp}
                            onChange={handleMobileChange}
                            onUploading={onUploading}
                            disabled={!!action}
                        />

                        <div style={{ height: 16 }} />

                        <Image 
                            ratio={0.104}
                            value={mobile.bg}
                            name="bg"
                            layout={fieldLayout}
                            label="รูปพื้นหลัง (Mobile)"
                            fit="cover"
                            createTemp={createTemp}
                            onChange={handleMobileChange}
                            onUploading={onUploading}
                            disabled={!!action}
                        />

                        <Contacts 
                            data={contacts}
                            disabled={!!action}
                            onChange={handleMobileChange}
                        />
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default Component