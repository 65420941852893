function filter (items, keyword, filters)
{
    if (!!keyword)
    {
        keyword = keyword.toLowerCase()
    }

    const status = !!filters.status && filters.status.length > 0 ? filters.status[0] : null

    return (items || []).filter (item => 
    {
        if (!!keyword && item.name.toLowerCase ().indexOf(keyword) < 0)
        {
            return false
        }

        if (!!status && item.status !== status)
        {
            return false
        }
        
        return true
    })
}

export default 
{
    filter,
}