import React from 'react'
import {
    Form,
    Input,
} from 'antd'

import Label from '../Label.Counter'
import Delay from '../DelayedInput'

function Component(
    {
        delay,

        layout,
        limit = 250,
        
        disabled,
        
        label = 'ลิงก์',
        name = 'link',

        style,
        value,
        onChange,
    }
)
{
    function handleChange (changedValue)
    {
        onChange(name, changedValue)
    }

    return (
        <Delay 
            delay={delay}
            value={value}
            onChange={handleChange}
        >
            {
                ({ value, onChange }) => (
                    <Form.Item 
                        {...layout}
                        style={style}
                        label={
                            <Label 
                                text={label} 
                                value={value.length} 
                                limit={limit} 
                            />
                        }
                    >
                        <Input 
                            disabled={disabled}
                            value={value}
                            onChange={function (e)
                            {
                                onChange(e.target.value.slice(0, limit))
                            }}
                        />
                    </Form.Item>
                )
            }
        </Delay>
    )
}

export default Component