import { Rule } from '@cesium133/forgjs'

function getInvalidFields (data)
{
    const errors = []

    const emailRule = new Rule(
        {
            type: 'email',
            notEmpty: true,
        }
    )

    const isEmailValid = emailRule.test (data.email)

    if (!isEmailValid)
    {
        errors.push('email')
    }

    if (!data.displayName)
    {
        errors.push('displayName')
    }

    // if (!data.role)
    // {
    //     errors.push('role')
    // }
    
    return errors
}

function getChangeds (o, c)
{
    const changeds = []

    if (o.email !== c.email)
    {
        changeds.push ('email')
    }

    if (o.displayName !== c.displayName)
    {
        changeds.push ('displayName')
    }

    // if (o.role !== c.role)
    // {
    //     changeds.push ('role')
    // }

    return changeds
}

export default
{
    getInvalidFields,
    getChangeds,
}