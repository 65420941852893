import React from 'react'
import { Tag } from 'antd'

// const COLORS = 
// {
//     'draft': '#fb8b6b',
//     'changed': '#4194f6',
//     'published': '#69c850',
// }
const COLORS = 
{
    'draft': 'orange',
    'changed': 'blue',
    'published': 'green',
}


export default function (
    {
        value = 'draft'
    }
)
{
    // return (
    //     <span style={{ color: COLORS[value] }} >
    //         {value.toUpperCase()}
    //     </span>
    // )

    return (
        <Tag color={COLORS[value]}>{value}</Tag>
    )
}