import firebase from 'firebase/app'
import Parse from './Parse'

import { Modal } from 'antd'

async function byID (id, parse, callback)
{
    const { space } = window.config
    
    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/hq/${id}`)

    let m = null
    let init = false

    return await queryRef.onSnapshot(function (doc)
    {
        if (!!m)
        {
            m.destroy ()
            m = null
        }

        const _editor = doc.get ('_editor')

        if (!init || !_editor || _editor === window.localStorage.getItem('editorID'))
        {
            init = true

            callback ({ original: parse(doc), current: parse(doc) })
        }
        else
        {
            m = Modal.confirm(
                {
                    title: `มีการแก้ไขข้อมูลจากบัญชีผู้ใช้อื่น ต้องการอัพเดทข้อมูลล่าสุดหรือไม่ ?`,
                    okButtonProps: { type: 'primary' },
                    okText: 'ใช่',
                    cancelText: 'ไม่ใช่',
                    onOk: () => 
                    {
                        init = true

                        callback ({ original: parse(doc), current: parse(doc) })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    },
                    onCancel: () =>
                    {
                        init = true

                        callback ({ original: parse(doc) })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    }
                }
            )
        }
    })
}

async function parent (callback)
{
    return await byID ('parent', Parse.parent, callback)
}

async function about (callback)
{
    return await byID ('about', Parse.about, callback)
}

async function home (callback)
{
    return await byID ('home', Parse.home, callback)
}

async function contact (callback)
{
    return await byID ('contact', Parse.contact, callback)
}

async function posts (callback)
{
    const { space } = window.config
     
    const cfs = firebase.firestore()
    const parse = Parse.posts
        
    let queryRef = cfs.doc(`${space}/taxonomies/post`)

    let m = null
    let init = false

    return await queryRef.onSnapshot(function (doc)
    {
        if (!!m)
        {
            m.destroy ()
            m = null
        }

        const _editor = doc.get ('_editor')

        if (!init || !_editor || _editor === window.localStorage.getItem('editorID'))
        {
            init = true

            callback ({ original: parse(doc), current: parse(doc) })
        }
        else
        {
            m = Modal.confirm(
                {
                    title: `มีการแก้ไขข้อมูลจากบัญชีผู้ใช้อื่น ต้องการอัพเดทข้อมูลล่าสุดหรือไม่ ?`,
                    okButtonProps: { type: 'primary' },
                    okText: 'ใช่',
                    cancelText: 'ไม่ใช่',
                    onOk: () => 
                    {
                        init = true

                        callback ({ original: parse(doc), current: parse(doc) })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    },
                    onCancel: () =>
                    {
                        init = true

                        callback ({ original: parse(doc) })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    }
                }
            )
        }
    })
    // return await byID ('posts', Parse.posts, callback)
}

async function projects (callback)
{
    return await byID ('projects', Parse.projects, callback)
}

async function privacyPolicy (callback)
{
    return await byID ('privacy-policy', Parse.privacyPolicy, callback)
}


export default 
{
    about,
    contact,
    home,
    parent,
    posts,
    projects,
    privacyPolicy,
}