import React, { Fragment } from 'react'
import styled from 'styled-components'

import { Divider, Button, Modal } from 'antd'
import StatusLabel from '../posts/Column.Status'

const Container = styled.div`
    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px;

    .label
    {
        padding-bottom: 8px;

        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;

        .field
        {
            padding-right: 6px;
        }

        .grow
        {
            flex-grow: 1;
        }
    }
`

function Group (
    {
        status,
        children,
    }
)
{
    return (
        <Container>
            <Status value={status} />
            <Divider style={{ margin: `0 0 4px 0` }} />
            {children}
        </Container>
    )
}

function Status (
    {
        value
    }
)
{
    return (
        <span className="label">
            <span className="field" >สถานะ:</span>
            <StatusLabel value={value} />
        </span>
    )
}

function ChangedLabel ()
{
    return (
        <small>กรุณาบันทึกการเปลี่ยนแปลงก่อนเผยแพร่</small>
    )
}

function PublishButton (
    {
        disabled,

        action,
        status,

        onConfirm,
    }
)
{
    return (
        <Button
            icon="schedule"
            type="primary"
            block={true}
            disabled={!!disabled || (!!action && action !== 'publishing')}
            loading={action === 'publishing'}
            style={{ margin: `4px 0` }}
            onClick={
                () =>
                {
                    const m = Modal.confirm(
                        {
                            title: 'ต้องการเผยแพร่ ?',
                            okButtonProps: { type: 'primary' },
                            okText: 'ใช่',
                            cancelText: 'ไม่ใช่',
                            onOk: () => 
                            {
                                onConfirm ()
                                m.destroy ()
                            },
                        }
                    )
                }
            }
        >
            เผยแพร่การเปลี่ยนแปลง
        </Button>
    )
}

function SaveButton (
    {
        disabled,
        action,

        onConfirm,
    }
)
{
    return (
        <Button
            icon="edit"
            type="primary"
            block={true}
            disabled={!!disabled || (!!action && action !== 'saving')}
            loading={action === 'saving'}
            style={{ margin: `4px 0` }}
            onClick={() => onConfirm ()}
        >
            บันทึก
        </Button>
    )
}

function Component(
    {
        action,

        status,
        publishedAt,

        changed,
        canSave,

        onPublish,
        onSave,
    }
)
{
    if ([ 'changed', 'published' ].indexOf(status) < 0)
    {
        return null
    }

    return (
        <Fragment>
            <Group status={status} >
                {!!changed && <ChangedLabel />}
                <SaveButton 
                    disabled={!canSave}
                    action={action}
                    onConfirm={onSave}
                />
                <PublishButton 
                    disabled={status === 'published' || !!changed || !!canSave}
                    action={action}
                    status={status}
                    onConfirm={onPublish}
                />
            </Group>
        </Fragment>
    )
}

export default Component