import Document from './Document'
import Parse from './Parse'
import Query from './Query'
import Update from './Update'

export default
{
    Document,
    Parse,
    Query,
    Update,
}