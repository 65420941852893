import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'

import Registration from '../../utils/Space.Registration'
import Taxonomy from '../../utils/Space.Taxonomy'

import Infinite from '../../components/Datatable/Infinite'
import Table from '../../components/Datatable/Table'

import Page from '../../components/Page'

import Export from './Export'

function Comp (
    {
        history,
    }
)
{
    const _page = parseInt (window.sessionStorage.getItem ('registrations.page') || 1, 10)

    const [ keyword, setKeyword ] = useState('')
    const [ filters, setFilters ] = useState({ })
    const [ sorters, setSorters ] = useState({ })
    const [ projects, setProjects ] = useState([ ])

    const [ loading, setLoading ] = useState(0)
    const [ page, setPage ] = useState(_page)

    const [ registrations, setRegistrations ] = useState([])
    
    let _registrations = registrations

    _registrations = Registration.Search.filter(_registrations, keyword, filters)

    function handleMount ()
    {
        const unsubscribes = []
        const promises = 
        [
            Registration.Query.list(function ({ items })
            {
                setRegistrations (items)
                setLoading (2)
            }, page, filters, sorters),

            Taxonomy.Query.project(setProjects),
        ]

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleTableChange (pagination, filters, sorters)
    {
        setLoading(0)
        setFilters(filters || {})
        setSorters(sorters || {})
    }

    function handleLoadMore (page)
    {
        window.sessionStorage.setItem ('registrations.page', page)

        setLoading(1)
        setPage(page)
    }

    function handleDownload ()
    {
        // document.getElementById ('root').classList.add ('loading')
        let _project = 'none'
        let _projectName = 'ทุกโครงการ'

        let startAt = null
        let endAt = null

        if (!!filters['project'] && filters['project'].length > 0)
        {
            _project = filters['project'][0]
            _projectName = projects.filter (p => p.value === _project)[0].text
        }

        if (!!filters['createdAt'] && filters['createdAt'].length > 0)
        {
            startAt = filters['createdAt'][0]
            endAt = filters['createdAt'][1]
        }
        
        const m = Modal.confirm(
            {
                title: `ต้องการดาวโหลดข้อมูลจาก "${_projectName}" ?`,
                icon: null,
                okButtonProps: { type: 'primary' },
                okText: 'ใช่',
                cancelText: 'ไม่ใช่',
                onOk: async () => 
                {
                    m.update ({ content: `กำลังดาวโหลด . . .`, cancelButtonProps: { disabled: true } })

                    function onProgress (count)
                    {
                        m.update ({ content: `ดาวโหลดแล้ว ${count} รายการ` })
                    }

                    await Registration.Query.download (_project, _projectName, startAt, endAt, onProgress)

                    m.destroy ()
                },
            }
        )
    }

    useEffect (handleMount, [ filters, sorters, page ])

    const loadingPage = loading === 0 || projects.length === 0

    const hasMore = loading === 2 && registrations.length >= page * window.config.query.limit
    const loadingMore = loading === 1

    return (
        <Page title="รายการลงทะเบียน" >
            <Infinite
                more={hasMore}
                loading={loadingMore}
                onLoad={handleLoadMore}
            >
                <Table 
                    unit="รายการ"
                    columns={Registration.Table.columns ({ keyword, projects })}

                    emptyText="ไม่มีรายการ"

                    keyword={keyword}
                    loading={loadingPage}

                    // selection={selection}
                    // actions={actions}

                    items={_registrations}

                    onTableChange={handleTableChange}
                    // onSelectionChange={handleSelectionChange}
                    onKeywordChange={setKeyword}

                    extra={
                        <Export 
                            loadingText="กำลังดาวโหลด"
                            onClick={handleDownload}
                        />
                    }
                />
            </Infinite>
        </Page>
    )
}

export default Comp