import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { Form } from 'antd'

import styled from 'styled-components'

import Node from '../../../../post/Tab.Content/node/parent'
// import Image from '../../../../../components/Field.Image'
import Title from '../../../../post/Tab.Overview/Field.Title'
import Excerpt from '../../../../post/Tab.Overview/Field.Excerpt'

import Image from '../../../../../components/Field.Image'

const Container = styled.div`
    background: rgba(0,0,0, 0.025);
    
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 8px;

    .ant-form-item
    {
        width: 100%;
        margin-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}

    @media (min-width: 576px)
    {
        flex-direction: row;
    }
`

const Column1 = styled.div`
    flex-grow: 1;
    flex-shrink: 0;

    width: 100%;

    @media (min-width: 576px)
    {
        max-width: 200px;
    }
`

const Column2 = styled.div`
    flex-grow: 1;
    width: 100%;

    @media (min-width: 576px)
    {
        padding: 8px;
        padding-right: 0;
    }
`

function Comp (
    {
        action,
        disabled,

        sortIndex,
        length,

        id,
        
        image,
        name = '',
        home = '',
        text = '',

        onChange,
        onUploading,

        createTemp,
    }
)
{
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const old = { id, image, name, home, text }
            onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `รายการที่ ${sortIndex + 1}`

    const nodeTitle = !!name ? name : defaultTitle

    return (
        <Node
            title={nodeTitle}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
            cols={
                {
                    xs: 24,
                    // sm: 12,
                    // md: 8,
                }
            }
        >
            <Form layout="vertical" >
                <Container disabled={!!disabled} >
                    <Column1>
                        <Image 
                            layout={formLayout}
                            label={null}
                            name="image"
                            value={image}
                            ratio={1}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </Column1>

                    <Column2>
                        <Title 
                            layout={formLayout}
                            label="ชื่อลูกบ้าน"
                            name="name"
                            value={name}
                            limit={100}
                            disabled={!!disabled}
                            onChange={handleChange}
                        />

                        <Title 
                            layout={formLayout}
                            label="บ้าน"
                            name="home"
                            value={home}
                            limit={100}
                            disabled={!!disabled}
                            onChange={handleChange}
                        />

                        <Excerpt 
                            layout={formLayout}
                            label="ข้อความ"
                            name="text"
                            value={text}
                            limit={500}
                            rows={3}
                            minRows={3}
                            maxRows={6}
                            disabled={!!disabled}
                            onChange={handleChange}
                        />
                    </Column2>
                </Container>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)