import React from 'react'
import styled from 'styled-components'

import { Form } from 'antd'

import Title from '../../../../post/Tab.Overview/Field.Title'
import Excerpt from '../../../../post/Tab.Overview/Field.Excerpt'
import Image from '../../../../../components/Field.Image'

const Container = styled.div`
    background: rgba(0,0,0, 0.025);
    min-height: 200px;
    padding: 16px 8px;
`

function Component(
    {
        data = {},

        action,

        onChange,
        onUploading,
 
        createTemp,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('meta', { ...data, [name]: value })
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const description = data.description || ''

    const image = data.image

    return (
        <Container>
            <Form 
                layout="vertical"
            >
                <Title 
                    layout={fieldLayout}
                    value={title}
                    disabled={disabled}
                    onChange={handleChange}
                />
                
                <Excerpt 
                    layout={fieldLayout}
                    label="คำอธิบาย"
                    name="description"
                    value={description}
                    disabled={disabled}
                    onChange={handleChange}
                />
                
                <Image 
                    layout={fieldLayout}
                    label="รูปประกอบ (1200x628 px)"
                    ratio={0.523}
                    value={image}
                    disabled={disabled}
                    onChange={handleChange}
                    onUploading={onUploading}
                    createTemp={createTemp}
                />
            </Form>
        </Container>
    )
}

export default Component