import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'

import { Form } from 'antd'

import Node from '../../../Tab.Content/node/parent'
import Image from '../../../../../components/Field.Image'
import Link from '../../../../../components/Field.Link'

const Container = styled.div`
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 4px;

    .ant-form-item.alt
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .ant-form-item.thumbnail
    {
        margin-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}

    .col-uploader,
    .col-alt-and-align
    {
        padding: 4px;
    }
    
    .col-uploader,
    .col-align
    {
        width: 100%;
    }

    .col-align
    {
        max-width: 360px;
    }

    .col-alt
    {
        .ant-form-item
        {
            margin-bottom: 0;
        }
    }

    .col-alt-and-align
    {
        width: 100%;
        flex-grow: 1;
    }

    .col-align
    {
        display: flex;

        flex-direction: row;
        flex-wrap: wrap;

        align-items: flex-start;

        .col-align-label
        {
            padding-top: 4px;
            width: 100%;
        }
        
        .col-align-image
        {
            padding-right: 8px;
        }

        .col-align-image,
        .col-align-alt
        {
            width: 50%;
        }
    }

    @media (min-width: 576px)
    {
        flex-direction: row;

        .col-uploader
        {
            width: 200px;
        }
    }

`

function Comp (
    {
        // title = 'ลิงก์',

        action,

        sortIndex,
        length,

        id,
        type,

        image,
        link = '',

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    function getID (value = '')
    {
        if (value.indexOf ('youtube.com') >= 0)
        {
            if (value.indexOf ('v=') >= 0)
            {
                return value.split (`v=`)[1].split ('&')[0]
            }
            else if (value.indexOf ('embed') >= 0)
            {
                return value.split (`embed/`)[1].split ('?')[0]
            }
        }
        else if (value.indexOf ('youtu.be') >= 0)
        {
            return value.split (`youtu.be/`)[1].split ('?')[0]
        }

        return ''
    }

    function transform (value)
    {
        const id = getID (value)

        if (!!id)
        {
            return `https://www.youtube.com/embed/${id}`
        }

        return ''
    }

    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            if (fieldName === 'link')
            {
                const old = { id, type, image }
                onChange ('edit', sortIndex, { ...old, [fieldName]: transform (fieldValue) })
            }
            else
            {
                const old = { id, type, link }
                onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
            }
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `รายการที่ ${sortIndex + 1} (Youtube)`

    let title = defaultTitle

    return (
        <Node
            title={title}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <Form layout="vertical" >
                <Container disabled={!!disabled} >
                    <div className="col-uploader">
                        <Image 
                            layout={formLayout}
                            name="image"
                            value={image}
                            ratio={0.75}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </div>
                    <Link 
                        delay={10}
                        id={id}
                        layout={formLayout}
                        disabled={disabled}
                        value={link}
                        onChange={handleChange}
                        style={{
                            width: `100%`,
                            padding: 4,
                            margin: 0,
                        }}
                    />
                </Container>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)