import React from 'react'
import ReactDOM from 'react-dom'

import 'moment/locale/th'
import './index.scss'

import App from './_app'
import * as serviceWorker from './serviceWorker'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import config from './config'

window.config = config

if (!firebase.apps.length) 
{
    firebase.initializeApp (
        {
            apiKey: "AIzaSyBCctqpJQiTJYFJGAsPiRJ8TzCf0B3laKQ",
            authDomain: "suanluang-services.firebaseapp.com",
            databaseURL: "https://suanluang-services.firebaseio.com",
            projectId: "suanluang-services",
            storageBucket: "suanluang-services.appspot.com",
            messagingSenderId: "461128474432",
            appId: "1:461128474432:web:1726531bfd312851"
        }
    )
    
    firebase
    .firestore ()
    .settings ({ cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED })
    
    firebase
    .firestore ()
    .enablePersistence ({ synchronizeTabs: true })
    .catch (function ()
    {
        console.log ('disablePersistence')
    })
}

if (!window.localStorage.getItem ('editorID'))
{
    const _editorID = firebase.firestore ().collection ('spaces').doc ().id
    window.localStorage.setItem ('editorID', _editorID)
}

ReactDOM.render(<App />, document.getElementById ('root'))
serviceWorker.unregister ()
