import React from 'react'
import ReactQuill from 'react-quill'

import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'
import Constants from '../constants'

import Delay from '../../../../components/DelayedInput'
import Node from './parent'

const Container = styled.div`
    .quill
    {
        background-color: white;

        .ql-toolbar.ql-snow
        {
            border: none;
            border-bottom: 1px solid #e8e8e8;
        }

        .ql-container.ql-snow
        {
            min-height: 120px;
            border: none;

            .ql-tooltip
            {
                z-index: 999999;
            }

            strong
            {
                font-weight: bold;
            }
        }

        ${props => !!props.disabled && `
            pointer-events: none;
            opacity: 0.6;
        `}
    }
`

function Comp (
    {
        action,
        
        sortIndex,
        length,

        id,
        type,
        value = '',
        options = {},
        onChange,
    }
)
{
    function handleChange (changedValue)
    {
        onChange('edit', sortIndex, { id, type, options, value: changedValue })
    }

    const onNodeChange = onChange

    return (
        <Delay
            value={value}
            onChange={handleChange}
        >
            {
                ({ value, onChange }) => (
                    <Node
                        title="ข้อความ"
                        action={action}

                        index={sortIndex}
                        length={length}

                        id={id}
                        type={type}
                        value={value}
                        options={options}
                        
                        onChange={onNodeChange}
                    >
                        <Container disabled={!!action} >
                            <ReactQuill 
                                id={id}
                                value={value}
                                onChange={onChange}
                                bounds={`#content-editor`}
                                placeholder="แก้ไขข้อความที่นี่"
                                preserveWhitespace={false}
                                modules={
                                    {
                                        toolbar:
                                        [
                                            [{ 'size': ['small', false, 'large' ] }],
                                            [{ 'header': 1 }, { 'header': 2 }],
                                            ['bold', 'italic', 'underline', 'strike'],
                                            ['link'],
                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                            [{ 'script': 'sub'}, { 'script': 'super' }],
                                            [{ 'color': Constants.colors }, ],
                                            [{ 'align': [] }],
                                        ]
                                    }
                                }
                            />
                        </Container>
                    </Node>
                )
            }
        </Delay>
    )
}

export default SortableElement(Comp)