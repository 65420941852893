import React, { useState }  from 'react'
import styled from 'styled-components'

import {
    Form,
    Upload,
    Modal,
} from 'antd'

import Media from '../../utils/Space.Media'
import Preview from './Preview'

import ButtonUpload from '../Field.Image/Button.Upload'
import ButtonDelete from '../Field.Image/Button.Delete'

const Uploader = styled.div`
    .ant-upload
    {
        width: 100%;
    }

    .actions
    {
        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;

        align-items: center;

        padding: 4px 0;

        .upload
        {
            flex-grow: 1;
        }

        .delete
        {
            padding-left: 8px;
            width: 80px;
        }
    }
`

function Comp (
    {
        layout,
        label = 'ไฟล์วิดีโอ',

        ratio = 1,

        name = 'video',
        value,

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    const [ uploading, setUploading ] = useState(false)
 
    function handleUpload (info)
    {
        setUploading (info.file.status === 'uploading')
        onUploading (info.file.status === 'uploading')
        
        if (info.file.status === 'done')
        {
            handleTemp ()
            handleChange (info.file.response[0])
        }
    }

    function handleChange (newValue)
    {
        if (typeof onChange === 'function')
        {
            onChange (name, newValue)
        }
    }

    function handleDelete ()
    {
        handleTemp ()
        handleChange (undefined)
    }

    function handleTemp ()
    {
        if (!!value)
        {
            createTemp (value)
        }
    }

    async function handleRequest ({ file, onSuccess })
    {
        const result = await Media.Upload.video (file)
        onSuccess ([ result ])
    }

    return (
        <Form.Item
            {...layout}
            label={label}
            style={{ margin: 0, padding: 0 }}
        >
            <Uploader>
                <Preview 
                    data={value} 
                    ratio={ratio} 
                />
                <div className="actions">
                    <div className="upload">
                        <Upload
                            listType="picture"
                            showUploadList={false}
                            // action={window.config.url.upload.video}
                            action={null}
                            customRequest={handleRequest}
                            beforeUpload={Media.Validate.video}
                            onChange={handleUpload}
                            disabled={!!disabled || !!uploading}
                        >
                            <ButtonUpload
                                disabled={!!disabled}
                                loading={!!uploading} 
                            />
                        </Upload>
                    </div>
                    <div className="delete">
                        <ButtonDelete
                            disabled={!!disabled || !!uploading || !value} 
                            onClick={
                                () =>
                                {
                                    const m = Modal.confirm(
                                        {
                                            title: 'ต้องการลบวิดีโอนี้ ?',
                                            okButtonProps: { type: 'danger' },
                                            okText: 'ใช่',
                                            cancelText: 'ไม่ใช่',
                                            onOk: () => 
                                            {
                                                handleDelete ()
                                                m.destroy ()
                                            },
                                        }
                                    )
                                }
                            }
                        />
                    </div>
                </div>
            </Uploader>
        </Form.Item>
    )
}

export default Comp