import React from 'react'
import { Layout } from 'antd'

import User from './User'
import Menu from './Menu'

const {
    Sider,
} = Layout

function Comp (
    {
        theme = 'light',

        collapsed = false,
        setCollapse,
    }
)
{
    // const [ isMobile, setIsMobile ] = useState(true)
    const isMobile = true

    return (
        <Sider
            // width={220}
            width={180}
            theme={theme}
            breakpoint="lg"
            collapsible={true}
            collapsedWidth={!!isMobile ? 0 : 80}
            collapsed={collapsed}
            onCollapse={e => setCollapse(e)}
            // onBreakpoint={e => setIsMobile(e)}
            trigger={null}

            style={{ background: `#fafafa` }}
            // style={{ paddingTop: 2 }}
        >
            {/* <div style={{ height: 46 }} /> */}
            <User />
            <Menu theme={theme} />
        </Sider>
    )
}

export default Comp