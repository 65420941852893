import React from 'react'
import styled from 'styled-components'

import { Form, Divider } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'

import Image from '../../../components/Field.Image'
import Link from '../../../components/Field.Link'

import Box from '../Box'
import Nearest from './Nearest'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

const Group = styled.div`
    margin-top: 16px;
    padding: 8px;

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('map', { ...data, [name]: value })
    }

    function handleNearestChange (name, value)
    {
        const nearest = { ...data.nearest, [name]: value }
        handleChange ('nearest', nearest)
    }
    
    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const bg = data.bg

    const image = data.image
    const google = data.google
    const nearest = data.nearest || {}

    return (
        <Container>
            <Form layout="vertical">
                <Box>
                    <Title 
                        layout={fieldLayout}
                        label="ชื่อหัวข้อ"
                        name="title"
                        value={title}
                        limit={50}
                        disabled={disabled}
                        onChange={handleChange}
                    />

                    <Image 
                        layout={fieldLayout}
                        label="รูปพื้นหลัง"
                        name="bg"
                        position="top"
                        ratio={0.25}
                        value={bg}
                        disabled={disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Box>

                <Group>
                    <Divider orientation="center" type="horizontal" >แผนที่</Divider>

                    <Link 
                        layout={fieldLayout}
                        label="Google Map"
                        name="google"
                        value={google}
                        limit={500}
                        disabled={disabled}
                        onChange={handleChange}
                    />

                    <Image 
                        layout={fieldLayout}
                        label="Graphic Map"
                        name="image"
                        position="top"
                        ratio={0.5625}
                        value={image}
                        disabled={disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Group>
            </Form>

            <Nearest 
                value={nearest.items}
                title="สถานที่ใกล้เคียง"
                name="items"
                action={action}
                onChange={handleNearestChange}
                onUploading={onUploading}
                createTemp={createTemp}
            />
        </Container>
    )
}

export default Component