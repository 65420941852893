import React from 'react'
import styled from 'styled-components'

import Carousel from './Carousel'
import Message from './Message'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('concept', { ...data, [name]: value })
    }

    function handleCarouselChange (name, value)
    {
        const carousel = { ...data.carousel, [name]: value }
        handleChange ('carousel', carousel)
    }

    function handleMessageChange (name, value)
    {
        const message = { ...data.message, [name]: value }
        handleChange ('message', message)
    }

    const carousel = data.carousel || {}
    const message = data.message || {}
    
    return (
        <Container>
            <Carousel 
                value={carousel.items}
                title="อัลบั้มรูป"
                name="items"
                action={action}
                onChange={handleCarouselChange}
                onUploading={onUploading}
                createTemp={createTemp}
            />
            
            <Message 
                value={message.items}
                title="รายละเอียด"
                name="items"
                limit={3}
                action={action}
                onChange={handleMessageChange}
            />
        </Container>
    )
}

export default Component