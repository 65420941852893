import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="หน้าแบบบ้าน" >
            <Group>
                <Item>
                    <Label>Breadcrumb</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="model.page.heading.bgColor"
                                alpha={false}
                                value={select('model.page.heading.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ข้อความ"
                                name="model.page.heading.color"
                                alpha={false}
                                value={select('model.page.heading.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>

                    <div style={{ height: 16 }} />
                    
                    <Label>3D</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="model.page.walkthrough.bgColor"
                                alpha={false}
                                value={select('model.page.walkthrough.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="เส้นขอบ"
                                name="model.page.walkthrough.borderColor"
                                alpha={false}
                                value={select('model.page.walkthrough.borderColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ตัวอักษร"
                                name="model.page.walkthrough.title.color"
                                alpha={false}
                                value={select('model.page.walkthrough.title.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>

                <Item>
                    <Label>รายละเอียด</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="model.page.title.bgColor"
                                alpha={false}
                                value={select('model.page.title.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ข้อความ"
                                name="model.page.title.color"
                                alpha={false}
                                value={select('model.page.title.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>

                    <div style={{ height: 16 }} />
                    
                    <Label>แกลเลอรี่</Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="model.page.gallery.bgColor"
                                alpha={false}
                                value={select('model.page.gallery.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="ตัวอักษร"
                                name="model.page.gallery.title.color"
                                alpha={false}
                                value={select('model.page.gallery.title.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>

                <Item>
                    <Label>ผังบ้าน</Label>
                    <Group group>
                        <Item>
                            <Label></Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="model.page.plan.bgColor"
                                        alpha={false}
                                        value={select('model.page.plan.bgColor')}
                                        onChange={onChange}
                                    />
                                    
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ชื่อหัวข้อ"
                                        name="model.page.plan.title.color"
                                        alpha={false}
                                        value={select('model.page.plan.title.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>

                        <Item>
                            <Label>ชั้น</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="เส้นขอบ"
                                        name="model.page.plan.image.borderColor"
                                        alpha={false}
                                        value={select('model.page.plan.image.borderColor')}
                                        onChange={onChange}
                                    />
                                    
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="model.page.plan.label.bgColor"
                                        alpha={false}
                                        value={select('model.page.plan.label.bgColor')}
                                        onChange={onChange}
                                    />
                                    
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ชื่อ"
                                        name="model.page.plan.label.color"
                                        alpha={false}
                                        value={select('model.page.plan.label.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>
                    </Group>
                </Item>


            </Group>
        </Card>
    )
}

export default Component