import React from 'react'
import styled from 'styled-components'

import { Row, Col, Form, Divider } from 'antd'

import Project from '../../../utils/Space.Project'

import Title from '../../post/Tab.Overview/Field.Title'
import Slug from '../../post/Tab.Overview/Field.Slug'
import Excerpt from '../../post/Tab.Overview/Field.Excerpt'
import Image from '../../../components/Field.Image'
// import PublishedAt from '../../post/Tab.Overview/Field.PublishedAt'
import Publish from '../../post/Tab.Overview/Field.Publish'

import Area from './Field.Area'
import Contact from './Field.Contact'
import Features from './Field.Features'

import Links from './Field.Links'
// import ColorInput from '../../../components/Input/Color'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        id,
        data = {},
        info = {},

        header = {},
        footer = {},

        action,
        changed,
        invalid,
        validations,

        onChange,
        onDelete,
        onPublish,
        onUnpublish,
        onUploading,
 
        createTemp,
        setValidations,
    }
)
{
    function handleOverviewChange (name, value)
    {
        onChange ('overview', { ...data, [name]: value })
    }

    function handleHeaderChange (name, value)
    {
        onChange ('header', { ...header, [name]: value })
    }
    
    function handleFooterChange (name, value)
    {
        onChange ('footer', { ...footer, [name]: value })
    }

    const fieldLayout = { colon: false }

    const title = data.title || ''
    const titleAlt = data.titleAlt || ''
    const breadcrumb = data.breadcrumb || ''
    const excerpt = data.excerpt || ''
    const price = data.price || ''

    const image = data.image
    const publishedAt = data.publishedAt
    
    const slug = data.slug || ''
    const status = info.status || 'none'

    return (
        <Container>
            <Form 
                layout="vertical"
            >
                <Row 
                    gutter={32} 
                >
                    <Col 
                        xs={24}
                        sm={24} 
                        md={16}
                        lg={18}
                    >
                        <Title 
                            layout={fieldLayout}
                            label="ชื่อโครงการ"
                            name="title"
                            value={title}
                            disabled={!!action}
                            onChange={handleOverviewChange}
                        />

                        <Title 
                            layout={fieldLayout}
                            label="ชื่อโครงการ (ภาษาไทย)"
                            name="titleAlt"
                            value={titleAlt}
                            disabled={!!action}
                            onChange={handleOverviewChange}
                        />

                        <Title 
                            layout={fieldLayout}
                            label="ชื่อโครงการ (สำหรับ Breadcrumb)"
                            name="breadcrumb"
                            value={breadcrumb}
                            disabled={!!action}
                            onChange={handleOverviewChange}
                        />
                        
                        <Slug 
                            id={id}

                            layout={fieldLayout}
                            
                            value={slug}
                            disabled={!!action}
                            onChange={handleOverviewChange}

                            validate={Project.Validate.slug}
                            validations={validations}
                            setValidations={setValidations}
                            
                            collection="projects"
                            errorText={'มีการใช้ชื่อลิงก์นี้ในโครงการอื่นแล้ว'}
                        />

                        <Excerpt 
                            layout={fieldLayout}
                            label="คำอธิบายแบบย่อ"
                            rows={3}
                            limit={150}
                            value={excerpt}
                            disabled={!!action}
                            onChange={handleOverviewChange}
                        />
                            
                        <Title 
                            layout={fieldLayout}
                            limit={15}
                            label="ราคา"
                            name="price"
                            value={price}
                            disabled={!!action}
                            onChange={handleOverviewChange}
                        />

                        <Image 
                            layout={fieldLayout}
                            label="รูปโครงการ (อัตราส่วน 16:9)"
                            ratio={0.5625}
                            value={image}
                            disabled={!!action}
                            onChange={handleOverviewChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />

                        <Image 
                            layout={fieldLayout}
                            label="รูปพื้นหลังเมนู"
                            name="bg"
                            fit="cover"
                            ratio={0.104}
                            value={header.bg}
                            disabled={!!action}
                            onChange={handleHeaderChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />

                        <Divider orientation="center">โลโก้</Divider>

                        <Row gutter={16} >
                            <Col xs={24} sm={12} lg={11}>
                                <Image 
                                    layout={fieldLayout}
                                    label="โลโก้ (Header)"
                                    name="logo"
                                    fit="contain"
                                    ratio={1}
                                    value={header.logo}
                                    disabled={!!action}
                                    onChange={handleHeaderChange}
                                    onUploading={onUploading}
                                    createTemp={createTemp}
                                />
                            </Col>

                            <Col xs={24} sm={12} lg={11} >
                                <Image 
                                    layout={fieldLayout}
                                    label="โลโก้ (Footer)"
                                    name="logo"
                                    fit="contain"
                                    ratio={1}
                                    value={footer.logo}
                                    disabled={!!action}
                                    onChange={handleFooterChange}
                                    onUploading={onUploading}
                                    createTemp={createTemp}
                                />
                            </Col>
                        </Row>

                        <div style={{ height: 16 }} />

                        <Row gutter={16} >
                            <Col xs={24} sm={12} lg={11} >
                                <Image 
                                    layout={fieldLayout}
                                    label="โลโก้ (Content)"
                                    name="logo"
                                    fit="contain"
                                    ratio={1}
                                    value={data.logo}
                                    disabled={!!action}
                                    onChange={handleOverviewChange}
                                    onUploading={onUploading}
                                    createTemp={createTemp}
                                />
                            </Col>
                        </Row>

                        <Features 
                            data={data.features}
                            action={action}
                            onChange={handleOverviewChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />

                        <Area 
                            data={data}
                            onChange={handleOverviewChange}
                            disabled={!!action}
                        />

                        <Contact 
                            data={data.contact}
                            onChange={handleOverviewChange}
                            disabled={!!action}
                        />

                        <Links 
                            form={false}
                            limit={4}
                            title="ลิงก์อื่นๆ"
                            name="contact"
                            value={header.contact}
                            onChange={handleHeaderChange}
                        />

                    </Col>

                    <Col 
                        xs={24} 
                        sm={12}
                        md={8}
                        lg={6}
                    >
                        <Row gutter={8} > 
                            <Col 
                                xs={24}
                            >
                                <Publish 
                                    label="โครงการ"
                                    changed={changed}
                                    invalid={invalid}
                                    action={action}
                                    status={status}
                                    publishedAt={publishedAt}
                                    onDelete={onDelete}
                                    onPublish={onPublish}
                                    onUnpublish={onUnpublish}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default Component