import React, { useState } from 'react'
import ReactSVG from 'react-svg'
import Input from './Input'

function InputPassword (
    {
        defaultValue = '',
        onChange,

        error,
        disabled,
    }
)
{
    const [ visible, setVisible ] = useState (false)

    return (
        <Input
            type={!!visible ? `text` : `password`}

            placeholder="รหัสผ่าน"

            minLength={6}
            maxLength={32}

            defaultValue={defaultValue}
            onChange={onChange}
            
            error={error}
            disabled={disabled}

            prefix={
                <div className="icon" >
                    <ReactSVG src="/static/icons/twotone-lock.svg" />
                </div>
            }

            suffix={
                <div className="suffix" >
                    <button
                        type="button"
                        onClick={() => setVisible (!visible)}
                    >
                        <div className="icon" >
                            <ReactSVG 
                                src={
                                    !!visible ?
                                    "/static/icons/twotone-visibility-off.svg"
                                    :
                                    "/static/icons/twotone-visibility.svg"
                                } 
                            />
                        </div>
                    </button>
                </div>
            }
        />
    )
}

export default InputPassword