
function parseTagName (element)
{
    let reg = /(<)+[A-z0-9]+(\s|>)|(<\/)+[A-z0-9]+(\s|>)/gmi.exec (element)

    if (!!reg && reg.length > 0)
    {
        return reg[0].split (reg[1])[1].split (reg[2])[0]
    }

    return ''
}

function parseAttributes (element)
{
    element = element.replace (/<\w+\W/g, '')
    element = element.replace (/\s>/g, ' ')

    if (element.length > 0)
    {
        const attrs = []

        let key = 'name'

        let name = ''
        let value = ''
        let quote = 0

        for (let i = 0; i < element.length; i++)
        {
            const c = element[i]

            if (key === 'name')
            {
                if (c === '=')
                {
                    key = 'value'
                }
                else if (c === ' ' || c === '>')
                {
                    attrs.push ({ name, value: null })

                    name = ''
                    value = ''
                    quote = 0
                }
                else
                {
                    name += c
                }
            }
            else if (key === 'value')
            {
                if (quote >= 2 && quote % 2 === 0 && (c === ' ' || c === '>'))
                {
                    if (name === 'class')
                    {
                        value = value.split (' ').sort ().join (' ')
                    }
                    else if (name === 'style')
                    {
                        value = value
                                .split (';')
                                .map (item => item.split(':').map (e => e.trim ()).join(':'))
                                .sort ((a, b) => a.split(':')[0].trim ().localeCompare (b.split(':')[0].trim ()))
                                .join ('; ')
                    }

                    attrs.push ({ name, value })

                    name = ''
                    value = ''
                    quote = 0

                    key = 'name'
                }
                else if (c === '"')
                {
                    quote += 1
                }
                else
                {
                    value += c
                }
            }
        }

        return attrs.sort ((a, b) => a.name.localeCompare (b.name))
    }

    return []
}

function parse (text = '')
{
    const reg = /<[^>/]*>/gmi
    const elements = []

    text = text.replace (reg, function (val, i)
    {
        const tag = parseTagName (val)
        const attrs = parseAttributes (val)

        elements.push ({ tag, attrs })

        return `<${tag}>`
    })
    
    return { text, elements }
}

function hasChanged (a, b)
{
    const p1 = parse (a)
    const p2 = parse (b)

    if (p1.text !== p2.text)
    {
        return true
    }

    const e1 = p1.elements
    const e2 = p2.elements

    if (e1.length !== e2.length)
    {
        return true
    }

    for (let i = 0; i < e1.length; i++)
    {
        const a = e1[i]
        const b = e2[i]

        if (a.tag !== b.tag)
        {
            return true
        }

        const aa = a.attrs
        const ba = b.attrs

        if (aa.length !== ba.length)
        {
            return true
        }

        for (let j = 0; j < aa.length; j++)
        {
            if (aa[j].name !== ba[j].name)
            {
                return true
            }

            if (aa[j].value !== ba[j].value)
            {
                return true
            }
        }
    }

    return false
}

export default
{
    hasChanged,
}