import React from 'react'
import { Button } from 'antd'

function Component(
    {
        disabled,
        onClick,
    }
)
{
    return (
        <Button 
            disabled={disabled}
            onClick={onClick}
            type="danger"
            icon="delete"  
            block={true}
        >
            ลบ
        </Button>
    )
}

export default Component