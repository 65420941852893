import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'

import {
    Form
} from 'antd'

import Node from '../../post/Tab.Content/node/parent'

import Image from '../../../components/Field.Image'
// import Caption from '../../../components/Field.Caption'
import Style from './Field.Style'

// const Container = styled.div`
//     display: flex;

//     flex-direction: column;
//     flex-wrap: nowrap;

//     align-items: flex-start;

//     padding: 4px;

//     .ant-form-item.alt
//     {
//         margin-bottom: 0;
//         padding-bottom: 0;
//     }

//     .ant-form-item.thumbnail
//     {
//         margin-bottom: 0;
//     }

//     ${props => !!props.disabled && `
//         pointer-events: none;
//         opacity: 0.6;
//     `}

//     .col-uploader,
//     .col-alt-and-align
//     {
//         padding: 4px;
//     }
    
//     .col-uploader,
//     .col-align
//     {
//         width: 100%;
//     }

//     .col-align
//     {
//         max-width: 360px;
//     }

//     .col-alt
//     {
//         .ant-form-item
//         {
//             margin-bottom: 0;
//         }
//     }
    
//     .col-alt-and-align
//     {
//         width: 100%;
//         flex-grow: 1;
//     }

//     .col-align
//     {
//         display: flex;

//         flex-direction: row;
//         flex-wrap: wrap;

//         align-items: flex-start;

//         .col-align-label
//         {
//             padding-top: 4px;
//             width: 100%;
//         }
        
//         .col-align-image
//         {
//             padding-right: 8px;
//         }

//         .col-align-image,
//         .col-align-alt
//         {
//             width: 50%;
//         }
//     }

//     @media (min-width: 576px)
//     {
//         flex-direction: row;

//         .col-uploader
//         {
//             width: 400px;
//         }
//     }
// `

const Box = styled.div`
    background-color: #f9f9f9;

    padding: 8px;

    .ant-form-item
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    ${props => props.padding === 'small' && `
        padding: 4px;
    `}

    ${props => props.direction === 'horizontal' && `
        display: flex;

        flex-direction: row;
        flex-wrap: wrap;
    `}

    ${props => props.divider && `
        border-top: 1px solid #d9d9d9;
    `}
`

function Comp (
    {
        action,

        sortIndex,
        length,

        id,
        type,
        value = {},
        options = {},
        style = {},

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    const { image } = value
    const formLayout = { colon: false }
    
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const newValue = { ...value, [fieldName]: fieldValue }
            onChange('edit', sortIndex, { id, type, options, style, value: newValue })
        }
    }

    function handleStyleChange (style)
    {
        if (typeof onChange === 'function')
        {
            onChange('edit', sortIndex, { id, type, options, style, value })
        }
    }

    return (
        <Node
            title="รูป"
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <Form layout="vertical" >
                <Box>
                    <Image 
                        layout={formLayout}
                        name="image"
                        value={image}
                        ratio={0.75}
                        disabled={!!disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Box>
                <Box divider={true} >
                    <Style 
                        layout={formLayout}
                        value={style}
                        onChange={handleStyleChange}
                    />
                </Box>
                {/* <Container 
                    disabled={!!action} 
                    span={options.span || 1}
                >
                    <div className="col-uploader">
                        <Image 
                            layout={formLayout}
                            name="image"
                            value={image}
                            ratio={0.75}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </div>

                    <div className="col-alt-and-align">
                        <Style 
                            layout={formLayout}
                            value={style}
                            onChange={handleStyleChange}
                        />
                    </div>
                </Container> */}
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)