import React from 'react'
import styled from 'styled-components'

import { Form, Row, Col } from 'antd'

import Title from '../../../../post/Tab.Overview/Field.Title'

import Image from '../../../../../components/Field.Image'

const Container = styled.div`
    background: rgba(0,0,0, 0.025);
    min-height: 200px;
    padding: 16px 8px;
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('walkthrough', { ...data, [name]: value })
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const url = data.url || ''
    const logo = data.logo
    
    return (
        <Container>
            <Form layout="vertical">
                <Row gutter={16} >
                    <Col xs={24} sm={10} md={8} >
                        <Image 
                            layout={fieldLayout}
                            label="ไอคอน"
                            name="logo"
                            position="center"
                            ratio={1}
                            value={logo}
                            disabled={disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </Col>
                    <Col xs={24} sm={14} md={16} >
                        <Title 
                            layout={fieldLayout}
                            label="ชื่อหัวข้อ"
                            name="title"
                            value={title}
                            limit={50}
                            disabled={disabled}
                            onChange={handleChange}
                        />

                        <Title 
                            layout={fieldLayout}
                            label="ลิงก์"
                            name="url"
                            value={url}
                            limit={250}
                            disabled={disabled}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default Component