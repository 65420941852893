import moment from 'moment'

function edit (doc)
{
    const email = doc.get('email');

    let createdAt = doc.get('createdAt') || null
    let updatedAt = doc.get('updatedAt') || null

    if (!!createdAt)
    {
        createdAt = moment(createdAt)
    }

    if (!!updatedAt)
    {
        updatedAt = moment(updatedAt)
    }
    
    return {
        email,
        createdAt,
        updatedAt,
    }
}

function view (doc)
{
    const email = doc.get ('email') || '';
    
    let createdAt = doc.get('createdAt') || null
    let updatedAt = doc.get('updatedAt') || null

    if (!!createdAt)
    {
        createdAt = moment(createdAt)
    }

    if (!!updatedAt)
    {
        updatedAt = moment(updatedAt)
    }
    
    return {
        key: doc.id,
        id: doc.id,

        email,
        createdAt,
        updatedAt,
    }
}

export default 
{
    edit,
    view,
}