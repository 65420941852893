import React from 'react'
import ColorUtil from '@bit/wirunekaewjai.utils.color'
import styled from 'styled-components'

import { Form, Popover, Button } from 'antd'
import { SketchPicker } from 'react-color'

const Content = styled.div`
    .sketch-picker
    {
        box-shadow: none !important;
    }

    ${props => props.disabled && `
        pointer-events: none;
    `}
`

const Prefix = styled(Button)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;

    cursor: pointer;

    padding: 0 8px;

    width: 150px;
`

const Box = styled.span`
    width: 20px;
    height: 16px;

    margin-right: 8px;

    border: 1px solid #d9d9d9;
    border-radius: 4px;

    outline: none;

    flex-shrink: 0;
`

const Flex = styled.span`
    flex-grow: 1;
`

function Component(
    {
        layout,
        
        disabled,
        
        label = 'สี',
        name = 'color',

        value = 'rgba(0,0,0, 1)',
        alpha,
        onChange,
    }
)
{
    function handleChange (changedValue)
    {
        onChange (name, ColorUtil.rgba (changedValue))
    }

    return (
        <Form.Item 
            {...layout}
            label={label}
            disabled={disabled}
        >
            <Popover
                placement="top"
                trigger="click"
                disabled={disabled}
                content={
                    !disabled &&
                    <Content disabled={disabled} >
                        <SketchPicker 
                            disabled={disabled}
                            disableAlpha={!alpha}
                            color={ColorUtil.obj (value)}
                            presetColors={[ ]}
                            onChange={function (color)
                            {
                                handleChange (color.rgb)
                            }}
                        />
                    </Content>
                }
            >
                <Prefix disabled={disabled} >   
                    <Box style={{ backgroundColor: value }} />
                    {/* <Flex /> */}
                    <Flex>{ColorUtil.hex (value)}</Flex>
                </Prefix>
            </Popover>
        </Form.Item>
    )
}

export default Component