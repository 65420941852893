import React from 'react'
import { Link } from 'react-router-dom'

import DateTimeUtil from '../DateTime'

import Title from '../../components/Datatable/Column.Title'
import DateTime from '../../components/Datatable/Column.DateTime'

import Status from '../../pages/cloudinaries/Column.Status'
import Usage from '../../pages/cloudinaries/Column.Usage'

const COLUMNS = 
[
    {
        title: 'Cloud Name',
        dataIndex: 'name',
        width: 180,
    },

    {
        title: 'Api Key',
        dataIndex: 'key',
        width: 200,
    },

    {
        title: 'Api Secret',
        dataIndex: 'secret',
        width: 280,
    },

    {
        title: 'การใช้งาน',
        dataIndex: 'usage',
        width: 80,
    },

    {
        title: 'สถานะ',
        dataIndex: 'status',
        width: 100,
        render: e => (
            <div style={{ minWidth: 100 }} >
                <Status value={e} />
            </div>
        ),
        filterMultiple: false,
        filters:
        [
            {
                text: 'INACTIVE',
                value: 'inactive',
            },
            {
                text: 'ACTIVE',
                value: 'active',
            },
        ],
    },

    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: 104,
        render: e => (
            <div style={{ minWidth: 104 }} >
                <DateTime value={e} />
            </div>
        ),

        defaultSortOrder: 'descend',
        sorter: (a, b) => DateTimeUtil.compare(a.createdAt, b.createdAt),
        sortDirections: ['descend', 'ascend'],
    },
]

function columns (
    {
        keyword,
        syncing,
    }
)
{
    const _columns = [ ...COLUMNS ]
    
    _columns[0].render = function (value, record)
    {
        return (
            <div style={{ minWidth: 120, wordBreak: 'keep-all', whiteSpace: 'nowrap' }} >
                <Link to={`/cloudinary/${record.id}`}>
                    <Title 
                        value={value}
                        keyword={keyword}
                    />
                </Link>
                {/* <Title 
                    value={value}
                    keyword={keyword}
                /> */}
            </div>
        )
    }

    _columns[1].render = function (value)
    {
        return (
            <small style={{ minWidth: 180, wordBreak: 'keep-all', whiteSpace: 'nowrap' }} >
                <Title 
                    value={value}
                    keyword={keyword}
                />
            </small>
        )
    }

    _columns[2].render = function (value)
    {
        return (
            <small style={{ minWidth: 260, wordBreak: 'keep-all', whiteSpace: 'nowrap' }} >
                <Title 
                    value={value}
                    keyword={keyword}
                />
            </small>
        )
    }

    _columns[3].render = function (value)
    {
        return (
            <div style={{ minWidth: 80 }} >
                <Usage {...value} syncing={syncing} />
            </div>
        )
    }

    return _columns
}

export default
{
    columns,
}