// import TextUtil from '@bit/wirunekaewjai.utils.text'

function view (doc)
{
    return {
        key: doc.id,
        id: doc.id,

        name: doc.get('name'),
        email: doc.get('email'),
        phone: doc.get('phone'),
        message: doc.get('message'),
        // message: TextUtil.excerpt (doc.get('message'), 60),
        createdAt: doc.get('createdAt'),
    }
}

export default 
{
    view,
}