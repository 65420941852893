import firebase from 'firebase/app'

import ParseUtil from './Parse'

async function get (id, callback)
{
    const { space } = window.config
    
    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/contacts/${id}`)

    return await queryRef.onSnapshot(callback)
}

async function list (callback, page, filters, sorters)
{
    const { space, query } = window.config
    
    const orderBy = sorters.field || 'createdAt'
    const order = sorters.order === 'ascend' ? 'asc' : 'desc'

    // const where = [ '-' ]

    // if (!!filters.status && filters.status.length > 0)
    // {
    //     where[0] = filters.status[0]
    // }

    const limit = query.limit * page
    const cfs = firebase.firestore()

    let queryRef = cfs.collection(`${space}/contacts`)

    // queryRef = queryRef.where('_filters', 'array-contains', where.join('|'))
    if (orderBy === 'createdAt')
    {
        if (!!filters['createdAt'] && filters['createdAt'].length > 0)
        {
            // const m = filters['createdAt'][0]

            // if (!!m && !!m.valueOf)
            // {
            //     if (order === 'desc')
            //     {
            //         queryRef = queryRef.where ('createdAt', '<=', m.endOf ('day').valueOf ())
            //     }
            //     else if (order === 'asc')
            //     {
            //         queryRef = queryRef.where ('createdAt', '>=', m.startOf ('day').valueOf ())
            //     }
            // }
            const m1 = filters['createdAt'][0]
            const m2 = filters['createdAt'][1]

            const has1 = !!m1 && !!m1.valueOf
            const has2 = !!m2 && !!m2.valueOf

            if (has1)
            {
                queryRef = queryRef.where ('createdAt', '>=', m1.startOf ('day').valueOf ())
            }

            if (has2)
            {
                queryRef = queryRef.where ('createdAt', '<=', m2.endOf ('day').valueOf ())
            }
        }
    }
    
    queryRef = queryRef.orderBy(orderBy, order)
    queryRef = queryRef.limit(limit)

    const parse = ParseUtil.view

    return await queryRef.onSnapshot(function (snapshot)
    {
        let items = []
        let keys = []
        
        snapshot.docs.forEach(function (doc)
        {
            keys.push (doc.id)
            items.push (parse (doc))
        })

        callback ({ keys, items })
    })
}

export default 
{
    get,
    list,
}