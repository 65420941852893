import React, { useState, useEffect } from 'react'
import {
    Form,
    Input,
    Icon,
} from 'antd'

import styled from 'styled-components'

import Label from '../../../components/Label.Counter'
import Delay from '../../../components/DelayedInput'

import toJSON from 'json-stable-stringify'

const LabelTitle = styled.span`
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;

    cursor: pointer;

    @media (hover: hover)
    {
        &:hover
        {
            text-decoration: underline;
        }
    }

    ${props => props.disabled && `
        pointer-events: none;
    `}

    .title
    {
        padding-right: 8px;
    }
`

function Component(
    {
        layout,
        limit = 1000,
        rows = 4,

        minRows = 8,
        maxRows = 10,
        
        disabled,

        label = 'stylesheet',
        // name = 'style',

        value,
        onChange,
    }
)
{
    const [ json, setJson ] = useState (toJSON (value, { space: '    ' }))
    const [ open, setOpen ] = useState (false)

    function handleChange (changedValue)
    {
        onChange(JSON.parse (changedValue || '{}'))
    }

    function handleMount ()
    {
        setJson (toJSON (value, { space: '    ' }))
    }

    useEffect (handleMount, [ value ])

    return (
        <Delay 
            value={json}
            onChange={handleChange}
        >
            {
                ({ value, onChange }) => (
                    <Form.Item 
                        {...layout}
                        label={
                            <Label 
                                text={
                                    <LabelTitle
                                        disabled={!!disabled || !label} 
                                        onClick={() => setOpen (!open)} 
                                    >
                                        <span className="title" >{label}</span>
                                        {
                                            !!label &&
                                            <Icon
                                                type={'right'}
                                                style={{ fontSize: 8 }}
                                                rotate={!!open ? 90 : 0}
                                            />
                                        }
                                    </LabelTitle>
                                } 
                                value={value.length} 
                                limit={limit} 
                            />
                        }
                    >
                        {
                            !!open &&
                            <Input.TextArea 
                                rows={rows} 
                                disabled={disabled}
                                style={{ resize: 'none' }}
                                autosize={{ minRows, maxRows }}
                                value={value}
                                onChange={function (e)
                                {
                                    onChange (e.target.value)
                                }}
                            />
                        }
                    </Form.Item>
                )
            }
        </Delay>
    )
}

export default Component