import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { Form } from 'antd'

import styled from 'styled-components'

import Node from '../../../../post/Tab.Content/node/parent'
// import Image from '../../../../../components/Field.Image'
import Title from '../../../../post/Tab.Overview/Field.Title'
import Excerpt from '../../../../post/Tab.Overview/Field.Excerpt'

const Container = styled.div`
    background: rgba(0,0,0, 0.025);
    
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 8px;

    .ant-form-item
    {
        width: 100%;
        margin-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        action,
        disabled,

        sortIndex,
        length,

        id,
        
        title = '',
        subtitle = '',
        description = '',

        onChange,
    }
)
{
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const old = { id, title, subtitle, description }
            onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `รายการที่ ${sortIndex + 1}`

    const nodeTitle = !!title ? title : defaultTitle

    return (
        <Node
            title={nodeTitle}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
            cols={
                {
                    xs: 24,
                    // sm: 12,
                    // md: 8,
                }
            }
        >
            <Form layout="vertical" >
                <Container disabled={!!disabled} >
                    <Title 
                        layout={formLayout}
                        label="หัวข้อ"
                        name="title"
                        value={title}
                        limit={100}
                        disabled={!!disabled}
                        onChange={handleChange}
                    />

                    <Title 
                        layout={formLayout}
                        label="หัวข้อรอง"
                        name="subtitle"
                        value={subtitle}
                        limit={100}
                        disabled={!!disabled}
                        onChange={handleChange}
                    />

                    <Excerpt 
                        layout={formLayout}
                        label="ข้อความ"
                        name="description"
                        value={description}
                        limit={500}
                        rows={3}
                        minRows={3}
                        maxRows={6}
                        disabled={!!disabled}
                        onChange={handleChange}
                    />
                </Container>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)