import React from 'react'
import Layer from '@bit/wirunekaewjai.components.layer'
import styled from 'styled-components'

// const Modal = styled.div`
//     background-color: rgba(0,0,0, 0.65);
//     transition: opacity 0.3s ease;

//     display: flex;

//     align-items: center;
//     justify-content: center;

//     position: fixed;

//     left: 0;
//     right: 0;

//     top: 0;
//     bottom: 0;

//     ${props => !props.open && `
//         pointer-events: none;
//         opacity: 0;

//         overflow: hidden;
//     `}
// `

// const Global = createGlobalStyle`
//     body
//     {
//         ${props => !!props.open && `
//             overflow: hidden;
//             position: absolute;

//             right: 0;
//             left: 0;
//             top: 0;   
//             bottom: 0;
//         `}
//     }
// `

const Modal = styled(Layer)`
	background: rgba(0,0,0, 0.2);
	z-index: ${props => props.zIndex};
	
	> :first-child
	{
        transition: transform 0.2s ease;
	}

	&:not(.open) > :first-child
	{
        transform: scale(0);
	}
`

const MULTIPLY = 100

function Component (
    {
        children,

        open,
        onClose,

        zIndex = 1,
    }
)
{
    return (
        <Modal 
            open={open}
            onClose={onClose} 
            zIndex={zIndex * MULTIPLY}
            // style={{ zIndex: zIndex * MULTIPLY }}
            // onClick={e =>
            //     {
            //         if (!!onClose && e.target === e.currentTarget)
            //         {
            //             onClose()
            //         }
            //     }}
        >
            {children}
            {/* <Global open={open} /> */}
        </Modal>
    )
}

export default Component