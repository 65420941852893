import firebase from 'firebase/app'

import ParseUtil from './Parse'

import JFile from 'js-file-download'
// import J2C from 'json2csv'

import { json2csv } from 'json-2-csv'

async function get (id, callback)
{
    const { space } = window.config
    
    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/registrations/${id}`)

    return await queryRef.onSnapshot(callback)
}

async function list (callback, page, filters, sorters)
{
    const { space, query } = window.config
    
    const orderBy = sorters.field || 'createdAt'
    const order = sorters.order === 'ascend' ? 'asc' : 'desc'

    // const where = [ '-' ]

    // if (!!filters.status && filters.status.length > 0)
    // {
    //     where[0] = filters.status[0]
    // }

    const limit = Math.min (query.limit * page, 10000)
    const cfs = firebase.firestore()

    let queryRef = cfs.collection(`${space}/registrations`)

    // queryRef = queryRef.where('_filters', 'array-contains', where.join('|'))
    if (orderBy === 'createdAt')
    {
        if (!!filters['createdAt'] && filters['createdAt'].length > 0)
        {
            // const m = filters['createdAt'][0]

            // if (!!m && !!m.valueOf)
            // {
            //     if (order === 'desc')
            //     {
            //         queryRef = queryRef.where ('createdAt', '<=', m.endOf ('day').valueOf ())
            //     }
            //     else if (order === 'asc')
            //     {
            //         queryRef = queryRef.where ('createdAt', '>=', m.startOf ('day').valueOf ())
            //     }
            // }
            const m1 = filters['createdAt'][0]
            const m2 = filters['createdAt'][1]

            const has1 = !!m1 && !!m1.valueOf
            const has2 = !!m2 && !!m2.valueOf

            if (has1)
            {
                queryRef = queryRef.where ('createdAt', '>=', m1.startOf ('day').valueOf ())
            }

            if (has2)
            {
                queryRef = queryRef.where ('createdAt', '<=', m2.endOf ('day').valueOf ())
            }
        }
    }

    queryRef = queryRef.orderBy(orderBy, order)
    queryRef = queryRef.limit(limit)

    const parse = ParseUtil.view

    return await queryRef.onSnapshot(function (snapshot)
    {
        let items = []
        let keys = []
        
        snapshot.docs.forEach(function (doc)
        {
            keys.push (doc.id)
            items.push (parse (doc))
        })

        callback ({ keys, items })
    })
}

async function download (project, projectName, startAt, endAt, onProgress)
{
    const limit = 1000

    const { space } = window.config
    const cfs = firebase.firestore()

    let queryRef = cfs.collection (`${space}/$registrations/${project}/items`)

    if (!!startAt && !!startAt.valueOf)
    {
        queryRef = queryRef.where ('createdAt', '>=', startAt.startOf ('day').valueOf ())
    }

    if (!!endAt && !!endAt.valueOf)
    {
        queryRef = queryRef.where ('createdAt', '<=', endAt.endOf ('day').valueOf ())
    }

    queryRef = queryRef.orderBy ('createdAt', 'desc')

    let cursor = null
    let items = []

    do
    {
        let ref = queryRef

        if (!!cursor)
        {
            ref = ref.startAfter (cursor)
        }

        ref = ref.limit (limit)

        const snap = await ref.get ()

        snap.docs.forEach (doc =>
        {
            const data = doc.data ()

            if (!!data.firstName && data.firstName.length > 255)
            {
                data.firstName = data.firstName.slice (0, 255)
            }

            if (!!data.lastName && data.lastName.length > 255)
            {
                data.lastName = data.lastName.slice (0, 255)
            }

            items.push (data)
        })

        if (snap.docs.length > 0)
        {
            cursor = snap.docs[snap.docs.length - 1]
        }

        if (typeof onProgress === 'function')
        {
            onProgress (items.length)
        }

        if (snap.docs.length < limit)
        {
            break
        }
    }
    while (true)

    const fields = 
    [
        {
            label: 'Email Address',
            value: 'email',
        },
        {
            label: 'First Name',
            value: 'firstName',
        },
        {
            label: 'Last Name',
            value: 'lastName',
        },
        {
            label: 'Phone',
            value: 'phone',
        },
        {
            label: 'Budget',
            value: 'budget',
        },
    ]

    const rows = items.map (item =>
    {
        const row = {}

        fields.forEach (({ label, value }) =>
        {
            row[label] = item[value] || ''
        })

        return row
    })

    if (items.length < 10000)
    {
        await new Promise (r => setTimeout (r, 2000))
    }

    const fileName = `รายชื่อผู้ลงทะเบียนจาก ${projectName}.csv`
    
    json2csv (rows, function (err, csv)
    {
        console.log (err)
        JFile (csv, fileName)
    }, { excelBOM: true })

    // const csvFile = new J2C.Parser ({ fields }).parse (items)
}

export default 
{
    get,
    list,

    download,
}