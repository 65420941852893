import Parse from './Parse'
import Query from './Query'
import Search from './Search'
import Table from './Table'

export default
{
    Parse,
    Query,
    Search,
    Table,
}