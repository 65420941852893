import React from 'react'
import { Row, Col, Divider } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'
import Excerpt from '../../post/Tab.Overview/Field.Excerpt'

function Component(
    {
        data = {},
        disabled,
        onChange,
    }
)
{
    function handleChange (name, value)
    {
        onChange('info', { ...data, [name]: value })
    }

    const fieldLayout = { colon: false }

    const address = data.address || ''
    const phone = data.phone || ''
    const email = data.email || ''
    const fax = data.fax || ''

    return (
        <>
            <Divider orientation="center" >ติดต่อ</Divider>
            <Excerpt 
                layout={fieldLayout}
                limit={200}
                rows={3}
                minRows={2}
                
                label="ที่อยู่"
                name="address"
                value={address}
                disabled={disabled}
                onChange={handleChange}
            />

            <Title 
                layout={fieldLayout}
                limit={100}
                label="อีเมล"
                name="email"
                value={email}
                disabled={disabled}
                onChange={handleChange}
            />
            
            <Row gutter={16} >
                <Col xs={24} md={12} >
                    <Title 
                        layout={fieldLayout}
                        limit={50}
                        label="เบอร์โทรศัพท์"
                        name="phone"
                        value={phone}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                </Col>
                <Col xs={24} md={12} >
                    <Title 
                        layout={fieldLayout}
                        limit={50}
                        label="แฟ็กซ์"
                        name="fax"
                        value={fax}
                        disabled={disabled}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Component