import firebase from 'firebase/app'

async function get (callback)
{
    const { space } = window.config
    
    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/services/mailgun`)

    return await queryRef.onSnapshot(doc =>
    {
        const original = doc.data ()
        const current = doc.data ()

        callback ({ original, current })
    })
}

export default 
{
    get,
}