import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'

import { Form } from 'antd'

import Node from '../../../../../post/Tab.Content/node/parent'
import Image from '../../../../../../components/Field.Image'

const Container = styled.div`
    background: rgba(0,0,0, 0.025);
    
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 4px;

    .ant-form-item
    {
        width: 100%;
    }

    .ant-form-item.alt
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .ant-form-item.thumbnail
    {
        margin-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        // title = 'รูป',

        action,

        sortIndex,
        length,

        id,
        type,

        image,
        link = '',

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const old = { id, type, image, link }
            onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `รูปที่ ${sortIndex + 1}`

    const title = !!image && !!image.title ? image.title : defaultTitle

    return (
        <Node
            title={title}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
            cols={
                {
                    xs: 24,
                    sm: 12,
                    md: 8,
                }
            }
        >
            <Form layout="vertical" >
                <Container disabled={!!disabled} >
                    <Image 
                        layout={formLayout}
                        label={null}
                        name="image"
                        value={image}
                        ratio={0.296875}
                        disabled={!!disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Container>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)