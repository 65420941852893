function filter (items, keyword)
{
    if (!keyword)
        return items || []

    keyword = keyword.toLowerCase()

    return (items || []).filter (item => item.overview.title.toLowerCase().indexOf(keyword) >= 0)
}

export default 
{
    filter,
}