import React from 'react'
import styled from 'styled-components'

import { Row, Col, Button, Modal } from 'antd'

import Handle from './Handle'
import Title from './Title'

// import DropdownSpan from './Dropdown.Span'
// import DropdownDelete from './Dropdown.Delete'

const Toolbar = styled.div`
    height: 40px;

    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;

    padding: 0;
    /* padding: 4px 8px; */
    font-weight: bold;

    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;

    align-items: center;
    justify-content: flex-start;

    .grow
    {
        flex-grow: 1;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        action,
        title,

        index,
        length,

        // id,
        // type,
        // value,
        // options,
        open,

        onChange,
        onOpenChange,
    }
)
{
    const colStyle = 
    {
        width: 38
    }

    function handleDelete ()
    {
        if (typeof onChange === 'function')
        {
            onChange('remove', index)
        }
    }

    return (
        <Toolbar disabled={!!action} >
            {length > 1 && <Handle />}
            <Title 
                title={title}
                disabled={!!action}
                open={open}
                onChange={onOpenChange}
                inset={length < 2}
            />
            <div className="grow" />
            <Row
                type="flex"
                gutter={8}
                style={{ paddingRight: 8 }}
            >
                {/* <Col style={colStyle} >
                    <DropdownSpan 
                        index={index}

                        id={id}
                        type={type}
                        value={value}
                        options={options}
                        onChange={onChange}
                    />
                </Col> */}
                <Col style={colStyle} >
                    {/* <DropdownDelete 
                        title={title}
                        index={index}
                        onChange={onChange}
                    /> */}
                    <Button 
                        type="danger" 
                        icon="delete"
                        // style={{ fontSize: 16 }} 
                        disabled={!!action}
                        onClick={() =>
                        {
                            const m = Modal.confirm(
                                {
                                    title: `ต้องการลบ${title} ?`,
                                    okButtonProps: { type: 'danger' },
                                    okText: 'ใช่',
                                    cancelText: 'ไม่ใช่',
                                    onOk: () => 
                                    {
                                        handleDelete ()
                                        m.destroy ()
                                    },
                                }
                            )
                        }}
                    />
                </Col>
            </Row>
        </Toolbar>
    )
}

export default Comp