import React from 'react'
import { Button, Dropdown, Menu } from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    padding: 8px;

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        disabled,
        onAdd,
    }
)
{
    return (
        <Container disabled={!!disabled} >
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu>
                        <Menu.Item 
                            key="0"
                            onClick={() => onAdd('image')}
                        >
                            <span>รูป</span>
                        </Menu.Item>
                        
                        {/* <Menu.Item 
                            key="1"
                            onClick={() => onAdd('link')}
                        >
                            <span>ลิงก์</span>
                        </Menu.Item> */}

                        <Menu.Item 
                            key="2"
                            onClick={() => onAdd('video')}
                        >
                            <span>วิดีโอ</span>
                        </Menu.Item>

                        <Menu.Item 
                            key="2"
                            onClick={() => onAdd('youtube')}
                        >
                            <span>Youtube</span>
                        </Menu.Item>
                    </Menu>
                }
            >
                <Button 
                    title="เพิ่มเนื้อหา"
                    type="primary" 
                    icon="plus" 
                    disabled={!!disabled}
                >
                    เพิ่มเนื้อหา
                </Button>
            </Dropdown>
        </Container>
    )
}

export default Comp