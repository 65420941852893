import React, { useState } from 'react'
import { Col } from 'antd'

import styled from 'styled-components'
import Header from './Header'

const Container = styled.div`
    margin: 8px;

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    overflow: hidden;
`

// function parseSpan ({ span = 1 })
// {
//     const col = 
//     {
//         xs: 24,
//     }

//     // if (span === 2)
//     // {
//     //     col.md = 12
//     // }

//     // if (span === 3)
//     // {
//     //     col.md = 8
//     // }

//     // if (span === 4)
//     // {
//     //     col.md = 12
//     //     col.lg = 6
//     // }

//     return col
// }

function Comp (
    {
        action,
        title,

        index,
        length,

        children,

        // id,
        // type,
        // value,
        // options,

        onChange,

        cols = {},
    }
)
{
    const [ open, setOpen ] = useState (true)

    return (
        <Col
            style={{ zIndex: 101 }}
            xs={24}
            {...cols}
            // {...parseSpan(options)}
        >
            <Container>
                <Header 
                    action={action}
                    title={title}

                    index={index}
                    length={length}

                    // id={id}
                    // type={type}
                    // value={value}
                    // options={options}

                    onChange={onChange}

                    open={open}
                    onOpenChange={e => setOpen (e)}
                />
                {!!open && children}
            </Container>
        </Col>
    )
}

export default Comp