import React from 'react'
import { Progress } from 'antd'

export default function (
    {
        value = 0,
        limit = 1,

        syncing,
    }
)
{
    if (!!syncing)
    {
        return (
            <small>กำลังโหลด ...</small>
        )
    }

    const p = parseInt ((value / limit) * 100, 10)

    let status = 'success'

    if (p >= 75)
    {
        status = 'exception'
    }
    else if (p >= 50)
    {
        status = 'normal'
    }

    return (
        <div>
            <Progress 
                type="circle"
                width={32}
                status={status}
                percent={p}
                format={p => <small>{p}%</small>}
            />
        </div>
    )
}