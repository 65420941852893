import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { Row } from 'antd'

import Paragraph from './node/Paragraph'
import Image from './node/Image'

function Comp (
    {
        action,
        contents = [],
        createTemp,

        onChange,
        onUploading,
    }
)
{
    return (
        <Row type="flex" >
            {
                contents.map((content, i) =>
                {
                    if (content.type === 'paragraph')
                    {
                        return (
                            <Paragraph
                                key={content.id}
                                id={content.id}
                                index={i}
                                sortIndex={i}
                                length={contents.length}
                                onChange={onChange}
                                action={action}
                                {...content}
                            />
                        )
                    }

                    if (content.type === 'image')
                    {
                        return (
                            <Image
                                key={content.id}
                                id={content.id}
                                index={i}
                                sortIndex={i}
                                length={contents.length}
                                onChange={onChange}
                                onUploading={onUploading}
                                action={action}
                                createTemp={createTemp}
                                {...content}
                            />
                        )
                    }

                    return null
                })
                .filter (e => !!e)
            }
        </Row>
    )
}

export default SortableContainer(Comp)