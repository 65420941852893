import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import { Form, Row, Col } from 'antd'

import Node from '../../../pages/post/Tab.Content/node/parent'
import Image from '../../Field.Image'
import Link from '../../Field.Link'

function Comp (
    {
        action,

        sortIndex,
        length,

        value = {},

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    const { id, image, imageDesktop, link = '' } = value

    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            onChange ('edit', sortIndex, { ...value, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }

    return (
        <Node
            title="รูป"
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
        >
            <Form layout="vertical" >
                <Row
                    style={{ padding: 8 }}
                    gutter={8}
                >
                    <Col xs={24} md={10} >
                        <Image 
                            layout={formLayout}
                            label="รูปสำหรับ Mobile (640x770)"
                            name="image"
                            value={image}
                            ratio={1.203}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </Col>

                    <Col xs={24} md={14} >
                        <Image 
                            layout={formLayout}
                            label="รูปสำหรับ Desktop (1280x641)"
                            name="imageDesktop"
                            value={imageDesktop}
                            ratio={0.5007}
                            disabled={!!disabled}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />

                        <div style={{ height: 16 }} />

                        <Link 
                            id={id}
                            layout={formLayout}
                            disabled={disabled}
                            value={link}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)