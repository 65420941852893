import React from 'react'
import {
    Form,
    DatePicker,
} from 'antd'

function Component(
    {
        layout,
        extra,

        disabled,

        label = 'วันและเวลาที่เผยแพร่',
        name = 'publishedAt',

        value,
        onChange,
    }
)
{
    return (
        <Form.Item 
            {...layout}
            label={label}
            extra={extra}
        >
            <DatePicker  
                disabled={disabled}
                format="DD MMM YYYY HH:mm น" 
                showTime={{ format: 'HH:mm' }}
                style={{ width: `100%` }}
                autoFocus={false}

                value={value}
                onChange={dateTime => onChange(name, dateTime)}
            />
        </Form.Item>
    )
}

export default Component