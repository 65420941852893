module.exports = [
    "ม.ค.", 
    "ก.พ.", 
    "มี.ค.",
    "เม.ย.", 
    "พ.ค.", 
    "มิ.ย.",
    "ก.ค.", 
    "ส.ค.", 
    "ก.ย.",
    "ต.ค.", 
    "พ.ย.", 
    "ธ.ค."
]