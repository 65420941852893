import { createGlobalStyle } from 'styled-components'

const Global = createGlobalStyle`
    html
    {
        -ms-overflow-style: scrollbar;

        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }

    *,
    *::before,
    *::after 
    {
        -webkit-box-sizing: inherit;
                box-sizing: inherit;
    }

    body
    {      
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
                
        font-family: 'Prompt', sans-serif;
        font-weight: 300;
        font-size: 13px;

        line-height: 1.4;

        width: 100%;
        height: 100%;

        margin: 0;
        padding: 0;
    }

    #root
    {
        width: 100%;
        height: 100%;

        margin: 0;
        padding: 0;
    }
`

export default Global