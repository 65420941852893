import React, { useState, useEffect } from 'react'
import { message } from 'antd'

import Project from '../../utils/Space.Project'

import Infinite from '../../components/Datatable/Infinite'
import Table from '../../components/Datatable/Table'

import Page from '../../components/Page'

function Comp (
    {
        history,
    }
)
{
    const _page = parseInt (window.sessionStorage.getItem ('projects.page') || 1, 10)

    const [ selection, setSelection ] = useState([ ])
    const [ keyword, setKeyword ] = useState('')
    const [ filters, setFilters ] = useState({ })
    const [ sorters, setSorters ] = useState({ })
    const [ actions, setActions ] = useState([ ])

    const [ processing, setProcessing ] = useState(false)
    const [ loading, setLoading ] = useState(0)
    const [ page, setPage ] = useState(_page)

    const [ projects, setProjects ] = useState([])
    
    let _projects = projects

    _projects = Project.Search.filter(_projects, keyword)

    function handleMount ()
    {
        const unsubscribes = []
        const promises = 
        [
            Project.Query.list(function ({ items, keys })
            {
                setProjects(items)
                setSelection(selection.filter (key => keys.indexOf(key) >= 0) )

                if (!processing)
                {
                    setLoading(2)
                }

            }, page, filters, sorters),
        ]

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleTableChange (pagination, filters, sorters)
    {
        setLoading(0)
        setFilters(filters || {})
        setSorters(sorters || {})
        setSelection([ ])
    }

    function handleSelectionChange (selectedRowKeys)
    {
        setSelection (selectedRowKeys)
        setActions (
            Project.Selection.getActions (
                { 
                    selection: selectedRowKeys, 
                    items: _projects,

                    onPublish: handlePublish,
                    onUnpublish: handleUnpublish,
                    onDelete: handleDelete,
                    onClear: handleClear,
                }
            )
        )
    }

    function setAction (status, text)
    {
        if (status === 'begin')
        {
            document.getElementById ('root').classList.add ('loading')

            setLoading(0)
            setProcessing(true)
        }
        else if (status === 'success')
        {
            setLoading (2)
            setProcessing (false)
            setSelection ([])

            message.success(text)
            
            document.getElementById ('root').classList.remove ('loading')
        }
        else if (status === 'error')
        {
            setLoading (2)
            setProcessing (false)

            message.error (text)

            document.getElementById ('root').classList.remove ('loading')
        }
    }

    async function handlePublish (items = [])
    {
        setAction ('begin')

        try
        {
            await Project.Update.publish (items.filter (item => item.info.status !== 'published').map (e => e.id))
            setAction ('success', 'แผยแพร่โครงการเรียบร้อย')
        }
        catch (err)
        {
            setAction ('error', 'พบข้อผิดพลาดระหว่างการเผยแพร่ กรุณาตรวจสอบข้อมูลและเผยแพร่อีกครั้ง')
        }
    }

    async function handleUnpublish (items = [])
    {
        setAction ('begin')

        try
        {
            await Project.Update.unpublish (items.filter (item => item.info.status !== 'draft').map (e => e.id))
            setAction ('success', 'หยุดแผยแพร่โครงการเรียบร้อย')
        }
        catch (err)
        {
            setAction ('error', 'พบข้อผิดพลาดระหว่างการหยุดเผยแพร่ กรุณาตรวจสอบข้อมูลและหยุดเผยแพร่อีกครั้ง')
        }
    }

    async function handleDelete (items = [])
    {
        setAction ('begin')

        try
        {
            await Project.Update.delete (items.filter (item => item.info.status === 'draft').map (e => e.id))

            setAction ('success', 'ลบโครงการเรียบร้อย')
        }
        catch (err)
        {
            setAction ('error', 'พบข้อผิดพลาดระหว่างการลบ กรุณาตรวจสอบข้อมูลและลบอีกครั้ง')
        }
    }

    function handleClear ()
    {
        setSelection ([])
    }

    function handleLoadMore (page)
    {
        window.sessionStorage.setItem ('projects.page', page)

        setLoading(1)
        setPage(page)
    }

    function handleCreate ()
    {
        const id = 'create'
        history.push (`/projects/${id}`)
    }
    
    useEffect (handleMount, [ filters, sorters, page ])

    const loadingPage = loading === 0 || !!processing

    const hasMore = loading === 2 && projects.length >= page * window.config.query.limit
    const loadingMore = loading === 1

    return (
        <Page title="จัดการโครงการ" >
            <Infinite
                more={hasMore}
                loading={loadingMore}
                onLoad={handleLoadMore}
            >
                <Table 
                    unit="โครงการ"
                    columns={Project.Table.columns ({ keyword })}

                    emptyText="ไม่มีโครงการ"

                    keyword={keyword}
                    loading={loadingPage}

                    selection={selection}
                    actions={actions}

                    items={_projects}

                    onCreate={handleCreate}

                    onTableChange={handleTableChange}
                    onSelectionChange={handleSelectionChange}
                    onKeywordChange={setKeyword}
                />
            </Infinite>
        </Page>
    )
}

export default Comp