import styled from 'styled-components'

const Container = styled.div`
    display: flex;

    flex-wrap: nowrap;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;
`

export default Container