import React, { useState, useEffect } from 'react'
import { 
    Form,
    message,
} from 'antd'

import styled from 'styled-components'
import Screen from '../../../components/Modal/Screen'

import Mailgun from '../../../utils/Space.Service.Mailgun'

import Title from './Title'
import Footer from './Footer'
import Loader from '../../post/Loader'

import Receivers from './Field.Receivers'

const Modal = styled.div`
    width: 100%;
    
    height: calc(100% - 48px);

    margin: 24px;
    /* min-height: 280px; */
    
    max-width: 720px;
    max-height: calc(100% - 48px);

    position: relative;

    overflow-x: hidden;
    overflow-y: hidden;
    
    background: white;

    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);

    transform: scale(0);
    transition: transform 0.2s ease;

    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    ${props => !!props.open && `
        transform: none;
    `}
`

const Content = styled.div`
    width: 100%;
    height: 100%;

    flex-grow: 1;

    overflow-x: hidden;
    overflow-y: auto;
`

const Section = styled.div`
    flex-shrink: 0;
`

const Layout = styled.div`
    background-color: #fdfdfd;
    border-top: 1px solid #e8e8e8;

    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

export default function (
    {
        history,
    }
)
{
    const [ action, setAction ] = useState(null)

    const [ original, setOriginal ] = useState({ })
    const [ current,  setCurrent ] = useState({ })

    const invalid = !action && Mailgun.Document.getInvalidFields(current).length > 0
    const changed = !action && Mailgun.Document.getChangeds(original, current).length > 0

    const canSave = !!changed && !invalid && !action

    const open = true

    let ready = action !== 'loading'

    function handleMount ()
    {
        setOriginal({ })
        setCurrent({ })

        setAction('loading')

        const unsubscribes = []
        const promises = []

        promises.push(
            Mailgun.Query.get(
                function ({ original, current })
                {
                    if (!!original)
                    {
                        setOriginal (original)
                    }

                    if (!!current)
                    {
                        setCurrent (current)
                        setAction (null)
                    }
                }
            )
        )

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleClose ()
    {
        history.replace (`/contacts`)
    }

    async function handleSave ()
    {
        setAction('saving')

        const { config, receivers } = current

        const data = 
        {
            config,
            receivers: receivers.filter (email => !!email),
        }

        try
        {
            const result = await Mailgun.Update.save (data)

            if (result.error)
            {
                setAction(null)

                message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
            }
            else
            {
                setAction(null)

                message.success('บันทึกเรียบร้อย')
            }
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
        }
    }

    function handleChange (name, value)
    {
        setCurrent ({ ...current, [name]: value })
    }

    useEffect (handleMount, [ ])

    return (
        <Screen 
            open={!!open} 
            zIndex={1}
        >
            <Modal open={!!open} >
                <Section>
                    <Title
                        title={'ตั้งค่าการส่งอีเมล'}
                        disabled={!!action}
                        changed={changed}
                        onClose={
                            function ()
                            {
                                if (!!handleClose)
                                {
                                    handleClose()
                                }
                            }
                        }
                    />
                </Section>
                <Content>
                    {
                        !!ready &&
                        <Layout>
                            <Form layout="vertical" >
                                <Receivers 
                                    layout={{ colon: false }}
                                    disabled={!!action}
                                    data={current.receivers}
                                    onChange={handleChange}
                                />
                            </Form>
                        </Layout>
                    }
                    
                    {!ready && <Loader />}
                </Content>
                <Section>
                    <Footer 
                        action={action}
                        canSave={canSave}
                        updatedAt={current.updatedAt}
                        onSave={handleSave}
                    />
                </Section>
            </Modal>
        </Screen>
    )
}