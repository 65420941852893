import React from 'react'

import { 
    Table, Empty
} from 'antd'

import styled from 'styled-components'

import Header from './Header'
import Search from './Header/Search'
import Create from './Header/Create'
import Selection from './Header/Selection'

const Container = styled.div`
    .ant-table colgroup > col.ant-table-selection-col
    {
        width: 48px;
    }

    .ant-table-small
    {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #e8e8e8;

        .ant-table-selection-column
        {
            min-width: 48px;
        }
    }

    .ant-table-column-title
    {
        white-space: nowrap;
    }

`

const SETTINGS = 
{
    bordered: false,

    pagination: false,

    size: 'small',
    
    scroll: { x: true },
    hasData: true,
}

const ROW_SELECTION = 
{
    getCheckboxProps: function (item)
    {
        return { name: item.id }
    }
}

function Comp (
    {
        loading,
        keyword,

        emptyText = 'ไม่มีข้อมูล',
        unit = 'รายการ',

        columns = [],

        expanded,
        expandeds = [],
        expandByClick = false,
        expandIcon,

        selection = [],
        actions = [],

        items = [],

        extra,

        onCreate,

        onTableChange,
        onSelectionChange,
        onKeywordChange,
        onExpandChange,
    }
)
{
    const rowSelection = 
    {
        ...ROW_SELECTION,

        selectedRowKeys: selection,
        onChange: onSelectionChange,
    }

    const locale = 
    {
        emptyText: <Empty description={emptyText} />
    }

    const style = 
    {
        background: 'white',
    }

    function renderHeader ()
    {
        if (selection.length > 0)
        {
            return (
                <Header>
                    <Selection 
                        unit={unit}
                        count={selection.length}
                        actions={actions}
                    />
                </Header>
            )
        }

        return (
            <Header>
                {/* <Menu /> */}
                <Search 
                    placeholder={`ค้นหา${unit}...`}
                    value={keyword}
                    onChange={onKeywordChange}
                />
                {
                    !!onCreate && 
                    <Create 
                        // text={`สร้าง${unit}`}
                        onClick={onCreate}
                    />
                }
                {extra}
            </Header>
        )
    }

    return (
        <Container>
            <Table 
                {...SETTINGS}

                columns={columns}
                        
                dataSource={items} 
                rowSelection={!!onSelectionChange ? rowSelection : null}
                
                onChange={onTableChange}
                
                loading={loading}
                locale={locale}

                title={renderHeader}
                style={style}

                expandIcon={expandIcon}
                expandRowByClick={expandByClick}
                expandedRowKeys={expandeds}
                expandedRowRender={expanded}

                onExpandedRowsChange={onExpandChange}
            />
        </Container>
    )
}

export default Comp