import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'

import { Form, Row, Col } from 'antd'

import Node from '../../../../post/Tab.Content/node/parent'
import Image from '../../../../../components/Field.Image'
// import Text from '../../../../../../components/Field.Text'

import Locations from './Locations'

const Container = styled.div`
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 4px;

    .ant-form-item
    {
        width: 100%;
    }

    .ant-form-item.alt
    {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        action,

        sortIndex,
        length,

        id,

        image,
        locations = [],

        onChange,
        onUploading,

        createTemp,
        disabled,
    }
)
{
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const old = { id, image, locations }
            onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }

    return (
        <Node
            title={`รายการที่ ${sortIndex + 1}`}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
            cols={
                {
                    xs: 24,
                    // sm: 12,
                    // md: 8,
                }
            }
        >
            <Form layout="vertical" >
                <Container disabled={!!disabled} >
                    <Row style={{ width: `100%` }} >
                        <Col xs={24} sm={15} md={10} lg={6} >
                            <div style={{ padding: 4 }} >
                                <Image 
                                    layout={formLayout}
                                    label={null}
                                    name="image"
                                    value={image}
                                    ratio={1}
                                    fit="contain"
                                    disabled={!!disabled}
                                    onChange={handleChange}
                                    onUploading={onUploading}
                                    createTemp={createTemp}
                                />
                            </div>
                        </Col>

                        <Col xs={24} sm={24} md={14} lg={18} >
                            <div style={{ padding: 4 }} >
                                <Locations 
                                    layout={formLayout}
                                    name="locations"
                                    action={action}
                                    value={locations}
                                    disabled={disabled}
                                    onChange={handleChange}
                                    onUploading={onUploading}
                                    createTemp={createTemp}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </Form>
        </Node>
    )
}

export default SortableElement(Comp)