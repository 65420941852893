import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'

import { 
    Row,
} from 'antd'

import Item from './Item'

function Component (
    {
        action,
        items = [],

        onEdit,
        onDelete,
    }
)
{
    return (
        <Row gutter={16} >
            {
                items.map((item, index) =>
                {
                    return (
                        <Item 
                            key={item.image.id}
                            index={index}
                            length={items.length}
                            value={item}
                            action={action}
                            sortIndex={index}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            disabled={!!action}
                        />
                    )
                })
            }
        </Row>
    )
}

export default SortableContainer(Component)