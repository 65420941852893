import React from 'react'
import styled from 'styled-components'

import { 
    Input, 
    Icon, 
} from 'antd'

import Delay from '../../DelayedInput'

const Container = styled.div`
    width: 100%;
    padding-right: 8px;
`

function Prefix ()
{
    return (
        <Icon 
            type="search" 
            style={{ color: 'rgba(0,0,0,.25)' }} 
        />
    )
}

function Comp (
    {
        className,

        placeholder = 'ค้นหา...',

        value,
        onChange,
    }
)
{
    function handleChange (value)
    {
        onChange (value.replace(/[^A-Za-z0-9ก-๙@.?]/gi, ''))
    }

    return (
        <Container className={`${className}`} >
            <Delay
                delay={300}
                value={value}
                onChange={handleChange}
            >
                {
                    (props) => (
                        <Input 
                            value={props.value}
                            onChange={e => props.onChange (e.target.value)}
                            placeholder={placeholder}
                            prefix={<Prefix />}
                        />
                    )
                }
            </Delay>
        </Container>
    )
}


export default Comp