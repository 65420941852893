import moment from 'moment'

function edit (doc)
{
    if (!doc.exists)
    {
        return null
    }

    let {
        email,
        displayName,
        role,
        createdAt,
        updatedAt,
    } = doc.data ()

    if (!!createdAt)
    {
        createdAt = moment(createdAt)
    }

    if (!!updatedAt)
    {
        updatedAt = moment(updatedAt)
    }
    
    return {
        id: doc.id,

        email,
        displayName,
        role,
        createdAt,
        updatedAt,
    }
}

function view (doc)
{
    const data = edit (doc)
    
    return {
        key: doc.id,
       
        ...data,
    }
}

export default 
{
    edit,
    view,
}