import React from 'react'
import { Button, Dropdown, Menu } from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    padding: 8px;

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        disabled,
        onAdd,
    }
)
{
    return (
        <Container disabled={!!disabled} >
            {/* <Button 
                title="เพิ่มบล็อค"
                type="primary" 
                shape="round" 
                icon="plus" 
                // size="small"
                onClick={onAdd}
            >
                เพิ่มบล็อค
            </Button> */}
            <Dropdown
                trigger={['click']}
                overlay={
                    <Menu>
                        <Menu.Item 
                            key="0"
                            onClick={() => onAdd('paragraph')}
                        >
                            <span>ข้อความ</span>
                        </Menu.Item>
                        
                        <Menu.Item 
                            key="1"
                            onClick={() => onAdd('image')}
                        >
                            <span>รูป</span>
                        </Menu.Item>
                    </Menu>
                }
            >
                <Button 
                    title="เพิ่มเนื้อหา"
                    type="primary" 
                    // shape="round" 
                    icon="plus" 
                    // size="small"
                    disabled={!!disabled}
                >
                    เพิ่มเนื้อหา
                </Button>
            </Dropdown>
        </Container>
    )
}

export default Comp