import React, { useState } from 'react'
import styled from 'styled-components'

import { Form, Checkbox } from 'antd'

import Title from '../../../../post/Tab.Overview/Field.Title'

import Slug from './Field.Slug'

import Caption from '../../../../../components/Field.Caption'
import Image from '../../../../../components/Field.Image'

const Container = styled.div`
    background: rgba(0,0,0, 0.025);
    
    min-height: 200px;
    padding: 16px 8px;
    /* padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    } */
`

const VALIDATIONS = 
{
    slug: 'idle',
}

function Component(
    {
        data = {},

        parent,
        slugs = [],

        action,

        onChange,
        onUploading,
 
        createTemp,
    }
)
{
    const [ validations, setValidations ] = useState(VALIDATIONS)

    async function handleValidateSlug (slug, id)
    {
        const arr = slugs.filter (e => e.slug === slug && e.id !== id)
        return arr.length > 0 ? 'error' : 'success'
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const id = data.id
    
    const active = !!data.active

    // const heading = data.heading || ''
    const title = data.title || ''
    const slug = data.slug || ''
    const detail = data.detail || ''
    const price = data.price || ''

    const image = data.image

    return (
        <Container>
            <Form 
                layout="vertical"
            >
                {/* <Title 
                    layout={fieldLayout}
                    label="ชื่อหัวข้อ"
                    name="heading"
                    value={heading}
                    disabled={disabled}
                    onChange={onChange}
                /> */}

                <Checkbox
                    checked={active}
                    disabled={disabled}
                    onChange={e => onChange('active', !!e.target.checked)}
                >
                    แสดงในหน้าเว็บไซต์
                </Checkbox>

                <div style={{ height: 16 }} />

                <Title 
                    layout={fieldLayout}
                    label="ชื่อแบบบ้าน"
                    value={title}
                    disabled={disabled}
                    onChange={onChange}
                />
                
                <Slug 
                    parent={parent}
                    id={id}

                    layout={fieldLayout}
                    
                    value={slug}
                    disabled={disabled}
                    onChange={onChange}

                    validate={handleValidateSlug}
                    validations={validations}
                    setValidations={setValidations}
                />

                <Caption 
                    layout={fieldLayout}
                    label="รายละเอียด"
                    name="detail"
                    placeholder="รายละเอียดแบบบ้าน"
                    limit={500}
                    value={detail}
                    disabled={disabled}
                    onChange={onChange}
                />
                    
                <Title 
                    layout={fieldLayout}
                    limit={50}
                    label="ราคา"
                    name="price"
                    value={price}
                    disabled={disabled}
                    onChange={onChange}
                />

                <Image 
                    layout={fieldLayout}
                    label="รูปประกอบ (อัตราส่วน 16:9)"
                    ratio={0.5625}
                    value={image}
                    disabled={disabled}
                    onChange={onChange}
                    onUploading={onUploading}
                    createTemp={createTemp}
                />
            </Form>
        </Container>
    )
}

export default Component