import React from 'react'
import { Button } from 'antd'

import styled from 'styled-components'

const Container = styled.div`
    padding: 8px;

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        disabled,
        onAdd,
    }
)
{
    return (
        <Container disabled={!!disabled} >
            <Button 
                title="เพิ่ม"
                type="primary" 
                icon="plus" 
                disabled={!!disabled}
                onClick={() => onAdd()}
            >
                เพิ่ม
            </Button>
            {/* <Dropdown
                trigger={['click']}
                overlay={
                    <Menu>
                        <Menu.Item 
                            key="1"
                            onClick={() => onAdd('link')}
                        >
                            <span>รูป</span>
                        </Menu.Item>

                        <Menu.Item 
                            key="2"
                            onClick={() => onAdd('video')}
                        >
                            <span>วิดีโอ</span>
                        </Menu.Item>
                    </Menu>
                }
            >
                <Button 
                    title="เพิ่มเนื้อหา"
                    type="primary" 
                    icon="plus" 
                    disabled={!!disabled}
                >
                    เพิ่มเนื้อหา
                </Button>
            </Dropdown> */}
        </Container>
    )
}

export default Comp