import React, { useState } from 'react'
import { Row, Col, Divider } from 'antd'

import arrayMove from 'array-move'
import styled from 'styled-components'

import { 
    Upload,
    Empty,
    // message,
} from 'antd'

import Header from '../../../../components/Datatable/Header'
import Create from '../../../../components/Datatable/Header/Create'

import Counter from '../../../post/Tab.Gallery/Counter'
import Grid from './Grid'

import Media from '../../../../utils/Space.Media'

const Container = styled.div`
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    height: 100%;
    padding-bottom: 24px;
`

const Center = styled.div`
    display: flex;
    
    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: 100%;
    padding: 24px 0 48px 0;

    .ant-empty
    {
        padding: 24px;
    }
`

function Component(
    {
        axis = 'xy',
        emptyText = 'ไม่มีรูปในรายการ',

        name = "features",
        
        data = [],
        action,

        layout,
        limit = 3,

        disabled,

        onChange,
        onUploading,

        createTemp,
    }
)
{
    const [ uploadings, setUploadings ] = useState([ ])

    function handleBeforeUpload (file)
    {
        if (Media.Validate.image (file))
        {
            setUploadings ([ ...uploadings, file.uid ])
            onUploading(true)

            return true
        }

        return false
    }

    function handleUpload (info)
    {
        if (info.file.status === 'done')
        {
            const arr = []

            if (info.file.response.length > 0)
            {
                info.file.response.forEach (image => arr.push({ image, alt: '' }))
            }

            handleChange([ ...data, ...arr ])

            const _ups = uploadings.filter (u => u !== info.file.uid)

            setUploadings (_ups)
            onUploading (_ups.length > 0)
        }
    }

    function handleDelete (index)
    {
        createTemp(data[index])
        handleChange(data.filter((e, i) => i !== index))
    }

    function handleEdit (index, alt)
    {
        if (index >= 0)
        {
            data[index] = { ...data[index], alt }
            handleChange([ ...data ])
        }
    }

    function handleSort ({ oldIndex, newIndex })
    {
        handleChange(arrayMove (data, oldIndex, newIndex))
    }

    function handleChange (value)
    {
        onChange(name, value)
    }

    async function handleRequest ({ file, onSuccess })
    {
        const result = await Media.Upload.image (file)
        onSuccess ([ result ])
    }

    return (
        <Row>
            <Col xs={24}>
                <Divider orientation="center" >จุดเด่น</Divider>
                
                {
                    data.length === 0 ?
                    <Center>
                        <Empty description={emptyText} />
                        <Upload
                            listType="picture"
                            multiple={true}
                            showUploadList={false}
                            // action={window.config.url.upload.image}
                            action={null}
                            customRequest={handleRequest}
                            beforeUpload={Media.Validate.image}
                            onChange={handleUpload}
                            disabled={!!action || uploadings.length > 0}
                        >
                            <Create
                                icon="upload"
                                text="เพิ่มรูปใหม่"
                                disabled={!!action}
                                loading={uploadings.length > 0}
                                loadingText="กำลังอัพโหลด"
                            />
                        </Upload>
                    </Center>
                    :
                    <Container>
                        <div style={{ paddingBottom: 16 }}>
                            <Header>
                                <Counter count={data.length} />
                                {
                                    // data.length < limit &&
                                    <Upload
                                        listType="picture"
                                        multiple={true}
                                        showUploadList={false}
                                        // action={window.config.url.upload.image}
                                        action={null}
                                        customRequest={handleRequest}
                                        beforeUpload={handleBeforeUpload}
                                        onChange={handleUpload}
                                        disabled={!!action || uploadings.length > 0 || data.length === limit}
                                    >
                                        <Create
                                            icon="upload"
                                            text="เพิ่มรูปใหม่"
                                            disabled={!!action || data.length === limit}
                                            loading={uploadings.length > 0}
                                            loadingText="กำลังอัพโหลด"
                                        />
                                    </Upload>
                                }
                            </Header>
                        </div>
                        <Grid 
                            axis={axis}
                            // pressDelay={200}
                            useDragHandle={true}

                            items={data}
                            action={action}

                            // onEdit={setEditing}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onSortEnd={handleSort}
                        />
                    </Container>
                }
            </Col>
        </Row>
    )
}

export default Component