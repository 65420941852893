function filter (items, keyword)
{
    if (!keyword)
        return items || []

    keyword = keyword.toLowerCase()

    return (items || []).filter (item => 
    {
        if (item.name.toLowerCase ().indexOf(keyword) >= 0)
        {
            return true
        }

        if (item.email.toLowerCase ().indexOf(keyword) >= 0)
        {
            return true
        }

        if (item.phone.toLowerCase ().startsWith (keyword))
        {
            return true
        }

        if (item.message.toLowerCase ().indexOf (keyword) >= 0)
        {
            return true
        }

        return false
    })
}

export default 
{
    filter,
}