import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import styled from 'styled-components'

import { Row, Col } from 'antd'

import Node from '../../../../../post/Tab.Content/node/parent'
import Text from '../../../../../../components/Field.Text'

const Container = styled.div`
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    align-items: flex-start;

    padding: 8px;

    .ant-form-item
    {
        width: 100%;
    }

    .ant-form-item
    {
        margin-bottom: 0;
    }

    ${props => !!props.disabled && `
        pointer-events: none;
        opacity: 0.6;
    `}
`

function Comp (
    {
        action,

        sortIndex,
        length,

        id,

        title = '',
        distance = '',

        onChange,
        disabled,
    }
)
{
    function handleChange (fieldName, fieldValue)
    {
        if (typeof onChange === 'function')
        {
            const old = { id, title, distance }
            onChange ('edit', sortIndex, { ...old, [fieldName]: fieldValue })
        }
    }

    const formLayout = { colon: false }
    const defaultTitle = `สถานที่ ${sortIndex + 1}`

    let nodeTitle = defaultTitle

    if (!!title)
    {
        nodeTitle = title
    }

    return (
        <Node
            title={nodeTitle}
            action={action}

            index={sortIndex}
            length={length}

            onChange={onChange}
            cols={
                {
                    xs: 24,
                }
            }
        >
            <Container disabled={!!disabled} >
                <Row gutter={8} style={{ width: `100%` }} >
                    <Col xs={24} sm={16}>
                        <Text 
                            layout={formLayout}
                            label="ชื่อสถานที่"
                            name="title"
                            value={title}
                            limit={200}
                            disabled={!!disabled}
                            onChange={handleChange}
                        />
                    </Col>

                    <Col xs={24} sm={8} >
                        <Text 
                            layout={formLayout}
                            label="ระยะทาง"
                            name="distance"
                            value={distance}
                            limit={50}
                            disabled={!!disabled}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
            </Container>
        </Node>
    )
}

export default SortableElement(Comp)