function filter (items, keyword, filters)
{
    let _items = items || []

    if (!!filters.project && filters.project.length > 0)
    {
        const project = filters.project[0]
        
        _items = items.filter (item => item.project === project)
    }

    if (!keyword)
        return _items

    keyword = keyword.toLowerCase()

    return _items.filter (item => 
    {
        if (item.name.toLowerCase ().indexOf(keyword) >= 0)
        {
            return true
        }

        if (item.email.toLowerCase ().indexOf(keyword) >= 0)
        {
            return true
        }

        if (item.phone.toLowerCase ().startsWith (keyword))
        {
            return true
        }

        return false
    })
}

export default 
{
    filter,
}