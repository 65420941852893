import React, { Fragment } from 'react'
import styled from 'styled-components'

import { Divider, Button, Modal } from 'antd'
import StatusLabel from '../../posts/Column.Status'

const Container = styled.div`
    /* background-color: #fafafa; */
    /* background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px;
    margin: -8px -8px 8px -8px; */

    /* position: relative;
    z-index: 0; */

    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px;

    .label
    {
        padding-bottom: 8px;

        display: flex;

        flex-direction: row;
        flex-wrap: nowrap;

        .field
        {
            padding-right: 6px;
        }

        .grow
        {
            flex-grow: 1;
        }
    }

    /* &::before
    {
        content: '';
        z-index: -1;

        background-color: #f9f9f9;
        border: 1px solid #d9d9d9;
        border-radius: 4px;

        position: absolute;

        left: -8px;
        right: -8px;
        top: -8px;
        bottom: -8px;
    } */
`

function Group (
    {
        status,
        children,
    }
)
{
    return (
        <Container>
            <Status value={status} />
            <Divider style={{ margin: `0 0 4px 0` }} />
            {children}
        </Container>
    )
}

function Status (
    {
        value
    }
)
{
    return (
        <span className="label">
            <span className="field" >สถานะ:</span>
            <StatusLabel value={value} />
        </span>
    )
}

function ChangedLabel ({ label })
{
    return (
        <small>กรุณาบันทึกการเปลี่ยนแปลงของ{label}ก่อนเผยแพร่</small>
    )
}

function NeedPublishedAtLabel ()
{
    return (
        <small>กรุณาระบุวันและเวลาที่ต้องการเผยแพร่</small>
    )
}

function PublishButton (
    {
        label,
        disabled,

        action,
        status,

        onConfirm,
    }
)
{
    return (
        <Button
            icon="schedule"
            type="primary"
            block={true}
            disabled={!!disabled || (!!action && action !== 'publishing')}
            loading={action === 'publishing'}
            style={{ margin: `4px 0` }}
            onClick={
                () =>
                {
                    const m = Modal.confirm(
                        {
                            title: `ต้องการเผยแพร่${label} ?`,
                            okButtonProps: { type: 'primary' },
                            okText: 'ใช่',
                            cancelText: 'ไม่ใช่',
                            onOk: () => 
                            {
                                onConfirm ()
                                m.destroy ()
                            },
                        }
                    )
                }
            }
        >
            {
                status === 'draft' && `เผยแพร่`
            }
            {
                status === 'changed' && `เผยแพร่การเปลี่ยนแปลง`
            }
        </Button>
    )
}

function UnpublishButton (
    {
        label,
        action,
        onConfirm,
    }
)
{
    return (
        <Button
            icon="stop"
            type="danger"
            block={true}
            disabled={!!action && action !== 'unpublishing'}
            loading={action === 'unpublishing'}
            style={{ margin: `4px 0` }}
            onClick={
                () =>
                {
                    const m = Modal.confirm(
                        {
                            title: `ต้องการหยุดเผยแพร่${label} ?`,
                            okButtonProps: { type: 'danger' },
                            okText: 'ใช่',
                            cancelText: 'ไม่ใช่',
                            onOk: () => 
                            {
                                onConfirm ()
                                m.destroy ()
                            },
                        }
                    )
                }
            }
        >
            หยุดการเผยแพร่
        </Button>
    )
}

function DeleteButton (
    {
        label,
        action,
        onConfirm,
    }
)
{
    return (
        <Fragment>
            <Divider />
            <Button
                icon="delete"
                type="danger"
                block={true}
                disabled={!!action && action !== 'deleting'}
                loading={action === 'deleting'}
                onClick={
                    () =>
                    {
                        const m = Modal.confirm(
                            {
                                title: `ต้องการลบ${label} ?`,
                                okButtonProps: { type: 'danger' },
                                okText: 'ใช่',
                                cancelText: 'ไม่ใช่',
                                onOk: () => 
                                {
                                    onConfirm ()
                                    m.destroy ()
                                },
                            }
                        )
                    }
                }
            >
                ลบ{label}
            </Button>
        </Fragment>
    )
}

function Component(
    {
        label = 'บทความ',
        action,

        status,
        publishedAt,

        changed,
        invalid,

        onDelete,
        onPublish,
        onUnpublish,
    }
)
{
    if ([ 'draft', 'changed', 'published' ].indexOf(status) < 0)
    {
        return null
    }

    return (
        <Fragment>
            <Group label={label} status={status} >
                {
                    [ 'draft', 'changed' ].indexOf(status) >= 0 &&
                    <Fragment>
                        {
                            !!publishedAt &&
                            <Fragment>
                                {!!changed && <ChangedLabel label={label} />}
                                {
                                    !changed &&
                                    <PublishButton 
                                        label={label}
                                        disabled={!!invalid}
                                        action={action}
                                        status={status}
                                        onConfirm={onPublish}
                                    />
                                }
                            </Fragment>
                        }
                        {!publishedAt && <NeedPublishedAtLabel label={label} />}
                    </Fragment>
                }
                {
                    [ 'published', 'changed' ].indexOf(status) >= 0 &&
                    <UnpublishButton 
                        label={label}
                        action={action}
                        onConfirm={onUnpublish}
                    />
                }
            </Group>
            {
                status === 'draft' &&
                <DeleteButton 
                    label={label}
                    action={action}
                    onConfirm={onDelete}
                />
            }
        </Fragment>
    )
}

export default Component