import React from 'react'
import styled from 'styled-components'

import { Form, Divider } from 'antd'

import Title from '../../post/Tab.Overview/Field.Title'

import Image from '../../../components/Field.Image'
import Gallery from '../../post/Tab.Gallery.v2'

import Box from '../Box'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

const Card = styled.div`
    background-color: #f9f9f9;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    
    margin-top: 16px;
`

function Component(
    {
        data = {},
        action,

        createTemp,

        onChange,
        onUploading,
    }
)
{
    function handleChange (name, value)
    {
        onChange ('gallery', { ...data, [name]: value })
    }

    function handleItemsChange (value)
    {
        handleChange ('items', value)
    }

    const fieldLayout = { colon: false }
    const disabled = !!action

    const title = data.title || ''
    const bg = data.bg

    const items = data.items || []
    
    return (
        <Container>
            <Form layout="vertical">
                <Box>
                    <Title 
                        layout={fieldLayout}
                        label="ชื่อหัวข้อ"
                        name="title"
                        value={title}
                        limit={50}
                        disabled={disabled}
                        onChange={handleChange}
                    />

                    <Image 
                        layout={fieldLayout}
                        label="รูปพื้นหลัง"
                        name="bg"
                        position="top"
                        ratio={0.25}
                        value={bg}
                        disabled={disabled}
                        onChange={handleChange}
                        onUploading={onUploading}
                        createTemp={createTemp}
                    />
                </Box>
            </Form>
            
            <Card>
                <Divider type="horizontal" orientation="center">อัลบั้มรูป</Divider>
                <Gallery 
                    action={action}
                    contents={items}
                    onChange={handleItemsChange}
                    onUploading={onUploading}
                    createTemp={createTemp}
                />
            </Card>

        </Container>
    )
}

export default Component