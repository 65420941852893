

function add (content, index, value)
{
    let changed = []

    if (index === 0)
    {
        changed = [ value, ...content ]
    }
    else if (index < 0 || index >= content.length)
    {
        changed = [ ...content, value ]
    }
    else
    {
        content.forEach ((e, i) =>
        {
            if (i === index)
            {
                changed.push(value)
            }

            changed.push(e)
        })
    }

    return changed
}

function edit (content, index, value)
{
    let changed = []

    content.forEach ((e, i) =>
    {
        if (i === index)
        {
            changed.push(value)
        }
        else
        {
            changed.push(e)
        }
    })

    return changed
}

function remove (content, index)
{
    let changed = []

    content.forEach ((e, i) =>
    {
        if (i !== index)
        {
            changed.push(e)
        }
    })

    return changed
}

export default 
{
    add,
    edit,
    remove,
}