import React from 'react'
import styled from 'styled-components'

import Items from './Items'

const Container = styled.div`
    margin: 8px;
`

function Component(
    {
        data = [],
        action,

        onChange,
    }
)
{
    return (
        <Container>
            <Items 
                title=""
                name="authors"
                value={data}
                action={action}
                onChange={onChange}
            />
        </Container>
    )
}

export default Component