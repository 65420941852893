import React from 'react'
import ReactQuill from 'react-quill'

import { Form } from 'antd'

// import Label from '../Label.Counter'
import Delay from '../DelayedInput'

import styled from 'styled-components'

const Container = styled.div`
    .quill
    {
        background-color: white;

        border: 1px solid #d9d9d9;
        border-radius: 4px;

        .ql-toolbar.ql-snow
        {
            border: none;
            border-bottom: 1px solid #e8e8e8;
        }

        .ql-container.ql-snow
        {
            min-height: 97px;
            border: none;

            .ql-tooltip
            {
                z-index: 999999;
            }

            strong
            {
                font-weight: bold;
            }
        }

        ${props => !!props.disabled && `
            pointer-events: none;
            opacity: 0.6;
        `}
    }
`

function Component(
    {
        layout,
        // limit = 500,
        
        disabled,

        id,

        label = 'แคปชั่น',
        name = 'caption',

        placeholder = 'ข้อความแคปชั่น...',

        value,
        onChange,
    }
)
{
    function handleChange (changedValue)
    {
        if (value !== changedValue && typeof onChange === 'function')
        {
            onChange(name, changedValue)
        }
    }

    return (
        <Delay 
            value={value}
            onChange={handleChange}
        >
            {
                ({ value, onChange }) => (
                    <Form.Item 
                        {...layout}
                        className={name}
                        label={label}
                        // label={
                        //     <Label 
                        //         text={label} 
                        //         value={value.length} 
                        //         limit={limit} 
                        //     />
                        // }
                    >
                        <Container disabled={!!disabled} >
                            <ReactQuill 
                                id={id}
                                value={value}
                                onChange={newValue =>
                                {
                                    if (value !== newValue && typeof onChange === 'function')
                                    {
                                        onChange (newValue)
                                    }
                                }}
                                bounds={`#content-editor`}
                                placeholder={placeholder}
                                preserveWhitespace={false}
                                modules={
                                    {
                                        toolbar:
                                        [
                                            ['bold', 'italic', 'underline', 'strike'],
                                            ['link'],
                                        ]
                                    }
                                }
                            />
                        </Container>
                    </Form.Item>
                )
            }
        </Delay>
    )
}

export default Component