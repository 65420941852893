import React from 'react'
import {
    Button,
} from 'antd'

export default function (
    {
        status,
        action,

        canSave,
        onSave,
    }
)
{
    const created = [ 'inactive', 'active' ].indexOf(status) >= 0

    return (
        <Button 
            type="primary" 
            onClick={onSave}
            loading={action === 'saving'}
            disabled={(!!action && action !== 'saving') || !canSave}
        >
            {action === 'saving' && `กำลัง`}
            {!!created ? `บันทึก` : `สร้าง`}
        </Button>
    )
}