import Settings from '../Space.Service.Cloudinary'
import axios from 'axios'
import sha1 from 'js-sha1'

async function upload (file, type)
{
    const { name, key, secret } = await Settings.Query.getActive ()
    
    const public_id = file.name.slice (0, 24) + '_' + new Date ().getTime ().toString (35)
    const timestamp = new Date ().getTime ()
    const signature = sha1 (`public_id=${public_id}&timestamp=${timestamp}${secret}`)

    const formData = new FormData ()

    formData.set ('file', file)
    formData.set ('api_key', key)
    formData.set ('public_id', public_id)
    formData.set ('timestamp', timestamp)
    formData.set ('signature', signature)

    const url = `https://api.cloudinary.com/v1_1/${name}/${type}/upload`
    const result = await axios.post(url, formData, 
    {
        headers: 
        {
            'Content-Type': 'multipart/form-data'
        }
    })

    const data = result.data
    const output = 
    {
        type,

        id: data.public_id,
        
        path: `v${data.version}/${data.public_id}`,
        bucket: name,

        title: file.name,
        mime: file.type,

        width: data.width,
        height: data.height,

        createdAt: new Date(data.created_at).getTime(),
    }

    return output
}

async function image (file)
{
    return await upload (file, 'image')
}

async function video (file)
{
    return await upload (file, 'video')
} 

export default 
{
    image,
    video,
}