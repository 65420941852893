import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Row, Col, Form } from 'antd'
import Post from '../../../utils/Space.Post'

import Loader from '../Loader'

import Title from './Field.Title'
import Slug from './Field.Slug'
import Excerpt from './Field.Excerpt'
import Author from './Field.Author'
import Category from './Field.Category'
import Tags from './Field.Tags'

import PublishedAt from './Field.PublishedAt'
import Publish from './Field.Publish'

import Image from '../../../components/Field.Image'

const Container = styled.div`
    min-height: 200px;
    padding: 32px 16px;

    @media (min-width: 768px)
    {
        padding: 32px;
    }
`

function Component(
    {
        id,
        data = {},
        info = {},

        taxonomy,

        action,
        changed,
        invalid,
        validations,

        onChange,
        onDelete,
        onPublish,
        onUnpublish,
        onUploading,
 
        createTemp,
        setValidations,
    }
)
{
    function handleMountChange ()
    {
        const newData = { ...data }

        if (!newData.author && !!taxonomy && !!taxonomy.authors)
        {
            newData.author = taxonomy.authors[0].value
        }

        if (!newData.category && !!taxonomy && !!taxonomy.categories)
        {
            newData.category = taxonomy.categories[0].value
        }

        onChange(newData)
    }

    function handleChange (name, value)
    {
        onChange({ ...data, [name]: value })
    }

    // useEffect(handleMount, [  ])
    useEffect(handleMountChange, [ id, taxonomy ])

    if (!taxonomy)
    {
        return <Loader />
    }

    const fieldLayout = { colon: false }

    const title = data.title || ''
    const slug = data.slug || ''
    const excerpt = data.excerpt || ''

    const author = data.author || taxonomy.authors[0].value
    const category = data.category || taxonomy.categories[0].value
    const tags = data.tags || []

    const image = data.image
    const publishedAt = data.publishedAt
    
    const status = info.status || 'none'

    return (
        <Container>
            <Form 
                layout="vertical"
            >
                <Row 
                    gutter={32} 
                >
                    <Col 
                        xs={24}
                        sm={24} 
                        md={16}
                        lg={18}
                    >
                        <Title 
                            layout={fieldLayout}
                            value={title}
                            disabled={!!action}
                            onChange={handleChange}
                        />
                        
                        <Slug 
                            id={id}

                            layout={fieldLayout}
                            
                            value={slug}
                            disabled={!!action}
                            onChange={handleChange}

                            validate={Post.Validate.slug}
                            validations={validations}
                            setValidations={setValidations}

                            collection="posts"
                            errorText={'มีการใช้ชื่อลิงก์นี้ในบทความอื่นแล้ว'}
                        />

                        <Excerpt 
                            layout={fieldLayout}
                            value={excerpt}
                            disabled={!!action}
                            onChange={handleChange}
                        />

                        <Author 
                            layout={fieldLayout}
                            options={taxonomy.authors}
                            value={author}
                            disabled={!!action}
                            onChange={handleChange}
                        />

                        <Category 
                            layout={fieldLayout}
                            options={taxonomy.categories}
                            value={category}
                            disabled={!!action}
                            onChange={handleChange}
                        />
                        
                        <Tags 
                            layout={fieldLayout}
                            options={taxonomy.tags}
                            value={tags}
                            disabled={!!action}
                            onChange={handleChange}
                        />

                        <PublishedAt 
                            layout={fieldLayout}
                            value={publishedAt}
                            disabled={!!action}
                            onChange={handleChange}
                        />

                        <Image 
                            layout={fieldLayout}
                            label="รูปประกอบบทความ (อัตราส่วน 4:5)"
                            ratio={1.235}
                            value={image}
                            disabled={!!action}
                            onChange={handleChange}
                            onUploading={onUploading}
                            createTemp={createTemp}
                        />
                    </Col>
                    <Col 
                        xs={24} 
                        sm={12}
                        md={8}
                        lg={6}
                    >
                        <Row gutter={8} >
                            <Col 
                                xs={24}
                            >
                                <Publish 
                                    changed={changed}
                                    invalid={invalid}
                                    action={action}
                                    status={status}
                                    publishedAt={publishedAt}
                                    onDelete={onDelete}
                                    onPublish={onPublish}
                                    onUnpublish={onUnpublish}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Container>
    )
}

export default Component