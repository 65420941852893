import React from 'react'
import Color from '../../../components/Field.Color'

import Card from './Card'
import Group from './Group'
import Label from './Group.Label'
import Item from './Group.Item'

function Component(
    {
        fieldLayout,
        disabled,

        select,
        onChange,
    }
)
{
    return (
        <Card title="แบบบ้าน" >
            <Group>
                <Item>
                    <Label> </Label>
                    <Group group>
                        <Item>
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="พื้นหลัง"
                                name="model.bgColor"
                                alpha={false}
                                value={select('model.bgColor')}
                                onChange={onChange}
                            />
                            
                            <Color 
                                disabled={disabled}
                                layout={fieldLayout}
                                label="หัวข้อ"
                                name="model.title.color"
                                alpha={false}
                                value={select('model.title.color')}
                                onChange={onChange}
                            />
                        </Item>
                    </Group>
                </Item>
                <Item>
                    <Label>รายการ</Label>
                    <Group group>
                        <Item>
                            <Label>ชื่อแบบบ้าน</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="model.item.primary.bgColor"
                                        alpha={false}
                                        value={select('model.item.primary.bgColor')}
                                        onChange={onChange}
                                    />

                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="เส้นขอบ"
                                        name="model.item.primary.borderColor"
                                        alpha={false}
                                        value={select('model.item.primary.borderColor')}
                                        onChange={onChange}
                                    />

                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ตัวอักษร"
                                        name="model.item.primary.color"
                                        alpha={false}
                                        value={select('model.item.primary.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>

                        <Item>
                            <Label>รายละเอียด</Label>
                            <Group group>
                                <Item>
                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="พื้นหลัง"
                                        name="model.item.secondary.bgColor"
                                        alpha={false}
                                        value={select('model.item.secondary.bgColor')}
                                        onChange={onChange}
                                    />

                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="เส้นขอบ"
                                        name="model.item.secondary.borderColor"
                                        alpha={false}
                                        value={select('model.item.secondary.borderColor')}
                                        onChange={onChange}
                                    />

                                    <Color 
                                        disabled={disabled}
                                        layout={fieldLayout}
                                        label="ตัวอักษร"
                                        name="model.item.secondary.color"
                                        alpha={false}
                                        value={select('model.item.secondary.color')}
                                        onChange={onChange}
                                    />
                                </Item>
                            </Group>
                        </Item>
                    </Group>
                </Item>
            </Group>
        </Card>
    )
}

export default Component