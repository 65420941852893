import React, { Fragment, useState, useEffect } from 'react'
import {
    message,
} from 'antd'

import Modal from '../../components/Modal'

import Project from '../../utils/Space.Project'

import Header from '../post/Header'
import Footer from '../post/Footer'
import Loader from '../post/Loader'

import TabOverview from './Tab.Overview'
import TabRibbon from './Tab.Ribbon'
import TabBanner from './Tab.Banner'
import TabConcept from './Tab.Concept'
import TabDetail from './Tab.Detail'
import TabMap from './Tab.Map'
import TabFacility from './Tab.Facility'
import TabGallery from './Tab.Gallery'
import TabModel from './Tab.Model'
import TabReview from './Tab.Review'
import TabRegistration from './Tab.Registration'
import TabStyle from './Tab.Style'

import TabMeta from '../post/Tab.Meta'

const TABS = 
[
    {
        //0
        tab: 'ภาพรวม',
        key: 'overview',
    },

    {
        //1
        tab: 'โปรโมชั่น',
        key: 'ribbon',
    },

    {
        //2
        tab: 'แบนเนอร์',
        key: 'banner',
    },

    {
        //3
        tab: 'แนวคิด',
        key: 'concept',
    },

    {
        //4
        tab: 'ข้อมูล',
        key: 'detail',
    },

    {
        //5
        tab: 'แบบบ้าน',
        key: 'model',
    },

    {
        //6
        tab: 'สิ่งอำนวยความสะดวก',
        key: 'facility',
    },

    {
        //7
        tab: 'แกลเลอรี่',
        key: 'gallery',
    },

    {
        //8
        tab: 'แผนที่',
        key: 'map',
    },

    {
        //9
        tab: 'รีวิว',
        key: 'review',
    },

    {
        //10
        tab: 'ลงทะเบียน',
        key: 'registration',
    },

    {
        //11
        tab: 'เมต้า',
        key: 'meta',
    },

    {
        tab: 'ธีม',
        key: 'style',
    },
]

const VALIDATIONS = 
{
    slug: 'idle',
}

function Comp (
    {
        match,
        history,
    }
)
{
    const { id } = match.params

    const [ action, setAction ] = useState(null)
    const [ tab, setTab ] = useState(TABS[0].key)
    
    const [ original, setOriginal ] = useState({ })
    const [ current,  setCurrent ] = useState({ })

    const [ validations, setValidations ] = useState(VALIDATIONS)
    const [ temps, setTemps ] = useState([ ])
    
    // const [ invalids, setInvalids ] = useState ([ ])
    // const [ changeds, setChangeds ] = useState ([ ])
    
    const invalid = !action && Project.Document.getInvalidFields(current, validations).length > 0
    const changed = !action && Project.Document.getChangeds(original, current).length > 0
    // const invalid = !action && invalids.length > 0
    // const changed = !action && changeds.length > 0

    const canSave = !!changed && !action

    function handleChangeds ()
    {
        // console.log ('changeds')

        // setInvalids (Project.Document.getInvalidFields(current, validations))
        // setChangeds (Project.Document.getChangeds(original, current))
    }

    function handleMount ()
    {
        setOriginal({ })
        setCurrent({ })

        setTemps([])
        setValidations(VALIDATIONS)
        setAction(id !== 'create' ? 'loading' : null)

        const unsubscribes = []
        const promises = []

        if (id !== 'create')
        {
            promises.push(
                Project.Query.get(id, function({ original, current })
                {
                    if (!!original)
                    {
                        setOriginal (original)
                    }

                    if (!!current)
                    {
                        setCurrent (current)
                        setAction (null)
                    }

                    handleChangeds ()
                })
            )
        }

        Promise.all(promises).then(function (us)
        {
            us.forEach(function (u)
            {
                unsubscribes.push(u)
            })
        })

        return function ()
        {
            unsubscribes.filter(u => !!u).forEach(u => u())
        }
    }

    function handleCreateTemp (temp)
    {
        if (!!temp)
        {
            // console.log(temp)
            setTemps([ ...temps, temp ])
        }
    }

    function handleDataChange (name, value)
    {
        setCurrent ({ ...current, [name]: value })

        // if (name === 'overview')
        // {
        //     if (id === 'create')
        //     {
        //         const _meta = current.meta || {}
    
        //         if (!_meta.title)
        //         {
        //             _meta.title = value.title || ''
        //         }
    
        //         if (!_meta.description)
        //         {
        //             _meta.description = value.excerpt || ''
        //         }
    
        //         if (!_meta.image)
        //         {
        //             _meta.image = value.image || null
        //         }
    
        //         setCurrent ({ ...current, meta: _meta })
        //     }
        // }

        // handleChangeds ()
    }

    async function handleSave ()
    {
        const _editor = window.localStorage.getItem('editorID')

        const _info = current.info || {}
        const _over = current.overview || {}

        const createdAt = !!_info.createdAt && !!_info.createdAt.toDate ? _info.createdAt.toDate().getTime() : 'now'
        const publishedAt = !!_over.publishedAt && !!_over.publishedAt.toDate ? _over.publishedAt.toDate().getTime() : 'delete'

        const status = _info.status || 'draft'

        const header = current.header || {}
        const footer = current.footer || {}
        const style = current.style || {}

        const overview = { ...current.overview, publishedAt }
        const ribbon = current.ribbon || {}
        const banner = current.banner || {}
        const concept = current.concept || {}
        const detail = current.detail || {}
        const model = current.model || {}
        const facility = current.facility || {}
        const gallery = current.gallery || {}
        const map = current.map || {}
        const review = current.review || {}
        const registration = current.registration || {}
        const meta = current.meta || {}

        const data = 
        {
            header,
            footer,
            style,

            overview,
            ribbon,
            banner,
            concept,
            detail,
            model,
            facility,
            gallery,
            map,
            review,
            registration,
            meta,

            status,
            createdAt,

            _editor,
        }

        setAction('saving')

        try
        {
            const result = await Project.Update.save ({ id, data, temps })

            if (result.error)
            {
                setAction(null)
                setValidations(result.error.validations || { })

                message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
            }
            else
            {
                setAction(null)
                setValidations({ })
                setTemps([ ])

                if (result.action === 'create')
                {
                    // onChange (result.id)
                    history.replace (`/projects/${result.id}`)
                }

                message.success('บันทึกโครงการเรียบร้อย')
            }
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการบันทึก กรุณาตรวจสอบข้อมูลและบันทึกอีกครั้ง')
        }
    }

    function handleUploading (uploading)
    {
        setAction(!!uploading ? 'uploading' : null)
    }

    async function handlePublish ()
    {
        setAction('publishing')

        try
        {
            await Project.Update.publish ([ id ])

            setAction(null)

            message.success('แผยแพร่โครงการเรียบร้อย')
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการเผยแพร่ กรุณาตรวจสอบข้อมูลและเผยแพร่อีกครั้ง')
        }
    }

    async function handleUnpublish ()
    {
        setAction('unpublishing')

        try
        {
            await Project.Update.unpublish ([ id ])

            setAction(null)

            message.success('หยุดแผยแพร่โครงการเรียบร้อย')
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการหยุดเผยแพร่ กรุณาตรวจสอบข้อมูลและหยุดเผยแพร่อีกครั้ง')
        }
    }

    async function handleDelete ()
    {
        setAction('deleting')

        try
        {
            await Project.Update.delete ([ id ])

            setAction(null)

            message.success('ลบโครงการเรียบร้อย')

            setTab(TABS[0].key)
            handleClose ()
        }
        catch (err)
        {
            setAction(null)
            message.error('พบข้อผิดพลาดระหว่างการลบ กรุณาตรวจสอบข้อมูลและลบอีกครั้ง')
        }
    }

    function handleClose ()
    {
        history.replace (`/projects`)
    }

    useEffect (handleMount, [ id ])

    let ready = action !== 'loading'

    return (
        <Modal
            open={true}

            header={
                <Header
                    title={id !== 'create' ? 'แก้ไขโครงการ' : 'สร้างโครงการใหม่'}
                    tab={tab}
                    tabs={TABS}
                    disabled={!!action}
                    changed={changed}
                    onChange={setTab}
                    onClose={
                        function ()
                        {
                            if (!!handleClose)
                            {
                                setTab(TABS[0].key)
                                handleClose()
                            }
                        }
                    }
                />
            }

            footer={
                <Footer
                    {...current.info}

                    action={action}
                    canSave={canSave}
                    onSave={handleSave}
                />
            }

            style={
                {
                    backgroundColor: `#fdfdfd`,
                }
            }
        >
            {
                !!ready &&
                <Fragment>
                    {
                        tab === 'overview' && 
                        <TabOverview 
                            id={id}
                            data={current.overview}
                            info={current.info}

                            header={current.header}
                            footer={current.footer}
                            style={current.style}

                            action={action}
                            onChange={handleDataChange}
                            onDelete={handleDelete}
                            onPublish={handlePublish}
                            onUnpublish={handleUnpublish}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}

                            changed={changed}
                            invalid={invalid}

                            validations={validations}
                            setValidations={setValidations}
                        />
                    }
                    {
                        tab === 'ribbon' && 
                        <TabRibbon 
                            data={current.ribbon}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'banner' && 
                        <TabBanner 
                            data={current.banner}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'concept' && 
                        <TabConcept 
                            data={current.concept}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'detail' && 
                        <TabDetail 
                            data={current.detail}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'model' && 
                        <TabModel 
                            parent={current.info.slug}
                            data={current.model}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'facility' && 
                        <TabFacility 
                            data={current.facility}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'gallery' && 
                        <TabGallery 
                            data={current.gallery}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'map' && 
                        <TabMap 
                            data={current.map}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'review' && 
                        <TabReview 
                            data={current.review}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'registration' && 
                        <TabRegistration 
                            data={current.registration}
                            action={action}
                            onChange={handleDataChange}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'meta' && 
                        <TabMeta 
                            data={current.meta}
                            action={action}
                            onChange={value => handleDataChange ('meta', value)}
                            onUploading={handleUploading}
                            createTemp={handleCreateTemp}
                        />
                    }
                    {
                        tab === 'style' && 
                        <TabStyle 
                            data={current.style}
                            action={action}
                            onChange={handleDataChange}
                        />
                    }
                </Fragment>
            }
            {!ready && <Loader />}
        </Modal>
    )
}

export default Comp