import React from 'react'
import { Link } from 'react-router-dom'

import DateTimeUtil from '../DateTime'

import Title from '../../components/Datatable/Column.Title'
import DateTime from '../../components/Datatable/Column.DateTime'

import DatePicker from '../../pages/posts/Filter.DatePicker'

const COLUMNS = 
[
    {
        title: 'ชื่อผู้ใช้',
        dataIndex: 'displayName',
        width: 250,
    },

    {
        title: 'อีเมล',
        dataIndex: 'email',
        width: 320,
    },

    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: 104,
        render: e => (
            <div style={{ minWidth: 104 }} >
                <DateTime value={e} />
            </div>
        ),

        defaultSortOrder: 'descend',
        sorter: (a, b) => DateTimeUtil.compare(a.createdAt, b.createdAt),
        sortDirections: ['descend', 'ascend'],

        filterDropdown: DatePicker,
    },
]

function columns (
    {
        keyword,
    }
)
{
    const _columns = [ ...COLUMNS ]

    _columns[0].render = function (value, record)
    {
        return (
            <div style={{ minWidth: 220 }} >
                <Link to={`/users/${record.id}`}>
                    <Title 
                        value={value}
                        keyword={keyword}
                    />
                </Link>
            </div>
        )
    }

    _columns[1].render = function (value, record)
    {
        return (
            <div style={{ minWidth: 320 }} >
                <Title 
                    value={value}
                    keyword={keyword}
                />
            </div>
        )
    }

    return _columns
}

export default
{
    columns,
}