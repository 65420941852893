import axios from 'axios'
import firebase from 'firebase/app'
import localforage from 'localforage'
import { Modal } from 'antd'

async function getActive ()
{
    const { space } = window.config
    const cfs = firebase.firestore ()

    const doc = await cfs.doc(`${space}/services/cloudinary`).get ()

    const bucket = parseInt (doc.get ('bucket') || 0)
    const buckets = doc.get ('buckets') || []

    return buckets[bucket]
}

async function get (id, callback)
{
    const { space } = window.config

    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/services/cloudinary`)
    
    let m = null
    let init = false

    function indexOf (buckets, id)
    {
        for (let i = 0; i < buckets.length; i++)
        {
            if (buckets[i].id === id)
            {
                return i
            }
        }

        return -1
    }

    return await queryRef.onSnapshot(async function (doc)
    {
        const bucket = parseInt (doc.get ('bucket') || 0)
        const buckets = doc.get ('buckets') || []

        const index = indexOf (buckets, id)

        const status = bucket === index ? 'active' : 'inactive'

        const original = { ...buckets[index], status }
        const current = { ...buckets[index], status }

        if (!!m)
        {
            m.destroy ()
            m = null
        }

        const _editor = doc.get ('_editor')

        if (!init || !_editor || _editor === window.localStorage.getItem('editorID'))
        {
            init = true
            
            callback ({ original, current })
        }
        else
        {
            m = Modal.confirm(
                {
                    title: `มีการแก้ไขข้อมูลจากบัญชีผู้ใช้อื่น ต้องการอัพเดทข้อมูลล่าสุดหรือไม่ ?`,
                    okButtonProps: { type: 'primary' },
                    okText: 'ใช่',
                    cancelText: 'ไม่ใช่',
                    onOk: () => 
                    {
                        init = true

                        callback ({ original, current })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    },
                    onCancel: () =>
                    {
                        init = true

                        callback ({ original })

                        if (!!m)
                        {
                            m.destroy ()
                            m = null
                        }
                    }
                }
            )
        }
    })
}

async function list (callback)
{
    const { space } = window.config
    
    const DEFAULT_USAGE = 
    {
        value: 0,
        limit: 1,
    }

    const cfs = firebase.firestore()
        
    let queryRef = cfs.doc(`${space}/services/cloudinary`)

    async function getUsage ({ name, key, secret })
    {
        try
        {
            const token = await firebase.auth ().currentUser.getIdToken ()
            const headers = { Authorization: `Bearer ${token}` }

            const url = window.config.url.service.cloudinary.usage
            const data = (await axios.post (url, { name, key, secret }, { headers })).data

            await localforage.setItem (`${name}-${key}`, data)

            return data
        }
        catch (err)
        {
            return DEFAULT_USAGE
        }
    }

    async function getUsageFromCache ({ name, key })
    {
        try
        {
            return await localforage.getItem (`${name}-${key}`)
        }
        catch (err)
        {
            return null
        }
    }

    function reduce (buckets, bucket, syncing)
    {
        const keys = buckets.map (b => b.name)
        callback ({ keys, buckets, bucket, syncing })
    }

    return await queryRef.onSnapshot(async function (doc)
    {
        const bucket = parseInt (doc.get ('bucket') || 0)
        const buckets = doc.get ('buckets') || []

        const usageFromCaches = await Promise.all (buckets.map (bucket => getUsageFromCache (bucket)))
        const promises = []

        for (let i = 0; i < buckets.length; i++)
        {
            buckets[i].status = bucket === i ? 'active' : 'inactive'
            buckets[i].usage = usageFromCaches[i] || DEFAULT_USAGE

            promises.push (
                new Promise (async resolve =>
                {
                    buckets[i].usage = await getUsage (buckets[i])
                    resolve ()
                })
            )
        }

        reduce (buckets, bucket, usageFromCaches.length === 0)

        await Promise.all (promises)
        
        reduce (buckets, bucket, false)
    })
}

export default 
{
    getActive,
    get,
    list,
}